// modified by Vihang
// modified at 06/05/2022
// modification : necessar classed added for selected and not selected checklist ui revamp, alignment changes and responsive changes
/*
  BY: Prasanadatta Kawadkar
  ON: 13 March 2024
  Modification: added new generic css classes
*/
/*[ FONT SIZE ]
///////////////////////////////////////////////////////////
*/
.fs-1 {font-size: 1px !important;}
.fs-2 {font-size: 2px !important;}
.fs-3 {font-size: 3px !important;}
.fs-4 {font-size: 4px !important;}
.fs-5 {font-size: 5px !important;}
.fs-6 {font-size: 6px !important;}
.fs-7 {font-size: 7px !important;}
.fs-8 {font-size: 8px !important;}
.fs-9 {font-size: 9px !important;}
.fs-10 {font-size: 10px !important;}
.fs-11-imp {font-size: 11px !important;}
.fs-11 {font-size: 11px !important; line-height: 16px;}
.fs-12 {font-size: 12px !important;}
.fs-13 {font-size: 13px !important; line-height: 18px;}
.fs-13-imp {font-size: 13px !important;}
.fs-14 {font-size: 14px !important;}
.fs-15 {font-size: 15px !important; line-height: 20px;}
.fs-16 {font-size: 16px !important;}
.fs-17 {font-size: 17px !important;}
.fs-18 {font-size: 18px !important;}
.fs-19 {font-size: 19px !important;}
.fs-20 {font-size: 20px !important;}
.fs-21 {font-size: 21px !important;}
.fs-22 {font-size: 22px !important;}
.fs-23 {font-size: 23px !important;}
.fs-24 {font-size: 24px !important;}
.fs-25 {font-size: 25px !important;}
.fs-26 {font-size: 26px !important;}
.fs-27 {font-size: 27px !important;}
.fs-28 {font-size: 28px !important;}
.fs-29 {font-size: 29px !important;}
.fs-30 {font-size: 30px !important;}
.fs-31 {font-size: 31px !important;}
.fs-32 {font-size: 32px !important;}
.fs-33 {font-size: 33px !important;}
.fs-34 {font-size: 34px !important;}
.fs-35 {font-size: 35px !important;}
.fs-36 {font-size: 36px !important;}
.fs-37 {font-size: 37px !important;}
.fs-38 {font-size: 38px !important;}
.fs-39 {font-size: 39px !important;}
.fs-40 {font-size: 40px !important;}
.fs-41 {font-size: 41px !important;}
.fs-42 {font-size: 42px !important;}
.fs-43 {font-size: 43px !important;}
.fs-44 {font-size: 44px !important;}
.fs-45 {font-size: 45px !important;}
.fs-46 {font-size: 46px !important;}
.fs-47 {font-size: 47px !important;}
.fs-48 {font-size: 48px !important;}
.fs-49 {font-size: 49px !important;}
.fs-50 {font-size: 50px !important;}
.fs-51 {font-size: 51px !important;}
.fs-52 {font-size: 52px !important;}
.fs-53 {font-size: 53px !important;}
.fs-54 {font-size: 54px !important;}
.fs-55 {font-size: 55px !important;}
.fs-56 {font-size: 56px !important;}
.fs-57 {font-size: 57px !important;}
.fs-58 {font-size: 58px !important;}
.fs-59 {font-size: 59px !important;}
.fs-60 {font-size: 60px !important;}
.fs-61 {font-size: 61px !important;}
.fs-62 {font-size: 62px !important;}
.fs-63 {font-size: 63px !important;}
.fs-64 {font-size: 64px !important;}
.fs-65 {font-size: 65px !important;}
.fs-66 {font-size: 66px !important;}
.fs-67 {font-size: 67px !important;}
.fs-68 {font-size: 68px !important;}
.fs-69 {font-size: 69px !important;}
.fs-70 {font-size: 70px !important;}
.fs-71 {font-size: 71px !important;}
.fs-72 {font-size: 72px !important;}
.fs-73 {font-size: 73px !important;}
.fs-74 {font-size: 74px !important;}
.fs-75 {font-size: 75px !important;}
.fs-76 {font-size: 76px !important;}
.fs-77 {font-size: 77px !important;}
.fs-78 {font-size: 78px !important;}
.fs-79 {font-size: 79px !important;}
.fs-80 {font-size: 80px !important;}
.fs-81 {font-size: 81px !important;}
.fs-82 {font-size: 82px !important;}
.fs-83 {font-size: 83px !important;}
.fs-84 {font-size: 84px !important;}
.fs-85 {font-size: 85px !important;}
.fs-86 {font-size: 86px !important;}
.fs-87 {font-size: 87px !important;}
.fs-88 {font-size: 88px !important;}
.fs-89 {font-size: 89px !important;}
.fs-90 {font-size: 90px !important;}
.fs-91 {font-size: 91px !important;}
.fs-92 {font-size: 92px !important;}
.fs-93 {font-size: 93px !important;}
.fs-94 {font-size: 94px !important;}
.fs-95 {font-size: 95px !important;}
.fs-96 {font-size: 96px !important;}
.fs-97 {font-size: 97px !important;}
.fs-98 {font-size: 98px !important;}
.fs-99 {font-size: 99px !important;}
.fs-100 {font-size: 100px !important;}
.fs-101 {font-size: 101px !important;}
.fs-102 {font-size: 102px !important;}
.fs-103 {font-size: 103px !important;}
.fs-104 {font-size: 104px !important;}
.fs-105 {font-size: 105px !important;}
.fs-106 {font-size: 106px !important;}
.fs-107 {font-size: 107px !important;}
.fs-108 {font-size: 108px !important;}
.fs-109 {font-size: 109px !important;}
.fs-110 {font-size: 110px !important;}
.fs-111 {font-size: 111px !important;}
.fs-112 {font-size: 112px !important;}
.fs-113 {font-size: 113px !important;}
.fs-114 {font-size: 114px !important;}
.fs-115 {font-size: 115px !important;}
.fs-116 {font-size: 116px !important;}
.fs-117 {font-size: 117px !important;}
.fs-118 {font-size: 118px !important;}
.fs-119 {font-size: 119px !important;}
.fs-120 {font-size: 120px !important;}
.fs-121 {font-size: 121px !important;}
.fs-122 {font-size: 122px !important;}
.fs-123 {font-size: 123px !important;}
.fs-124 {font-size: 124px !important;}
.fs-125 {font-size: 125px !important;}
.fs-126 {font-size: 126px !important;}
.fs-127 {font-size: 127px !important;}
.fs-128 {font-size: 128px !important;}
.fs-129 {font-size: 129px !important;}
.fs-130 {font-size: 130px !important;}
.fs-131 {font-size: 131px !important;}
.fs-132 {font-size: 132px !important;}
.fs-133 {font-size: 133px !important;}
.fs-134 {font-size: 134px !important;}
.fs-135 {font-size: 135px !important;}
.fs-136 {font-size: 136px !important;}
.fs-137 {font-size: 137px !important;}
.fs-138 {font-size: 138px !important;}
.fs-139 {font-size: 139px !important;}
.fs-140 {font-size: 140px !important;}
.fs-141 {font-size: 141px !important;}
.fs-142 {font-size: 142px !important;}
.fs-143 {font-size: 143px !important;}
.fs-144 {font-size: 144px !important;}
.fs-145 {font-size: 145px !important;}
.fs-146 {font-size: 146px !important;}
.fs-147 {font-size: 147px !important;}
.fs-148 {font-size: 148px !important;}
.fs-149 {font-size: 149px !important;}
.fs-150 {font-size: 150px !important;}
.fs-151 {font-size: 151px !important;}
.fs-152 {font-size: 152px !important;}
.fs-153 {font-size: 153px !important;}
.fs-154 {font-size: 154px !important;}
.fs-155 {font-size: 155px !important;}
.fs-156 {font-size: 156px !important;}
.fs-157 {font-size: 157px !important;}
.fs-158 {font-size: 158px !important;}
.fs-159 {font-size: 159px !important;}
.fs-160 {font-size: 160px !important;}
.fs-161 {font-size: 161px !important;}
.fs-162 {font-size: 162px !important;}
.fs-163 {font-size: 163px !important;}
.fs-164 {font-size: 164px !important;}
.fs-165 {font-size: 165px !important;}
.fs-166 {font-size: 166px !important;}
.fs-167 {font-size: 167px !important;}
.fs-168 {font-size: 168px !important;}
.fs-169 {font-size: 169px !important;}
.fs-170 {font-size: 170px !important;}
.fs-171 {font-size: 171px !important;}
.fs-172 {font-size: 172px !important;}
.fs-173 {font-size: 173px !important;}
.fs-174 {font-size: 174px !important;}
.fs-175 {font-size: 175px !important;}
.fs-176 {font-size: 176px !important;}
.fs-177 {font-size: 177px !important;}
.fs-178 {font-size: 178px !important;}
.fs-179 {font-size: 179px !important;}
.fs-180 {font-size: 180px !important;}
.fs-181 {font-size: 181px !important;}
.fs-182 {font-size: 182px !important;}
.fs-183 {font-size: 183px !important;}
.fs-184 {font-size: 184px !important;}
.fs-185 {font-size: 185px !important;}
.fs-186 {font-size: 186px !important;}
.fs-187 {font-size: 187px !important;}
.fs-188 {font-size: 188px !important;}
.fs-189 {font-size: 189px !important;}
.fs-190 {font-size: 190px !important;}
.fs-191 {font-size: 191px !important;}
.fs-192 {font-size: 192px !important;}
.fs-193 {font-size: 193px !important;}
.fs-194 {font-size: 194px !important;}
.fs-195 {font-size: 195px !important;}
.fs-196 {font-size: 196px !important;}
.fs-197 {font-size: 197px !important;}
.fs-198 {font-size: 198px !important;}
.fs-199 {font-size: 199px !important;}
.fs-200 {font-size: 200px !important;}
.fs-1halfrem {
  font-size: 1.5rem;
}

/*[ FONT WEIGHT ]
///////////////////////////////////////////////////////////
*/
.f-w-normal {font-weight: normal !important;}
.f-w-100 {font-weight: 100 !important;}
.f-w-200 {font-weight: 200 !important;}
.f-w-300 {font-weight: 300 !important;}
.f-w-400 {font-weight: 400 !important;}
.f-w-500 {font-weight: 500 !important;}
.f-w-600 {font-weight: 600 !important;}
.f-w-700 {font-weight: 700 !important;}
.f-w-800 {font-weight: 800 !important;}
.font-bold {font-weight:bold !important;}
/*[ PADDING ]
///////////////////////////////////////////////////////////
*/
.p-0 {padding: 0px !important;}
.p-1 {padding: 1px;}
.p-2 {padding: 2px;}
.p-3 {padding: 3px;}
.p-3-imp {padding: 3px !important;}
.p-4 {padding: 4px;}
.p-5 {padding: 5px;}
.p-5-imp {padding: 5px !important;}
.p-6 {padding: 6px;}
.p-7 {padding: 7px;}
.p-8 {padding: 8px;}
.p-8-imp {padding: 8px !important;}
.p-9 {padding: 9px;}
.p-10 {padding: 10px;}
.p-11 {padding: 11px;}
.p-12 {padding: 12px;}
.p-13 {padding: 13px;}
.p-14 {padding: 14px;}
.p-15 {padding: 15px;}
.p-16 {padding: 16px;}
.p-16-imp {padding: 16px !important;}
.p-17 {padding: 17px;}
.p-18 {padding: 18px;}
.p-19 {padding: 19px;}
.p-20 {padding: 20px;}
.p-21 {padding: 21px;}
.p-22 {padding: 22px;}
.p-23 {padding: 23px;}
.p-24 {padding: 24px;}
.p-25 {padding: 25px;}
.p-26 {padding: 26px;}
.p-27 {padding: 27px;}
.p-28 {padding: 28px;}
.p-29 {padding: 29px;}
.p-30 {padding: 30px;}
.p-31 {padding: 31px;}
.p-32 {padding: 32px;}
.p-33 {padding: 33px;}
.p-34 {padding: 34px;}
.p-35 {padding: 35px;}
.p-36 {padding: 36px;}
.p-37 {padding: 37px;}
.p-38 {padding: 38px;}
.p-39 {padding: 39px;}
.p-40 {padding: 40px;}
.p-41 {padding: 41px;}
.p-42 {padding: 42px;}
.p-43 {padding: 43px;}
.p-44 {padding: 44px;}
.p-45 {padding: 45px;}
.p-46 {padding: 46px;}
.p-47 {padding: 47px;}
.p-48 {padding: 48px;}
.p-49 {padding: 49px;}
.p-50 {padding: 50px;}
.p-l-r-0 {padding: 0 0px;}
.p-l-r-1 {padding: 0 1px;}
.p-l-r-2 {padding: 0 2px;}
.p-l-r-3 {padding: 0 3px;}
.p-l-r-4 {padding: 0 4px;}
.p-l-r-5 {padding: 0 5px;}
.p-l-r-6 {padding: 0 6px;}
.p-l-r-7 {padding: 0 7px;}
.p-l-r-8 {padding: 0 8px;}
.p-l-r-9 {padding: 0 9px;}
.p-l-r-10 {padding: 0 10px;}
.p-l-r-11 {padding: 0 11px;}
.p-l-r-12 {padding: 0 12px;}
.p-l-r-13 {padding: 0 13px;}
.p-l-r-14 {padding: 0 14px;}
.p-l-r-15 {padding: 0 15px;}
.p-l-r-16 {padding: 0 16px;}
.p-l-r-17 {padding: 0 17px;}
.p-l-r-18 {padding: 0 18px;}
.p-l-r-19 {padding: 0 19px;}
.p-l-r-20 {padding: 0 20px;}
.p-l-r-21 {padding: 0 21px;}
.p-l-r-22 {padding: 0 22px;}
.p-l-r-23 {padding: 0 23px;}
.p-l-r-24 {padding: 0 24px;}
.p-l-r-25 {padding: 0 25px;}
.p-l-r-26 {padding: 0 26px;}
.p-l-r-27 {padding: 0 27px;}
.p-l-r-28 {padding: 0 28px;}
.p-l-r-29 {padding: 0 29px;}
.p-l-r-30 {padding: 0 30px;}
.p-l-r-31 {padding: 0 31px;}
.p-l-r-32 {padding: 0 32px;}
.p-l-r-33 {padding: 0 33px;}
.p-l-r-34 {padding: 0 34px;}
.p-l-r-35 {padding: 0 35px;}
.p-l-r-36 {padding: 0 36px;}
.p-l-r-37 {padding: 0 37px;}
.p-l-r-38 {padding: 0 38px;}
.p-l-r-39 {padding: 0 39px;}
.p-l-r-40 {padding: 0 40px;}
.p-l-r-41 {padding: 0 41px;}
.p-l-r-42 {padding: 0 42px;}
.p-l-r-43 {padding: 0 43px;}
.p-l-r-44 {padding: 0 44px;}
.p-l-r-45 {padding: 0 45px;}
.p-l-r-46 {padding: 0 46px;}
.p-l-r-47 {padding: 0 47px;}
.p-l-r-48 {padding: 0 48px;}
.p-l-r-49 {padding: 0 49px;}
.p-l-r-50 {padding: 0 50px;}
.p-l-r-0-2rem {padding: 0 0.2rem !important;}
.p-t-b-4-l-r-8 {padding: 4px 8px !important;}
.p-t-b-0 {padding: 0px 0;}
.p-t-b-1 {padding: 1px 0;}
.p-t-b-2 {padding: 2px 0;}
.p-t-b-3 {padding: 3px 0;}
.p-t-b-4 {padding: 4px 0;}
.p-t-b-5 {padding: 5px 0 !important;}
.p-t-b-6 {padding: 6px 0;}
.p-t-b-7 {padding: 7px 0;}
.p-t-b-8 {padding: 8px 0;}
.p-t-b-9 {padding: 9px 0;}
.p-t-b-10 {padding: 10px 0;}
.p-t-b-11 {padding: 11px 0;}
.p-t-b-12 {padding: 12px 0;}
.p-t-b-13 {padding: 13px 0;}
.p-t-b-14 {padding: 14px 0;}
.p-t-b-15 {padding: 15px 0;}
.p-t-b-16 {padding: 16px 0;}
.p-t-b-17 {padding: 17px 0;}
.p-t-b-18 {padding: 18px 0;}
.p-t-b-19 {padding: 19px 0;}
.p-t-b-20 {padding: 20px 0;}
.p-t-b-21 {padding: 21px 0;}
.p-t-b-22 {padding: 22px 0;}
.p-t-b-23 {padding: 23px 0;}
.p-t-b-24 {padding: 24px 0;}
.p-t-b-25 {padding: 25px 0;}
.p-t-b-26 {padding: 26px 0;}
.p-t-b-27 {padding: 27px 0;}
.p-t-b-28 {padding: 28px 0;}
.p-t-b-29 {padding: 29px 0;}
.p-t-b-30 {padding: 30px 0;}
.p-t-b-31 {padding: 31px 0;}
.p-t-b-32 {padding: 32px 0;}
.p-t-b-33 {padding: 33px 0;}
.p-t-b-34 {padding: 34px 0;}
.p-t-b-35 {padding: 35px 0;}
.p-t-b-36 {padding: 36px 0;}
.p-t-b-37 {padding: 37px 0;}
.p-t-b-38 {padding: 38px 0;}
.p-t-b-39 {padding: 39px 0;}
.p-t-b-40 {padding: 40px 0;}
.p-t-b-41 {padding: 41px 0;}
.p-t-b-42 {padding: 42px 0;}
.p-t-b-43 {padding: 43px 0;}
.p-t-b-44 {padding: 44px 0;}
.p-t-b-45 {padding: 45px 0;}
.p-t-b-46 {padding: 46px 0;}
.p-t-b-47 {padding: 47px 0;}
.p-t-b-48 {padding: 48px 0;}
.p-t-b-49 {padding: 49px 0;}
.p-t-b-50 {padding: 50px 0;}
.p-t-0 {padding-top: 0px !important;}
.p-t-1 {padding-top: 1px;}
.p-t-2 {padding-top: 2px !important;}
.p-t-3 {padding-top: 3px;}
.p-t-4 {padding-top: 4px;}
.p-t-5 {padding-top: 5px;}
.p-t-6 {padding-top: 6px;}
.p-t-7 {padding-top: 7px;}
.p-t-8 {padding-top: 8px !important;}
.p-t-9 {padding-top: 9px;}
.p-t-10 {padding-top: 10px;}
.p-t-11 {padding-top: 11px;}
.p-t-12 {padding-top: 12px;}
.p-t-13 {padding-top: 13px;}
.p-t-14 {padding-top: 14px;}
.p-t-15 {padding-top: 15px;}
.p-t-16 {padding-top: 16px;}
.p-t-16-imp {padding-top: 16px !important;}
.p-t-17 {padding-top: 17px;}
.p-t-18 {padding-top: 18px;}
.p-t-19 {padding-top: 19px;}
.p-t-20 {padding-top: 20px;}
.p-t-21 {padding-top: 21px;}
.p-t-22 {padding-top: 22px;}
.p-t-23 {padding-top: 23px;}
.p-t-24 {padding-top: 24px;}
.p-t-25 {padding-top: 25px;}
.p-t-26 {padding-top: 26px;}
.p-t-27 {padding-top: 27px;}
.p-t-28 {padding-top: 28px;}
.p-t-29 {padding-top: 29px;}
.p-t-30 {padding-top: 30px;}
.p-t-31 {padding-top: 31px;}
.p-t-32 {padding-top: 32px !important;}
.p-t-33 {padding-top: 33px;}
.p-t-34 {padding-top: 34px;}
.p-t-35 {padding-top: 35px;}
.p-t-36 {padding-top: 36px;}
.p-t-37 {padding-top: 37px;}
.p-t-38 {padding-top: 38px;}
.p-t-39 {padding-top: 39px;}
.p-t-40 {padding-top: 40px !important;}
.p-t-41 {padding-top: 41px;}
.p-t-42 {padding-top: 42px;}
.p-t-43 {padding-top: 43px;}
.p-t-44 {padding-top: 44px;}
.p-t-45 {padding-top: 45px;}
.p-t-46 {padding-top: 46px;}
.p-t-47 {padding-top: 47px;}
.p-t-48 {padding-top: 48px;}
.p-t-49 {padding-top: 49px;}
.p-t-50 {padding-top: 50px !important;}
.p-t-51 {padding-top: 51px;}
.p-t-52 {padding-top: 52px;}
.p-t-53 {padding-top: 53px;}
.p-t-54 {padding-top: 54px;}
.p-t-55 {padding-top: 55px;}
.p-t-56 {padding-top: 56px;}
.p-t-57 {padding-top: 57px;}
.p-t-58 {padding-top: 58px;}
.p-t-59 {padding-top: 59px;}
.p-t-60 {padding-top: 60px;}
.p-t-61 {padding-top: 61px;}
.p-t-62 {padding-top: 62px;}
.p-t-63 {padding-top: 63px;}
.p-t-64 {padding-top: 64px;}
.p-t-65 {padding-top: 65px;}
.p-t-66 {padding-top: 66px;}
.p-t-67 {padding-top: 67px;}
.p-t-68 {padding-top: 68px;}
.p-t-69 {padding-top: 69px;}
.p-t-70 {padding-top: 70px;}
.p-t-71 {padding-top: 71px;}
.p-t-72 {padding-top: 72px;}
.p-t-73 {padding-top: 73px;}
.p-t-74 {padding-top: 74px;}
.p-t-75 {padding-top: 75px;}
.p-t-76 {padding-top: 76px;}
.p-t-77 {padding-top: 77px;}
.p-t-78 {padding-top: 78px;}
.p-t-79 {padding-top: 79px;}
.p-t-80 {padding-top: 80px;}
.p-t-81 {padding-top: 81px;}
.p-t-82 {padding-top: 82px;}
.p-t-83 {padding-top: 83px;}
.p-t-84 {padding-top: 84px;}
.p-t-85 {padding-top: 85px;}
.p-t-86 {padding-top: 86px;}
.p-t-87 {padding-top: 87px;}
.p-t-88 {padding-top: 88px;}
.p-t-89 {padding-top: 89px;}
.p-t-90 {padding-top: 90px;}
.p-t-91 {padding-top: 91px;}
.p-t-92 {padding-top: 92px;}
.p-t-93 {padding-top: 93px;}
.p-t-94 {padding-top: 94px;}
.p-t-95 {padding-top: 95px;}
.p-t-96 {padding-top: 96px;}
.p-t-97 {padding-top: 97px;}
.p-t-98 {padding-top: 98px;}
.p-t-99 {padding-top: 99px;}
.p-t-100 {padding-top: 100px;}
.p-t-101 {padding-top: 101px;}
.p-t-102 {padding-top: 102px;}
.p-t-103 {padding-top: 103px;}
.p-t-104 {padding-top: 104px;}
.p-t-105 {padding-top: 105px;}
.p-t-106 {padding-top: 106px;}
.p-t-107 {padding-top: 107px;}
.p-t-108 {padding-top: 108px;}
.p-t-109 {padding-top: 109px;}
.p-t-110 {padding-top: 110px;}
.p-t-111 {padding-top: 111px;}
.p-t-112 {padding-top: 112px;}
.p-t-113 {padding-top: 113px;}
.p-t-114 {padding-top: 114px;}
.p-t-115 {padding-top: 115px;}
.p-t-116 {padding-top: 116px;}
.p-t-117 {padding-top: 117px;}
.p-t-118 {padding-top: 118px;}
.p-t-119 {padding-top: 119px;}
.p-t-120 {padding-top: 120px;}
.p-t-121 {padding-top: 121px;}
.p-t-122 {padding-top: 122px;}
.p-t-123 {padding-top: 123px;}
.p-t-124 {padding-top: 124px;}
.p-t-125 {padding-top: 125px;}
.p-t-126 {padding-top: 126px;}
.p-t-127 {padding-top: 127px;}
.p-t-128 {padding-top: 128px;}
.p-t-129 {padding-top: 129px;}
.p-t-130 {padding-top: 130px;}
.p-t-131 {padding-top: 131px;}
.p-t-132 {padding-top: 132px;}
.p-t-133 {padding-top: 133px;}
.p-t-134 {padding-top: 134px;}
.p-t-135 {padding-top: 135px;}
.p-t-136 {padding-top: 136px;}
.p-t-137 {padding-top: 137px;}
.p-t-138 {padding-top: 138px;}
.p-t-139 {padding-top: 139px;}
.p-t-140 {padding-top: 140px;}
.p-t-141 {padding-top: 141px;}
.p-t-142 {padding-top: 142px;}
.p-t-143 {padding-top: 143px;}
.p-t-144 {padding-top: 144px;}
.p-t-145 {padding-top: 145px;}
.p-t-146 {padding-top: 146px;}
.p-t-147 {padding-top: 147px;}
.p-t-148 {padding-top: 148px;}
.p-t-149 {padding-top: 149px;}
.p-t-150 {padding-top: 150px;}
.p-t-151 {padding-top: 151px;}
.p-t-152 {padding-top: 152px;}
.p-t-153 {padding-top: 153px;}
.p-t-154 {padding-top: 154px;}
.p-t-155 {padding-top: 155px;}
.p-t-156 {padding-top: 156px;}
.p-t-157 {padding-top: 157px;}
.p-t-158 {padding-top: 158px;}
.p-t-159 {padding-top: 159px;}
.p-t-160 {padding-top: 160px;}
.p-t-161 {padding-top: 161px;}
.p-t-162 {padding-top: 162px;}
.p-t-163 {padding-top: 163px;}
.p-t-164 {padding-top: 164px;}
.p-t-165 {padding-top: 165px;}
.p-t-166 {padding-top: 166px;}
.p-t-167 {padding-top: 167px;}
.p-t-168 {padding-top: 168px;}
.p-t-169 {padding-top: 169px;}
.p-t-170 {padding-top: 170px;}
.p-t-171 {padding-top: 171px;}
.p-t-172 {padding-top: 172px;}
.p-t-173 {padding-top: 173px;}
.p-t-174 {padding-top: 174px;}
.p-t-175 {padding-top: 175px;}
.p-t-176 {padding-top: 176px;}
.p-t-177 {padding-top: 177px;}
.p-t-178 {padding-top: 178px;}
.p-t-179 {padding-top: 179px;}
.p-t-180 {padding-top: 180px;}
.p-t-181 {padding-top: 181px;}
.p-t-182 {padding-top: 182px;}
.p-t-183 {padding-top: 183px;}
.p-t-184 {padding-top: 184px;}
.p-t-185 {padding-top: 185px;}
.p-t-186 {padding-top: 186px;}
.p-t-187 {padding-top: 187px;}
.p-t-188 {padding-top: 188px;}
.p-t-189 {padding-top: 189px;}
.p-t-190 {padding-top: 190px;}
.p-t-191 {padding-top: 191px;}
.p-t-192 {padding-top: 192px;}
.p-t-193 {padding-top: 193px;}
.p-t-194 {padding-top: 194px;}
.p-t-195 {padding-top: 195px;}
.p-t-196 {padding-top: 196px;}
.p-t-197 {padding-top: 197px;}
.p-t-198 {padding-top: 198px;}
.p-t-199 {padding-top: 199px;}
.p-t-200 {padding-top: 200px;}
.p-t-201 {padding-top: 201px;}
.p-t-202 {padding-top: 202px;}
.p-t-203 {padding-top: 203px;}
.p-t-204 {padding-top: 204px;}
.p-t-205 {padding-top: 205px;}
.p-t-206 {padding-top: 206px;}
.p-t-207 {padding-top: 207px;}
.p-t-208 {padding-top: 208px;}
.p-t-209 {padding-top: 209px;}
.p-t-210 {padding-top: 210px;}
.p-t-211 {padding-top: 211px;}
.p-t-212 {padding-top: 212px;}
.p-t-213 {padding-top: 213px;}
.p-t-214 {padding-top: 214px;}
.p-t-215 {padding-top: 215px;}
.p-t-216 {padding-top: 216px;}
.p-t-217 {padding-top: 217px;}
.p-t-218 {padding-top: 218px;}
.p-t-219 {padding-top: 219px;}
.p-t-220 {padding-top: 220px;}
.p-t-221 {padding-top: 221px;}
.p-t-222 {padding-top: 222px;}
.p-t-223 {padding-top: 223px;}
.p-t-224 {padding-top: 224px;}
.p-t-225 {padding-top: 225px;}
.p-t-226 {padding-top: 226px;}
.p-t-227 {padding-top: 227px;}
.p-t-228 {padding-top: 228px;}
.p-t-229 {padding-top: 229px;}
.p-t-230 {padding-top: 230px;}
.p-t-231 {padding-top: 231px;}
.p-t-232 {padding-top: 232px;}
.p-t-233 {padding-top: 233px;}
.p-t-234 {padding-top: 234px;}
.p-t-235 {padding-top: 235px;}
.p-t-236 {padding-top: 236px;}
.p-t-237 {padding-top: 237px;}
.p-t-238 {padding-top: 238px;}
.p-t-239 {padding-top: 239px;}
.p-t-240 {padding-top: 240px;}
.p-t-241 {padding-top: 241px;}
.p-t-242 {padding-top: 242px;}
.p-t-243 {padding-top: 243px;}
.p-t-244 {padding-top: 244px;}
.p-t-245 {padding-top: 245px;}
.p-t-246 {padding-top: 246px;}
.p-t-247 {padding-top: 247px;}
.p-t-248 {padding-top: 248px;}
.p-t-249 {padding-top: 249px;}
.p-t-250 {padding-top: 250px;}
.p-b-0 {padding-bottom: 0px !important;}
.p-b-1 {padding-bottom: 1px;}
.p-b-2 {padding-bottom: 2px;}
.p-b-3 {padding-bottom: 3px;}
.p-b-4 {padding-bottom: 4px;}
.p-b-4-imp {padding-bottom: 4px !important;}
.p-b-5 {padding-bottom: 5px;}
.p-b-6 {padding-bottom: 6px;}
.p-b-7 {padding-bottom: 7px;}
.p-b-8 {padding-bottom: 8px;}
.p-b-9 {padding-bottom: 9px;}
.p-b-10 {padding-bottom: 10px;}
.p-b-11 {padding-bottom: 11px;}
.p-b-12 {padding-bottom: 12px;}
.p-b-13 {padding-bottom: 13px;}
.p-b-14 {padding-bottom: 14px;}
.p-b-15 {padding-bottom: 15px;}
.p-b-16 {padding-bottom: 16px;}
.p-b-17 {padding-bottom: 17px;}
.p-b-18 {padding-bottom: 18px;}
.p-b-19 {padding-bottom: 19px;}
.p-b-20 {padding-bottom: 20px;}
.p-b-21 {padding-bottom: 21px;}
.p-b-22 {padding-bottom: 22px;}
.p-b-23 {padding-bottom: 23px;}
.p-b-24 {padding-bottom: 24px;}
.p-b-25 {padding-bottom: 25px;}
.p-b-26 {padding-bottom: 26px;}
.p-b-27 {padding-bottom: 27px;}
.p-b-28 {padding-bottom: 28px;}
.p-b-29 {padding-bottom: 29px;}
.p-b-30 {padding-bottom: 30px;}
.p-b-31 {padding-bottom: 31px;}
.p-b-32 {padding-bottom: 32px;}
.p-b-33 {padding-bottom: 33px;}
.p-b-34 {padding-bottom: 34px;}
.p-b-35 {padding-bottom: 35px;}
.p-b-36 {padding-bottom: 36px;}
.p-b-37 {padding-bottom: 37px;}
.p-b-38 {padding-bottom: 38px;}
.p-b-39 {padding-bottom: 39px;}
.p-b-40 {padding-bottom: 40px;}
.p-b-41 {padding-bottom: 41px;}
.p-b-42 {padding-bottom: 42px;}
.p-b-43 {padding-bottom: 43px;}
.p-b-44 {padding-bottom: 44px;}
.p-b-45 {padding-bottom: 45px;}
.p-b-46 {padding-bottom: 46px;}
.p-b-47 {padding-bottom: 47px;}
.p-b-48 {padding-bottom: 48px;}
.p-b-49 {padding-bottom: 49px;}
.p-b-50 {padding-bottom: 50px;}
.p-b-51 {padding-bottom: 51px;}
.p-b-52 {padding-bottom: 52px;}
.p-b-53 {padding-bottom: 53px;}
.p-b-54 {padding-bottom: 54px;}
.p-b-55 {padding-bottom: 55px;}
.p-b-56 {padding-bottom: 56px;}
.p-b-57 {padding-bottom: 57px;}
.p-b-58 {padding-bottom: 58px;}
.p-b-59 {padding-bottom: 59px;}
.p-b-60 {padding-bottom: 60px;}
.p-b-61 {padding-bottom: 61px;}
.p-b-62 {padding-bottom: 62px;}
.p-b-63 {padding-bottom: 63px;}
.p-b-64 {padding-bottom: 64px;}
.p-b-65 {padding-bottom: 65px;}
.p-b-66 {padding-bottom: 66px;}
.p-b-67 {padding-bottom: 67px;}
.p-b-68 {padding-bottom: 68px;}
.p-b-69 {padding-bottom: 69px;}
.p-b-70 {padding-bottom: 70px;}
.p-b-71 {padding-bottom: 71px;}
.p-b-72 {padding-bottom: 72px;}
.p-b-73 {padding-bottom: 73px;}
.p-b-74 {padding-bottom: 74px;}
.p-b-75 {padding-bottom: 75px;}
.p-b-76 {padding-bottom: 76px;}
.p-b-77 {padding-bottom: 77px;}
.p-b-78 {padding-bottom: 78px;}
.p-b-79 {padding-bottom: 79px;}
.p-b-80 {padding-bottom: 80px;}
.p-b-81 {padding-bottom: 81px;}
.p-b-82 {padding-bottom: 82px;}
.p-b-83 {padding-bottom: 83px;}
.p-b-84 {padding-bottom: 84px;}
.p-b-85 {padding-bottom: 85px;}
.p-b-86 {padding-bottom: 86px;}
.p-b-87 {padding-bottom: 87px;}
.p-b-88 {padding-bottom: 88px;}
.p-b-89 {padding-bottom: 89px;}
.p-b-90 {padding-bottom: 90px;}
.p-b-91 {padding-bottom: 91px;}
.p-b-92 {padding-bottom: 92px;}
.p-b-93 {padding-bottom: 93px;}
.p-b-94 {padding-bottom: 94px;}
.p-b-95 {padding-bottom: 95px;}
.p-b-96 {padding-bottom: 96px;}
.p-b-97 {padding-bottom: 97px;}
.p-b-98 {padding-bottom: 98px;}
.p-b-99 {padding-bottom: 99px;}
.p-b-100 {padding-bottom: 100px;}
.p-b-101 {padding-bottom: 101px;}
.p-b-102 {padding-bottom: 102px;}
.p-b-103 {padding-bottom: 103px;}
.p-b-104 {padding-bottom: 104px;}
.p-b-105 {padding-bottom: 105px;}
.p-b-106 {padding-bottom: 106px;}
.p-b-107 {padding-bottom: 107px;}
.p-b-108 {padding-bottom: 108px;}
.p-b-109 {padding-bottom: 109px;}
.p-b-110 {padding-bottom: 110px;}
.p-b-111 {padding-bottom: 111px;}
.p-b-112 {padding-bottom: 112px;}
.p-b-113 {padding-bottom: 113px;}
.p-b-114 {padding-bottom: 114px;}
.p-b-115 {padding-bottom: 115px;}
.p-b-116 {padding-bottom: 116px;}
.p-b-117 {padding-bottom: 117px;}
.p-b-118 {padding-bottom: 118px;}
.p-b-119 {padding-bottom: 119px;}
.p-b-120 {padding-bottom: 120px;}
.p-b-121 {padding-bottom: 121px;}
.p-b-122 {padding-bottom: 122px;}
.p-b-123 {padding-bottom: 123px;}
.p-b-124 {padding-bottom: 124px;}
.p-b-125 {padding-bottom: 125px;}
.p-b-126 {padding-bottom: 126px;}
.p-b-127 {padding-bottom: 127px;}
.p-b-128 {padding-bottom: 128px;}
.p-b-129 {padding-bottom: 129px;}
.p-b-130 {padding-bottom: 130px;}
.p-b-131 {padding-bottom: 131px;}
.p-b-132 {padding-bottom: 132px;}
.p-b-133 {padding-bottom: 133px;}
.p-b-134 {padding-bottom: 134px;}
.p-b-135 {padding-bottom: 135px;}
.p-b-136 {padding-bottom: 136px;}
.p-b-137 {padding-bottom: 137px;}
.p-b-138 {padding-bottom: 138px;}
.p-b-139 {padding-bottom: 139px;}
.p-b-140 {padding-bottom: 140px;}
.p-b-141 {padding-bottom: 141px;}
.p-b-142 {padding-bottom: 142px;}
.p-b-143 {padding-bottom: 143px;}
.p-b-144 {padding-bottom: 144px;}
.p-b-145 {padding-bottom: 145px;}
.p-b-146 {padding-bottom: 146px;}
.p-b-147 {padding-bottom: 147px;}
.p-b-148 {padding-bottom: 148px;}
.p-b-149 {padding-bottom: 149px;}
.p-b-150 {padding-bottom: 150px;}
.p-b-151 {padding-bottom: 151px;}
.p-b-152 {padding-bottom: 152px;}
.p-b-153 {padding-bottom: 153px;}
.p-b-154 {padding-bottom: 154px;}
.p-b-155 {padding-bottom: 155px;}
.p-b-156 {padding-bottom: 156px;}
.p-b-157 {padding-bottom: 157px;}
.p-b-158 {padding-bottom: 158px;}
.p-b-159 {padding-bottom: 159px;}
.p-b-160 {padding-bottom: 160px;}
.p-b-161 {padding-bottom: 161px;}
.p-b-162 {padding-bottom: 162px;}
.p-b-163 {padding-bottom: 163px;}
.p-b-164 {padding-bottom: 164px;}
.p-b-165 {padding-bottom: 165px;}
.p-b-166 {padding-bottom: 166px;}
.p-b-167 {padding-bottom: 167px;}
.p-b-168 {padding-bottom: 168px;}
.p-b-169 {padding-bottom: 169px;}
.p-b-170 {padding-bottom: 170px;}
.p-b-171 {padding-bottom: 171px;}
.p-b-172 {padding-bottom: 172px;}
.p-b-173 {padding-bottom: 173px;}
.p-b-174 {padding-bottom: 174px;}
.p-b-175 {padding-bottom: 175px;}
.p-b-176 {padding-bottom: 176px;}
.p-b-177 {padding-bottom: 177px;}
.p-b-178 {padding-bottom: 178px;}
.p-b-179 {padding-bottom: 179px;}
.p-b-180 {padding-bottom: 180px;}
.p-b-181 {padding-bottom: 181px;}
.p-b-182 {padding-bottom: 182px;}
.p-b-183 {padding-bottom: 183px;}
.p-b-184 {padding-bottom: 184px;}
.p-b-185 {padding-bottom: 185px;}
.p-b-186 {padding-bottom: 186px;}
.p-b-187 {padding-bottom: 187px;}
.p-b-188 {padding-bottom: 188px;}
.p-b-189 {padding-bottom: 189px;}
.p-b-190 {padding-bottom: 190px;}
.p-b-191 {padding-bottom: 191px;}
.p-b-192 {padding-bottom: 192px;}
.p-b-193 {padding-bottom: 193px;}
.p-b-194 {padding-bottom: 194px;}
.p-b-195 {padding-bottom: 195px;}
.p-b-196 {padding-bottom: 196px;}
.p-b-197 {padding-bottom: 197px;}
.p-b-198 {padding-bottom: 198px;}
.p-b-199 {padding-bottom: 199px;}
.p-b-200 {padding-bottom: 200px;}
.p-b-201 {padding-bottom: 201px;}
.p-b-202 {padding-bottom: 202px;}
.p-b-203 {padding-bottom: 203px;}
.p-b-204 {padding-bottom: 204px;}
.p-b-205 {padding-bottom: 205px;}
.p-b-206 {padding-bottom: 206px;}
.p-b-207 {padding-bottom: 207px;}
.p-b-208 {padding-bottom: 208px;}
.p-b-209 {padding-bottom: 209px;}
.p-b-210 {padding-bottom: 210px;}
.p-b-211 {padding-bottom: 211px;}
.p-b-212 {padding-bottom: 212px;}
.p-b-213 {padding-bottom: 213px;}
.p-b-214 {padding-bottom: 214px;}
.p-b-215 {padding-bottom: 215px;}
.p-b-216 {padding-bottom: 216px;}
.p-b-217 {padding-bottom: 217px;}
.p-b-218 {padding-bottom: 218px;}
.p-b-219 {padding-bottom: 219px;}
.p-b-220 {padding-bottom: 220px;}
.p-b-221 {padding-bottom: 221px;}
.p-b-222 {padding-bottom: 222px;}
.p-b-223 {padding-bottom: 223px;}
.p-b-224 {padding-bottom: 224px;}
.p-b-225 {padding-bottom: 225px;}
.p-b-226 {padding-bottom: 226px;}
.p-b-227 {padding-bottom: 227px;}
.p-b-228 {padding-bottom: 228px;}
.p-b-229 {padding-bottom: 229px;}
.p-b-230 {padding-bottom: 230px;}
.p-b-231 {padding-bottom: 231px;}
.p-b-232 {padding-bottom: 232px;}
.p-b-233 {padding-bottom: 233px;}
.p-b-234 {padding-bottom: 234px;}
.p-b-235 {padding-bottom: 235px;}
.p-b-236 {padding-bottom: 236px;}
.p-b-237 {padding-bottom: 237px;}
.p-b-238 {padding-bottom: 238px;}
.p-b-239 {padding-bottom: 239px;}
.p-b-240 {padding-bottom: 240px;}
.p-b-241 {padding-bottom: 241px;}
.p-b-242 {padding-bottom: 242px;}
.p-b-243 {padding-bottom: 243px;}
.p-b-244 {padding-bottom: 244px;}
.p-b-245 {padding-bottom: 245px;}
.p-b-246 {padding-bottom: 246px;}
.p-b-247 {padding-bottom: 247px;}
.p-b-248 {padding-bottom: 248px;}
.p-b-249 {padding-bottom: 249px;}
.p-b-250 {padding-bottom: 250px;}
.p-l-0 {padding-left: 0px !important;}
.p-l-1 {padding-left: 1px;}
.p-l-2 {padding-left: 2px;}
.p-l-3 {padding-left: 3px !important;}
.p-l-4 {padding-left: 4px;}
.p-l-5 {padding-left: 5px;}
.p-l-6 {padding-left: 6px;}
.p-l-7 {padding-left: 7px;}
.p-l-8 {padding-left: 8px !important; }
.p-l-9 {padding-left: 9px;}
.p-l-10 {padding-left: 10px;}
.p-l-10-imp {padding-left: 10px !important;}
.p-l-11 {padding-left: 11px;}
.p-l-12 {padding-left: 12px;}
.p-l-13 {padding-left: 13px;}
.p-l-14 {padding-left: 14px;}
.p-l-15 {padding-left: 15px;}
.p-l-16 {padding-left: 16px;}
.p-l-16-imp {padding-left: 16px !important;}
.p-l-17 {padding-left: 17px;}
.p-l-18 {padding-left: 18px;}
.p-l-19 {padding-left: 19px;}
.p-l-20 {padding-left: 20px;}
.p-l-21 {padding-left: 21px;}
.p-l-22 {padding-left: 22px;}
.p-l-23 {padding-left: 23px;}
.p-l-24 {padding-left: 24px !important;}
.p-l-25 {padding-left: 25px;}
.p-l-26 {padding-left: 26px;}
.p-l-27 {padding-left: 27px;}
.p-l-28 {padding-left: 28px;}
.p-l-29 {padding-left: 29px;}
.p-l-30 {padding-left: 30px !important;}
.p-l-31 {padding-left: 31px;}
.p-l-32 {padding-left: 32px;}
.p-l-33 {padding-left: 33px;}
.p-l-34 {padding-left: 34px;}

/*
  Modified By: Arun Singh
  Modified On: 05 sep 2023
  Modification: Add padding-left 35px !important class .
*/

.p-l-35-imp {padding-left: 35px !important;}
.p-l-35 {padding-left: 35px;}
.p-l-36 {padding-left: 36px;}
.p-l-37 {padding-left: 37px;}
.p-l-38 {padding-left: 38px;}
.p-l-39 {padding-left: 39px;}
.p-l-40 {padding-left: 40px;}
.p-l-41 {padding-left: 41px;}
.p-l-42 {padding-left: 42px;}
.p-l-43 {padding-left: 43px;}
.p-l-44 {padding-left: 44px;}
.p-l-45 {padding-left: 45px;}
.p-l-46 {padding-left: 46px;}
.p-l-47 {padding-left: 47px;}
.p-l-48 {padding-left: 48px;}
.p-l-49 {padding-left: 49px;}
.p-l-50 {padding-left: 50px;}
.p-l-51 {padding-left: 51px;}
.p-l-52 {padding-left: 52px;}
.p-l-53 {padding-left: 53px;}
.p-l-54 {padding-left: 54px;}
.p-l-55 {padding-left: 55px;}
.p-l-56 {padding-left: 56px;}
.p-l-57 {padding-left: 57px;}
.p-l-58 {padding-left: 58px;}
.p-l-59 {padding-left: 59px;}
.p-l-60 {padding-left: 60px;}
.p-l-61 {padding-left: 61px;}
.p-l-62 {padding-left: 62px;}
.p-l-63 {padding-left: 63px;}
.p-l-64 {padding-left: 64px !important;}
.p-l-65 {padding-left: 65px;}
.p-l-66 {padding-left: 66px;}
.p-l-67 {padding-left: 67px;}
.p-l-68 {padding-left: 68px;}
.p-l-69 {padding-left: 69px;}
.p-l-70 {padding-left: 70px;}
.p-l-71 {padding-left: 71px;}
.p-l-72 {padding-left: 72px;}
.p-l-73 {padding-left: 73px;}
.p-l-74 {padding-left: 74px;}
.p-l-75 {padding-left: 75px;}
.p-l-76 {padding-left: 76px;}
.p-l-77 {padding-left: 77px;}
.p-l-78 {padding-left: 78px;}
.p-l-79 {padding-left: 79px;}
.p-l-80 {padding-left: 80px;}
.p-l-81 {padding-left: 81px;}
.p-l-82 {padding-left: 82px;}
.p-l-83 {padding-left: 83px;}
.p-l-84 {padding-left: 84px;}
.p-l-85 {padding-left: 85px;}
.p-l-86 {padding-left: 86px;}
.p-l-87 {padding-left: 87px;}
.p-l-88 {padding-left: 88px;}
.p-l-89 {padding-left: 89px;}
.p-l-90 {padding-left: 90px;}
.p-l-91 {padding-left: 91px;}
.p-l-92 {padding-left: 92px;}
.p-l-93 {padding-left: 93px;}
.p-l-94 {padding-left: 94px;}
.p-l-95 {padding-left: 95px;}
.p-l-96 {padding-left: 96px;}
.p-l-97 {padding-left: 97px;}
.p-l-98 {padding-left: 98px;}
.p-l-99 {padding-left: 99px;}
.p-l-100 {padding-left: 100px;}
.p-l-101 {padding-left: 101px;}
.p-l-102 {padding-left: 102px;}
.p-l-103 {padding-left: 103px;}
.p-l-104 {padding-left: 104px;}
.p-l-105 {padding-left: 105px;}
.p-l-106 {padding-left: 106px;}
.p-l-107 {padding-left: 107px;}
.p-l-108 {padding-left: 108px;}
.p-l-109 {padding-left: 109px;}
.p-l-110 {padding-left: 110px;}
.p-l-111 {padding-left: 111px;}
.p-l-112 {padding-left: 112px;}
.p-l-113 {padding-left: 113px;}
.p-l-114 {padding-left: 114px;}
.p-l-115 {padding-left: 115px;}
.p-l-116 {padding-left: 116px;}
.p-l-117 {padding-left: 117px;}
.p-l-118 {padding-left: 118px;}
.p-l-119 {padding-left: 119px;}
.p-l-120 {padding-left: 120px;}
.p-l-121 {padding-left: 121px;}
.p-l-122 {padding-left: 122px;}
.p-l-123 {padding-left: 123px;}
.p-l-124 {padding-left: 124px;}
.p-l-125 {padding-left: 125px;}
.p-l-126 {padding-left: 126px;}
.p-l-127 {padding-left: 127px;}
.p-l-128 {padding-left: 128px;}
.p-l-129 {padding-left: 129px;}
.p-l-130 {padding-left: 130px;}
.p-l-131 {padding-left: 131px;}
.p-l-132 {padding-left: 132px;}
.p-l-133 {padding-left: 133px;}
.p-l-134 {padding-left: 134px;}
.p-l-135 {padding-left: 135px;}
.p-l-136 {padding-left: 136px;}
.p-l-137 {padding-left: 137px;}
.p-l-138 {padding-left: 138px;}
.p-l-139 {padding-left: 139px;}
.p-l-140 {padding-left: 140px;}
.p-l-141 {padding-left: 141px;}
.p-l-142 {padding-left: 142px;}
.p-l-143 {padding-left: 143px;}
.p-l-144 {padding-left: 144px;}
.p-l-145 {padding-left: 145px;}
.p-l-146 {padding-left: 146px;}
.p-l-147 {padding-left: 147px;}
.p-l-148 {padding-left: 148px;}
.p-l-149 {padding-left: 149px;}
.p-l-150 {padding-left: 150px;}
.p-l-151 {padding-left: 151px;}
.p-l-152 {padding-left: 152px;}
.p-l-153 {padding-left: 153px;}
.p-l-154 {padding-left: 154px;}
.p-l-155 {padding-left: 155px;}
.p-l-156 {padding-left: 156px;}
.p-l-157 {padding-left: 157px;}
.p-l-158 {padding-left: 158px;}
.p-l-159 {padding-left: 159px;}
.p-l-160 {padding-left: 160px;}
.p-l-161 {padding-left: 161px;}
.p-l-162 {padding-left: 162px;}
.p-l-163 {padding-left: 163px;}
.p-l-164 {padding-left: 164px;}
.p-l-165 {padding-left: 165px;}
.p-l-166 {padding-left: 166px;}
.p-l-167 {padding-left: 167px;}
.p-l-168 {padding-left: 168px;}
.p-l-169 {padding-left: 169px;}
.p-l-170 {padding-left: 170px;}
.p-l-171 {padding-left: 171px;}
.p-l-172 {padding-left: 172px;}
.p-l-173 {padding-left: 173px;}
.p-l-174 {padding-left: 174px;}
.p-l-175 {padding-left: 175px;}
.p-l-176 {padding-left: 176px;}
.p-l-177 {padding-left: 177px;}
.p-l-178 {padding-left: 178px;}
.p-l-179 {padding-left: 179px;}
.p-l-180 {padding-left: 180px;}
.p-l-181 {padding-left: 181px;}
.p-l-182 {padding-left: 182px;}
.p-l-183 {padding-left: 183px;}
.p-l-184 {padding-left: 184px;}
.p-l-185 {padding-left: 185px;}
.p-l-186 {padding-left: 186px;}
.p-l-187 {padding-left: 187px;}
.p-l-188 {padding-left: 188px;}
.p-l-189 {padding-left: 189px;}
.p-l-190 {padding-left: 190px;}
.p-l-191 {padding-left: 191px;}
.p-l-192 {padding-left: 192px;}
.p-l-193 {padding-left: 193px;}
.p-l-194 {padding-left: 194px;}
.p-l-195 {padding-left: 195px;}
.p-l-196 {padding-left: 196px;}
.p-l-197 {padding-left: 197px;}
.p-l-198 {padding-left: 198px;}
.p-l-199 {padding-left: 199px;}
.p-l-200 {padding-left: 200px;}
.p-l-201 {padding-left: 201px;}
.p-l-202 {padding-left: 202px;}
.p-l-203 {padding-left: 203px;}
.p-l-204 {padding-left: 204px;}
.p-l-205 {padding-left: 205px;}
.p-l-206 {padding-left: 206px;}
.p-l-207 {padding-left: 207px;}
.p-l-208 {padding-left: 208px;}
.p-l-209 {padding-left: 209px;}
.p-l-210 {padding-left: 210px;}
.p-l-211 {padding-left: 211px;}
.p-l-212 {padding-left: 212px;}
.p-l-213 {padding-left: 213px;}
.p-l-214 {padding-left: 214px;}
.p-l-215 {padding-left: 215px;}
.p-l-216 {padding-left: 216px;}
.p-l-217 {padding-left: 217px;}
.p-l-218 {padding-left: 218px;}
.p-l-219 {padding-left: 219px;}
.p-l-220 {padding-left: 220px;}
.p-l-221 {padding-left: 221px;}
.p-l-222 {padding-left: 222px;}
.p-l-223 {padding-left: 223px;}
.p-l-224 {padding-left: 224px;}
.p-l-225 {padding-left: 225px;}
.p-l-226 {padding-left: 226px;}
.p-l-227 {padding-left: 227px;}
.p-l-228 {padding-left: 228px;}
.p-l-229 {padding-left: 229px;}
.p-l-230 {padding-left: 230px;}
.p-l-231 {padding-left: 231px;}
.p-l-232 {padding-left: 232px;}
.p-l-233 {padding-left: 233px;}
.p-l-234 {padding-left: 234px;}
.p-l-235 {padding-left: 235px;}
.p-l-236 {padding-left: 236px;}
.p-l-237 {padding-left: 237px;}
.p-l-238 {padding-left: 238px;}
.p-l-239 {padding-left: 239px;}
.p-l-240 {padding-left: 240px;}
.p-l-241 {padding-left: 241px;}
.p-l-242 {padding-left: 242px;}
.p-l-243 {padding-left: 243px;}
.p-l-244 {padding-left: 244px;}
.p-l-245 {padding-left: 245px;}
.p-l-246 {padding-left: 246px;}
.p-l-247 {padding-left: 247px;}
.p-l-248 {padding-left: 248px;}
.p-l-249 {padding-left: 249px;}
.p-l-250 {padding-left: 250px;}
.p-r-05rem {padding-right:0.5rem !important;}
.p-l-05rem {padding-left:0.5rem !important;}
.p-r-0 {padding-right: 0px !important;}
.p-r-1 {padding-right: 1px;}
.p-r-2 {padding-right: 2px;}
.p-r-3 {padding-right: 3px;}
.p-r-4 {padding-right: 4px;}
.p-r-5 {padding-right: 5px;}
.p-r-6 {padding-right: 6px;}
.p-r-6-imp {padding-right: 6px !important;}
.p-r-7 {padding-right: 7px;}
.p-r-8 {padding-right: 8px !important;}
.p-r-9 {padding-right: 9px;}
.p-r-10 {padding-right: 10px; }
.p-r-11 {padding-right: 11px;}
.p-r-12 {padding-right: 12px;}
.p-r-13 {padding-right: 13px;}
.p-r-14 {padding-right: 14px;}
.p-r-15 {padding-right: 15px;}
.p-r-16 {padding-right: 16px;}
.p-r-16-imp {padding-right: 16px !important;}
.p-r-17 {padding-right: 17px;}
.p-r-18 {padding-right: 18px;}
.p-r-19 {padding-right: 19px;}
.p-r-20 {padding-right: 20px;}
.p-r-21 {padding-right: 21px;}
.p-r-22 {padding-right: 22px;}
.p-r-23 {padding-right: 23px;}
.p-r-24 {padding-right: 24px;}
.p-r-25 {padding-right: 25px !important;}
.p-r-26 {padding-right: 26px;}
.p-r-27 {padding-right: 27px;}
.p-r-28 {padding-right: 28px;}
.p-r-29 {padding-right: 29px;}
.p-r-30 {padding-right: 30px !important;}
.p-r-31 {padding-right: 31px;}
.p-r-32 {padding-right: 32px;}
.p-r-33 {padding-right: 33px;}
.p-r-34 {padding-right: 34px;}

/*
  modified by Arun singh
  modifield on 04/sept/2023
  modification: Add padding right !important class
*/

.p-r-34-imp {padding-right: 34px !important;}
.p-r-35 {padding-right: 35px;}
.p-r-36 {padding-right: 36px;}
.p-r-37 {padding-right: 37px;}
.p-r-38 {padding-right: 38px;}
.p-r-39 {padding-right: 39px;}
.p-r-40 {padding-right: 40px !important;}
.p-r-41 {padding-right: 41px;}
.p-r-42 {padding-right: 42px;}
.p-r-43 {padding-right: 43px;}
.p-r-44 {padding-right: 44px;}
.p-r-45 {padding-right: 45px;}
.p-r-46 {padding-right: 46px;}
.p-r-47 {padding-right: 47px;}
.p-r-48 {padding-right: 48px !important;}
.p-r-49 {padding-right: 49px;}
.p-r-50 {padding-right: 50px;}
.p-r-51 {padding-right: 51px;}
.p-r-52 {padding-right: 52px;}
.p-r-53 {padding-right: 53px;}
.p-r-54 {padding-right: 54px;}
.p-r-55 {padding-right: 55px;}
.p-r-56 {padding-right: 56px;}
.p-r-57 {padding-right: 57px;}
.p-r-58 {padding-right: 58px;}
.p-r-59 {padding-right: 59px;}
.p-r-60 {padding-right: 60px;}
.p-r-61 {padding-right: 61px;}
.p-r-62 {padding-right: 62px;}
.p-r-63 {padding-right: 63px;}
.p-r-64 {padding-right: 64px;}
.p-r-65 {padding-right: 65px;}
.p-r-66 {padding-right: 66px;}
.p-r-67 {padding-right: 67px;}
.p-r-68 {padding-right: 68px;}
.p-r-69 {padding-right: 69px;}
.p-r-70 {padding-right: 70px;}
.p-r-71 {padding-right: 71px;}
.p-r-72 {padding-right: 72px;}
.p-r-73 {padding-right: 73px;}
.p-r-74 {padding-right: 74px;}
.p-r-75 {padding-right: 75px;}
.p-r-76 {padding-right: 76px;}
.p-r-77 {padding-right: 77px;}
.p-r-78 {padding-right: 78px;}
.p-r-79 {padding-right: 79px;}
.p-r-80 {padding-right: 80px;}
.p-r-81 {padding-right: 81px;}
.p-r-82 {padding-right: 82px;}
.p-r-83 {padding-right: 83px;}
.p-r-84 {padding-right: 84px;}
.p-r-85 {padding-right: 85px;}
.p-r-86 {padding-right: 86px;}
.p-r-87 {padding-right: 87px;}
.p-r-88 {padding-right: 88px;}
.p-r-89 {padding-right: 89px;}
.p-r-90 {padding-right: 90px;}
.p-r-91 {padding-right: 91px;}
.p-r-92 {padding-right: 92px;}
.p-r-93 {padding-right: 93px;}
.p-r-94 {padding-right: 94px;}
.p-r-95 {padding-right: 95px;}
.p-r-96 {padding-right: 96px;}
.p-r-97 {padding-right: 97px;}
.p-r-98 {padding-right: 98px;}
.p-r-99 {padding-right: 99px;}
.p-r-100 {padding-right: 100px;}
.p-r-101 {padding-right: 101px;}
.p-r-102 {padding-right: 102px;}
.p-r-103 {padding-right: 103px;}
.p-r-104 {padding-right: 104px;}
.p-r-105 {padding-right: 105px;}
.p-r-106 {padding-right: 106px;}
.p-r-107 {padding-right: 107px;}
.p-r-108 {padding-right: 108px;}
.p-r-109 {padding-right: 109px;}
.p-r-110 {padding-right: 110px;}
.p-r-111 {padding-right: 111px;}
.p-r-112 {padding-right: 112px;}
.p-r-113 {padding-right: 113px;}
.p-r-114 {padding-right: 114px;}
.p-r-115 {padding-right: 115px;}
.p-r-116 {padding-right: 116px;}
.p-r-117 {padding-right: 117px;}
.p-r-118 {padding-right: 118px;}
.p-r-119 {padding-right: 119px;}
.p-r-120 {padding-right: 120px;}
.p-r-121 {padding-right: 121px;}
.p-r-122 {padding-right: 122px;}
.p-r-123 {padding-right: 123px;}
.p-r-124 {padding-right: 124px;}
.p-r-125 {padding-right: 125px;}
.p-r-126 {padding-right: 126px;}
.p-r-127 {padding-right: 127px;}
.p-r-128 {padding-right: 128px;}
.p-r-129 {padding-right: 129px;}
.p-r-130 {padding-right: 130px;}
.p-r-131 {padding-right: 131px;}
.p-r-132 {padding-right: 132px;}
.p-r-133 {padding-right: 133px;}
.p-r-134 {padding-right: 134px;}
.p-r-135 {padding-right: 135px;}
.p-r-136 {padding-right: 136px;}
.p-r-137 {padding-right: 137px;}
.p-r-138 {padding-right: 138px;}
.p-r-139 {padding-right: 139px;}
.p-r-140 {padding-right: 140px;}
.p-r-141 {padding-right: 141px;}
.p-r-142 {padding-right: 142px;}
.p-r-143 {padding-right: 143px;}
.p-r-144 {padding-right: 144px;}
.p-r-145 {padding-right: 145px;}
.p-r-146 {padding-right: 146px;}
.p-r-147 {padding-right: 147px;}
.p-r-148 {padding-right: 148px;}
.p-r-149 {padding-right: 149px;}
.p-r-150 {padding-right: 150px;}
.p-r-151 {padding-right: 151px;}
.p-r-152 {padding-right: 152px;}
.p-r-153 {padding-right: 153px;}
.p-r-154 {padding-right: 154px;}
.p-r-155 {padding-right: 155px;}
.p-r-156 {padding-right: 156px;}
.p-r-157 {padding-right: 157px;}
.p-r-158 {padding-right: 158px;}
.p-r-159 {padding-right: 159px;}
.p-r-160 {padding-right: 160px;}
.p-r-161 {padding-right: 161px;}
.p-r-162 {padding-right: 162px;}
.p-r-163 {padding-right: 163px;}
.p-r-164 {padding-right: 164px;}
.p-r-165 {padding-right: 165px;}
.p-r-166 {padding-right: 166px;}
.p-r-167 {padding-right: 167px;}
.p-r-168 {padding-right: 168px;}
.p-r-169 {padding-right: 169px;}
.p-r-170 {padding-right: 170px;}
.p-r-171 {padding-right: 171px;}
.p-r-172 {padding-right: 172px;}
.p-r-173 {padding-right: 173px;}
.p-r-174 {padding-right: 174px;}
.p-r-175 {padding-right: 175px;}
.p-r-176 {padding-right: 176px;}
.p-r-177 {padding-right: 177px;}
.p-r-178 {padding-right: 178px;}
.p-r-179 {padding-right: 179px;}
.p-r-180 {padding-right: 180px;}
.p-r-181 {padding-right: 181px;}
.p-r-182 {padding-right: 182px;}
.p-r-183 {padding-right: 183px;}
.p-r-184 {padding-right: 184px;}
.p-r-185 {padding-right: 185px;}
.p-r-186 {padding-right: 186px;}
.p-r-187 {padding-right: 187px;}
.p-r-188 {padding-right: 188px;}
.p-r-189 {padding-right: 189px;}
.p-r-190 {padding-right: 190px;}
.p-r-191 {padding-right: 191px;}
.p-r-192 {padding-right: 192px;}
.p-r-193 {padding-right: 193px;}
.p-r-194 {padding-right: 194px;}
.p-r-195 {padding-right: 195px;}
.p-r-196 {padding-right: 196px;}
.p-r-197 {padding-right: 197px;}
.p-r-198 {padding-right: 198px;}
.p-r-199 {padding-right: 199px;}
.p-r-200 {padding-right: 200px;}
.p-r-201 {padding-right: 201px;}
.p-r-202 {padding-right: 202px;}
.p-r-203 {padding-right: 203px;}
.p-r-204 {padding-right: 204px;}
.p-r-205 {padding-right: 205px;}
.p-r-206 {padding-right: 206px;}
.p-r-207 {padding-right: 207px;}
.p-r-208 {padding-right: 208px;}
.p-r-209 {padding-right: 209px;}
.p-r-210 {padding-right: 210px;}
.p-r-211 {padding-right: 211px;}
.p-r-212 {padding-right: 212px;}
.p-r-213 {padding-right: 213px;}
.p-r-214 {padding-right: 214px;}
.p-r-215 {padding-right: 215px;}
.p-r-216 {padding-right: 216px;}
.p-r-217 {padding-right: 217px;}
.p-r-218 {padding-right: 218px;}
.p-r-219 {padding-right: 219px;}
.p-r-220 {padding-right: 220px;}
.p-r-221 {padding-right: 221px;}
.p-r-222 {padding-right: 222px;}
.p-r-223 {padding-right: 223px;}
.p-r-224 {padding-right: 224px;}
.p-r-225 {padding-right: 225px;}
.p-r-226 {padding-right: 226px;}
.p-r-227 {padding-right: 227px;}
.p-r-228 {padding-right: 228px;}
.p-r-229 {padding-right: 229px;}
.p-r-230 {padding-right: 230px;}
.p-r-231 {padding-right: 231px;}
.p-r-232 {padding-right: 232px;}
.p-r-233 {padding-right: 233px;}
.p-r-234 {padding-right: 234px;}
.p-r-235 {padding-right: 235px;}
.p-r-236 {padding-right: 236px;}
.p-r-237 {padding-right: 237px;}
.p-r-238 {padding-right: 238px;}
.p-r-239 {padding-right: 239px;}
.p-r-240 {padding-right: 240px;}
.p-r-241 {padding-right: 241px;}
.p-r-242 {padding-right: 242px;}
.p-r-243 {padding-right: 243px;}
.p-r-244 {padding-right: 244px;}
.p-r-245 {padding-right: 245px;}
.p-r-246 {padding-right: 246px;}
.p-r-247 {padding-right: 247px;}
.p-r-248 {padding-right: 248px;}
.p-r-249 {padding-right: 249px;}
.p-r-250 {padding-right: 250px;}
.p-l-1rem {padding-left: 1rem;}
.p-r-1rem {padding-right: 1rem;}
.p-4-16 {padding: 4px 16px};
.p-2-10 {padding: 2px 10px !important;}
/*
  modified by Masum Raja
  modifield on 18/08/2023
  modification: padding top bottom and left right  1 class added
*/
.p-4-2 {padding: 4px 2px};
.p-6-10 {padding: 6px 10px !important;};

/*[ MARGIN ]
///////////////////////////////////////////////////////////
*/

/*modified by Masum
   modifield on 20/07/2023
   modification: added important in top-25 and 1 top
*/

.m-auto {margin: auto;}
.m-0 {margin: 0px !important;}
.m-1 {margin: 1px !important;}
.m-2 {margin: 2px !important;}
.m-3 {margin: 3px !important;}
.m-4 {margin: 4px !important;}
.m-5 {margin: 5px !important;}
.m-6 {margin: 6px !important;}
.m-7 {margin: 7px !important;}
.m-8 {margin: 8px !important;}
.m-9 {margin: 9px !important;}
.m-10 {margin: 10px !important;}
.m-11 {margin: 11px !important;}
.m-12 {margin: 12px !important;}
.m-13 {margin: 13px !important;}
.m-14 {margin: 14px !important;}
.m-15 {margin: 15px !important;}
.m-16 {margin: 16px !important;}
.m-17 {margin: 17px !important;}
.m-18 {margin: 18px !important;}
.m-19 {margin: 19px !important;}
.m-20 {margin: 20px !important;}
.m-21 {margin: 21px !important;}
.m-22 {margin: 22px !important;}
.m-23 {margin: 23px !important;}
.m-24 {margin: 24px !important;}
.m-25 {margin: 25px !important;}
.m-26 {margin: 26px !important;}
.m-27 {margin: 27px !important;}
.m-28 {margin: 28px !important;}
.m-29 {margin: 29px !important;}
.m-30 {margin: 30px !important;}
.m-31 {margin: 31px !important;}
.m-32 {margin: 32px !important;}
.m-33 {margin: 33px !important;}
.m-34 {margin: 34px !important;}
.m-35 {margin: 35px !important;}
.m-36 {margin: 36px !important;}
.m-37 {margin: 37px !important;}
.m-38 {margin: 38px !important;}
.m-39 {margin: 39px !important;}
.m-40 {margin: 40px !important;}
.m-41 {margin: 41px !important;}
.m-42 {margin: 42px !important;}
.m-43 {margin: 43px !important;}
.m-44 {margin: 44px !important;}
.m-45 {margin: 45px !important;}
.m-46 {margin: 46px !important;}
.m-47 {margin: 47px !important;}
.m-48 {margin: 48px !important;}
.m-49 {margin: 49px !important;}
.m-50 {margin: 50px !important;}

.m-t-neg-8 {margin-top: -8px !important;}
.m-0 {margin: 0 !important;}
.m-t-0 {margin-top: 0px !important;}
.m-t-1 {margin-top: 1px;}
.m-t-2 {margin-top: 2px;}
.m-t-3 {margin-top: 3px;}
.m-t-4 {margin-top: 4px;}
.m-t-4-imp {margin-top: 4px !important;}
.m-t-5 {margin-top: 5px;}
.m-t-6 {margin-top: 6px;}
.m-t-7 {margin-top: 7px !important;}
.m-t-8 {margin-top: 8px !important;}
.m-t-9 {margin-top: 9px;}
.m-t-10 {margin-top: 10px;}
.m-t-11 {margin-top: 11px;}
.m-t-12 {margin-top: 12px;}
.m-t-13 {margin-top: 13px !important}
.m-t-14 {margin-top: 14px;}
.m-t-15 {margin-top: 15px !important;}
.m-t-16 {margin-top: 16px;}
.m-t-17 {margin-top: 17px;}
.m-t-18 {margin-top: 18px;}
.m-t-19 {margin-top: 19px;}
.m-t-20 {margin-top: 20px;}
.m-t-21 {margin-top: 21px;}
.m-t-22 {margin-top: 22px;}
.m-t-23 {margin-top: 23px;}
.m-t-24 {margin-top: 24px;}
.m-t-25 {margin-top: 25px !important;}
.m-t-26 {margin-top: 26px;}
.m-t-27 {margin-top: 27px;}
.m-t-28 {margin-top: 28px;}
.m-t-29 {margin-top: 29px;}
.m-t-30 {margin-top: 30px;}
.m-t-31 {margin-top: 31px;}
.m-t-32 {margin-top: 32px;}
.m-t-33 {margin-top: 33px;}
.m-t-34 {margin-top: 34px;}
.m-t-35 {margin-top: 35px;}
.m-t-36 {margin-top: 36px;}
.m-t-37 {margin-top: 37px;}
.m-t-38 {margin-top: 38px;}
.m-t-39 {margin-top: 39px;}
.m-t-40 {margin-top: 40px !important}
.m-t-41 {margin-top: 41px;}
.m-t-42 {margin-top: 42px;}
.m-t-43 {margin-top: 43px;}
.m-t-44 {margin-top: 44px;}
.m-t-45 {margin-top: 45px;}
.m-t-46 {margin-top: 46px;}
.m-t-47 {margin-top: 47px;}
.m-t-48 {margin-top: 48px;}
.m-t-49 {margin-top: 49px;}
.m-t-50 {margin-top: 50px;}
.m-t-51 {margin-top: 51px;}
.m-t-52 {margin-top: 52px;}
.m-t-53 {margin-top: 53px;}
.m-t-54 {margin-top: 54px;}
.m-t-55 {margin-top: 55px;}
.m-t-56 {margin-top: 56px;}
.m-t-57 {margin-top: 57px;}
.m-t-58 {margin-top: 58px;}
.m-t-59 {margin-top: 59px;}
.m-t-60 {margin-top: 60px;}
.m-t-61 {margin-top: 61px;}
.m-t-62 {margin-top: 62px;}
.m-t-63 {margin-top: 63px;}
.m-t-64 {margin-top: 64px;}
.m-t-65 {margin-top: 65px;}
.m-t-66 {margin-top: 66px;}
.m-t-67 {margin-top: 67px;}
.m-t-68 {margin-top: 68px;}
.m-t-69 {margin-top: 69px;}
.m-t-70 {margin-top: 70px;}
.m-t-71 {margin-top: 71px;}
.m-t-72 {margin-top: 72px;}
.m-t-73 {margin-top: 73px;}
.m-t-74 {margin-top: 74px;}
.m-t-75 {margin-top: 75px;}
.m-t-76 {margin-top: 76px;}
.m-t-77 {margin-top: 77px;}
.m-t-78 {margin-top: 78px;}
.m-t-79 {margin-top: 79px;}
.m-t-80 {margin-top: 80px;}
.m-t-81 {margin-top: 81px;}
.m-t-82 {margin-top: 82px;}
.m-t-83 {margin-top: 83px;}
.m-t-84 {margin-top: 84px;}
.m-t-85 {margin-top: 85px;}
.m-t-86 {margin-top: 86px;}
.m-t-87 {margin-top: 87px;}
.m-t-88 {margin-top: 88px;}
.m-t-89 {margin-top: 89px;}
.m-t-90 {margin-top: 90px;}
.m-t-91 {margin-top: 91px;}
.m-t-92 {margin-top: 92px;}
.m-t-93 {margin-top: 93px;}
.m-t-94 {margin-top: 94px;}
.m-t-95 {margin-top: 95px;}
.m-t-96 {margin-top: 96px;}
.m-t-97 {margin-top: 97px;}
.m-t-98 {margin-top: 98px;}
.m-t-99 {margin-top: 99px;}
.m-t-100 {margin-top: 100px;}
.m-t-101 {margin-top: 101px;}
.m-t-102 {margin-top: 102px;}
.m-t-103 {margin-top: 103px;}
.m-t-104 {margin-top: 104px;}
.m-t-105 {margin-top: 105px;}
.m-t-106 {margin-top: 106px;}
.m-t-107 {margin-top: 107px;}
.m-t-108 {margin-top: 108px;}
.m-t-109 {margin-top: 109px;}
.m-t-110 {margin-top: 110px;}
.m-t-111 {margin-top: 111px;}
.m-t-112 {margin-top: 112px;}
.m-t-113 {margin-top: 113px;}
.m-t-114 {margin-top: 114px;}
.m-t-115 {margin-top: 115px;}
.m-t-116 {margin-top: 116px;}
.m-t-117 {margin-top: 117px;}
.m-t-118 {margin-top: 118px;}
.m-t-119 {margin-top: 119px;}
.m-t-120 {margin-top: 120px;}
.m-t-121 {margin-top: 121px;}
.m-t-122 {margin-top: 122px;}
.m-t-123 {margin-top: 123px;}
.m-t-124 {margin-top: 124px;}
.m-t-125 {margin-top: 125px;}
.m-t-126 {margin-top: 126px;}
.m-t-127 {margin-top: 127px;}
.m-t-128 {margin-top: 128px;}
.m-t-129 {margin-top: 129px;}
.m-t-130 {margin-top: 130px;}
.m-t-131 {margin-top: 131px;}
.m-t-132 {margin-top: 132px;}
.m-t-133 {margin-top: 133px;}
.m-t-134 {margin-top: 134px;}
.m-t-135 {margin-top: 135px;}
.m-t-136 {margin-top: 136px;}
.m-t-137 {margin-top: 137px;}
.m-t-138 {margin-top: 138px;}
.m-t-139 {margin-top: 139px;}
.m-t-140 {margin-top: 140px;}
.m-t-141 {margin-top: 141px;}
.m-t-142 {margin-top: 142px;}
.m-t-143 {margin-top: 143px;}
.m-t-144 {margin-top: 144px;}
.m-t-145 {margin-top: 145px;}
.m-t-146 {margin-top: 146px;}
.m-t-147 {margin-top: 147px;}
.m-t-148 {margin-top: 148px;}
.m-t-149 {margin-top: 149px;}
.m-t-150 {margin-top: 150px;}
.m-t-151 {margin-top: 151px;}
.m-t-152 {margin-top: 152px;}
.m-t-153 {margin-top: 153px;}
.m-t-154 {margin-top: 154px;}
.m-t-155 {margin-top: 155px;}
.m-t-156 {margin-top: 156px;}
.m-t-157 {margin-top: 157px;}
.m-t-158 {margin-top: 158px;}
.m-t-159 {margin-top: 159px;}
.m-t-160 {margin-top: 160px;}
.m-t-161 {margin-top: 161px;}
.m-t-162 {margin-top: 162px;}
.m-t-163 {margin-top: 163px;}
.m-t-164 {margin-top: 164px;}
.m-t-165 {margin-top: 165px;}
.m-t-166 {margin-top: 166px;}
.m-t-167 {margin-top: 167px;}
.m-t-168 {margin-top: 168px;}
.m-t-169 {margin-top: 169px;}
.m-t-170 {margin-top: 170px;}
.m-t-171 {margin-top: 171px;}
.m-t-172 {margin-top: 172px;}
.m-t-173 {margin-top: 173px;}
.m-t-174 {margin-top: 174px;}
.m-t-175 {margin-top: 175px;}
.m-t-176 {margin-top: 176px;}
.m-t-177 {margin-top: 177px;}
.m-t-178 {margin-top: 178px;}
.m-t-179 {margin-top: 179px;}
.m-t-180 {margin-top: 180px;}
.m-t-181 {margin-top: 181px;}
.m-t-182 {margin-top: 182px;}
.m-t-183 {margin-top: 183px;}
.m-t-184 {margin-top: 184px;}
.m-t-185 {margin-top: 185px;}
.m-t-186 {margin-top: 186px;}
.m-t-187 {margin-top: 187px;}
.m-t-188 {margin-top: 188px;}
.m-t-189 {margin-top: 189px;}
.m-t-190 {margin-top: 190px;}
.m-t-191 {margin-top: 191px;}
.m-t-192 {margin-top: 192px;}
.m-t-193 {margin-top: 193px;}
.m-t-194 {margin-top: 194px;}
.m-t-195 {margin-top: 195px;}
.m-t-196 {margin-top: 196px;}
.m-t-197 {margin-top: 197px;}
.m-t-198 {margin-top: 198px;}
.m-t-199 {margin-top: 199px;}
.m-t-200 {margin-top: 200px;}
.m-t-201 {margin-top: 201px;}
.m-t-202 {margin-top: 202px;}
.m-t-203 {margin-top: 203px;}
.m-t-204 {margin-top: 204px;}
.m-t-205 {margin-top: 205px;}
.m-t-206 {margin-top: 206px;}
.m-t-207 {margin-top: 207px;}
.m-t-208 {margin-top: 208px;}
.m-t-209 {margin-top: 209px;}
.m-t-210 {margin-top: 210px;}
.m-t-211 {margin-top: 211px;}
.m-t-212 {margin-top: 212px;}
.m-t-213 {margin-top: 213px;}
.m-t-214 {margin-top: 214px;}
.m-t-215 {margin-top: 215px;}
.m-t-216 {margin-top: 216px;}
.m-t-217 {margin-top: 217px;}
.m-t-218 {margin-top: 218px;}
.m-t-219 {margin-top: 219px;}
.m-t-220 {margin-top: 220px;}
.m-t-221 {margin-top: 221px;}
.m-t-222 {margin-top: 222px;}
.m-t-223 {margin-top: 223px;}
.m-t-224 {margin-top: 224px;}
.m-t-225 {margin-top: 225px !important}
.m-t-226 {margin-top: 226px;}
.m-t-227 {margin-top: 227px;}
.m-t-228 {margin-top: 228px;}
.m-t-229 {margin-top: 229px;}
.m-t-230 {margin-top: 230px;}
.m-t-231 {margin-top: 231px;}
.m-t-232 {margin-top: 232px;}
.m-t-233 {margin-top: 233px;}
.m-t-234 {margin-top: 234px;}
.m-t-235 {margin-top: 235px;}
.m-t-236 {margin-top: 236px;}
.m-t-237 {margin-top: 237px;}
.m-t-238 {margin-top: 238px;}
.m-t-239 {margin-top: 239px;}
.m-t-240 {margin-top: 240px;}
.m-t-241 {margin-top: 241px;}
.m-t-242 {margin-top: 242px;}
.m-t-243 {margin-top: 243px;}
.m-t-244 {margin-top: 244px;}
.m-t-245 {margin-top: 245px;}
.m-t-246 {margin-top: 246px;}
.m-t-247 {margin-top: 247px;}
.m-t-248 {margin-top: 248px;}
.m-t-249 {margin-top: 249px;}
.m-t-250 {margin-top: 250px;}
.m-t-neg-1 {margin-top: -1px;}
.m-t-neg-2 {margin-top: -2px;}
.m-t-neg-4 {margin-top: -4px;}
.m-t-neg-8 {margin-top: -8px;}
.m-t-neg-11 {margin-top: -11px;}
.m-t-neg-12 {margin-top: -12px;}
.m-t-neg-15 {margin-top: -15px !important;}
.m-t-neg-21 {margin-top: -21px !important;}
.m-t-neg-44 {margin-top: -44px;}
.m-t-neg-60 {margin-top: -60px;}
.m-b-neg-1 {margin-bottom: -1px;}
.m-b-neg-2 {margin-bottom: -2px;}
.m-b-neg-3 {margin-bottom: -3px;}
.m-b-neg4 {margin-bottom: -4px;}
.m-b-neg-4 {margin-bottom: 0px;}
.m-b-0 {margin-bottom: 0px !important;}
.m-b-1 {margin-bottom: 1px;}
.m-b-2 {margin-bottom: 2px;}
/*
		modified by Arun Singh
		modified at 02/11/2023
		modification : Add m-b-2-imp !important class
*/
.m-b-2-imp {margin-bottom: 2px !important;}
.m-b-3 {margin-bottom: 3px !important;}
.m-b-4 {margin-bottom: 4px;}
.m-b-5 {margin-bottom: 5px !important;}
.m-b-6 {margin-bottom: 6px;}
.m-b-7 {margin-bottom: 7px;}
.m-b-8 {margin-bottom: 8px !important;}
.m-b-9 {margin-bottom: 9px;}
.m-b-10 {margin-bottom: 10px !important;}
.m-b-11 {margin-bottom: 11px;}
.m-b-12 {margin-bottom: 12px;}
.m-b-13 {margin-bottom: 13px;}
.m-b-14 {margin-bottom: 14px;}
.m-b-15 {margin-bottom: 15px !important;}
.m-b-16 {margin-bottom: 16px !important;}
.m-b-17 {margin-bottom: 17px;}
.m-b-18 {margin-bottom: 18px;}
.m-b-19 {margin-bottom: 19px;}
.m-b-20 {margin-bottom: 20px;}
.m-b-21 {margin-bottom: 21px;}
.m-b-22 {margin-bottom: 22px;}
.m-b-23 {margin-bottom: 23px;}
.m-b-24 {margin-bottom: 24px;}
.m-b-25 {margin-bottom: 25px;}
.m-b-26 {margin-bottom: 26px;}
.m-b-27 {margin-bottom: 27px;}
.m-b-28 {margin-bottom: 28px;}
.m-b-29 {margin-bottom: 29px;}
.m-b-30 {margin-bottom: 30px;}
.m-b-31 {margin-bottom: 31px;}
.m-b-32 {margin-bottom: 32px;}
.m-b-33 {margin-bottom: 33px;}
.m-b-34 {margin-bottom: 34px;}
.m-b-35 {margin-bottom: 35px !important;}
.m-b-36 {margin-bottom: 36px;}
.m-b-37 {margin-bottom: 37px;}
.m-b-38 {margin-bottom: 38px;}
.m-b-39 {margin-bottom: 39px;}
.m-b-40 {margin-bottom: 40px;}
.m-b-41 {margin-bottom: 41px;}
.m-b-42 {margin-bottom: 42px;}
.m-b-43 {margin-bottom: 43px;}
.m-b-44 {margin-bottom: 44px;}
.m-b-45 {margin-bottom: 45px;}
.m-b-46 {margin-bottom: 46px;}
.m-b-47 {margin-bottom: 47px;}
.m-b-48 {margin-bottom: 48px;}
.m-b-49 {margin-bottom: 49px;}
.m-b-50 {margin-bottom: 50px;}
.m-b-51 {margin-bottom: 51px;}
.m-b-52 {margin-bottom: 52px;}
.m-b-53 {margin-bottom: 53px;}
.m-b-54 {margin-bottom: 54px;}
.m-b-55 {margin-bottom: 55px;}
.m-b-56 {margin-bottom: 56px;}
.m-b-57 {margin-bottom: 57px;}
.m-b-58 {margin-bottom: 58px;}
.m-b-59 {margin-bottom: 59px;}
.m-b-60 {margin-bottom: 60px;}
.m-b-61 {margin-bottom: 61px;}
.m-b-62 {margin-bottom: 62px;}
.m-b-63 {margin-bottom: 63px;}
.m-b-64 {margin-bottom: 64px;}
.m-b-65 {margin-bottom: 65px;}
.m-b-66 {margin-bottom: 66px;}
.m-b-67 {margin-bottom: 67px;}
.m-b-68 {margin-bottom: 68px;}
.m-b-69 {margin-bottom: 69px;}
.m-b-70 {margin-bottom: 70px;}
.m-b-71 {margin-bottom: 71px;}
.m-b-72 {margin-bottom: 72px;}
.m-b-73 {margin-bottom: 73px;}
.m-b-74 {margin-bottom: 74px;}
.m-b-75 {margin-bottom: 75px;}
.m-b-76 {margin-bottom: 76px;}
.m-b-77 {margin-bottom: 77px;}
.m-b-78 {margin-bottom: 78px;}
.m-b-79 {margin-bottom: 79px;}
.m-b-80 {margin-bottom: 80px !important}
.m-b-81 {margin-bottom: 81px;}
.m-b-82 {margin-bottom: 82px;}
.m-b-83 {margin-bottom: 83px;}
.m-b-84 {margin-bottom: 84px;}
.m-b-85 {margin-bottom: 85px;}
.m-b-86 {margin-bottom: 86px;}
.m-b-87 {margin-bottom: 87px;}
.m-b-88 {margin-bottom: 88px;}
.m-b-89 {margin-bottom: 89px;}
.m-b-90 {margin-bottom: 90px;}
.m-b-91 {margin-bottom: 91px;}
.m-b-92 {margin-bottom: 92px;}
.m-b-93 {margin-bottom: 93px;}
.m-b-94 {margin-bottom: 94px;}
.m-b-95 {margin-bottom: 95px;}
.m-b-96 {margin-bottom: 96px;}
.m-b-97 {margin-bottom: 97px;}
.m-b-98 {margin-bottom: 98px;}
.m-b-99 {margin-bottom: 99px;}
.m-b-100 {margin-bottom: 100px;}
.m-b-101 {margin-bottom: 101px;}
.m-b-102 {margin-bottom: 102px;}
.m-b-103 {margin-bottom: 103px;}
.m-b-104 {margin-bottom: 104px;}
.m-b-105 {margin-bottom: 105px;}
.m-b-106 {margin-bottom: 106px;}
.m-b-107 {margin-bottom: 107px;}
.m-b-108 {margin-bottom: 108px;}
.m-b-109 {margin-bottom: 109px;}
.m-b-110 {margin-bottom: 110px;}
.m-b-111 {margin-bottom: 111px;}
.m-b-112 {margin-bottom: 112px;}
.m-b-113 {margin-bottom: 113px;}
.m-b-114 {margin-bottom: 114px;}
.m-b-115 {margin-bottom: 115px;}
.m-b-116 {margin-bottom: 116px;}
.m-b-117 {margin-bottom: 117px;}
.m-b-118 {margin-bottom: 118px;}
.m-b-119 {margin-bottom: 119px;}
.m-b-120 {margin-bottom: 120px;}
.m-b-121 {margin-bottom: 121px;}
.m-b-122 {margin-bottom: 122px;}
.m-b-123 {margin-bottom: 123px;}
.m-b-124 {margin-bottom: 124px;}
.m-b-125 {margin-bottom: 125px;}
.m-b-126 {margin-bottom: 126px;}
.m-b-127 {margin-bottom: 127px;}
.m-b-128 {margin-bottom: 128px;}
.m-b-129 {margin-bottom: 129px;}
.m-b-130 {margin-bottom: 130px;}
.m-b-131 {margin-bottom: 131px;}
.m-b-132 {margin-bottom: 132px;}
.m-b-133 {margin-bottom: 133px;}
.m-b-134 {margin-bottom: 134px;}
.m-b-135 {margin-bottom: 135px;}
.m-b-136 {margin-bottom: 136px;}
.m-b-137 {margin-bottom: 137px;}
.m-b-138 {margin-bottom: 138px;}
.m-b-139 {margin-bottom: 139px;}
.m-b-140 {margin-bottom: 140px;}
.m-b-141 {margin-bottom: 141px;}
.m-b-142 {margin-bottom: 142px;}
.m-b-143 {margin-bottom: 143px;}
.m-b-144 {margin-bottom: 144px;}
.m-b-145 {margin-bottom: 145px;}
.m-b-146 {margin-bottom: 146px;}
.m-b-147 {margin-bottom: 147px;}
.m-b-148 {margin-bottom: 148px;}
.m-b-149 {margin-bottom: 149px;}
.m-b-150 {margin-bottom: 150px !important;}
.m-b-151 {margin-bottom: 151px;}
.m-b-152 {margin-bottom: 152px;}
.m-b-153 {margin-bottom: 153px;}
.m-b-154 {margin-bottom: 154px;}
.m-b-155 {margin-bottom: 155px;}
.m-b-156 {margin-bottom: 156px;}
.m-b-157 {margin-bottom: 157px;}
.m-b-158 {margin-bottom: 158px;}
.m-b-159 {margin-bottom: 159px;}
.m-b-160 {margin-bottom: 160px;}
.m-b-161 {margin-bottom: 161px;}
.m-b-162 {margin-bottom: 162px;}
.m-b-163 {margin-bottom: 163px;}
.m-b-164 {margin-bottom: 164px;}
.m-b-165 {margin-bottom: 165px;}
.m-b-166 {margin-bottom: 166px;}
.m-b-167 {margin-bottom: 167px;}
.m-b-168 {margin-bottom: 168px;}
.m-b-169 {margin-bottom: 169px;}
.m-b-170 {margin-bottom: 170px;}
.m-b-171 {margin-bottom: 171px;}
.m-b-172 {margin-bottom: 172px;}
.m-b-173 {margin-bottom: 173px;}
.m-b-174 {margin-bottom: 174px;}
.m-b-175 {margin-bottom: 175px;}
.m-b-176 {margin-bottom: 176px;}
.m-b-177 {margin-bottom: 177px;}
.m-b-178 {margin-bottom: 178px;}
.m-b-179 {margin-bottom: 179px;}
.m-b-180 {margin-bottom: 180px;}
.m-b-181 {margin-bottom: 181px;}
.m-b-182 {margin-bottom: 182px;}
.m-b-183 {margin-bottom: 183px;}
.m-b-184 {margin-bottom: 184px;}
.m-b-185 {margin-bottom: 185px;}
.m-b-186 {margin-bottom: 186px;}
.m-b-187 {margin-bottom: 187px;}
.m-b-188 {margin-bottom: 188px;}
.m-b-189 {margin-bottom: 189px;}
.m-b-190 {margin-bottom: 190px;}
.m-b-191 {margin-bottom: 191px;}
.m-b-192 {margin-bottom: 192px;}
.m-b-193 {margin-bottom: 193px;}
.m-b-194 {margin-bottom: 194px;}
.m-b-195 {margin-bottom: 195px;}
.m-b-196 {margin-bottom: 196px;}
.m-b-197 {margin-bottom: 197px;}
.m-b-198 {margin-bottom: 198px;}
.m-b-199 {margin-bottom: 199px;}
.m-b-200 {margin-bottom: 200px;}
.m-b-201 {margin-bottom: 201px;}
.m-b-202 {margin-bottom: 202px;}
.m-b-203 {margin-bottom: 203px;}
.m-b-204 {margin-bottom: 204px;}
.m-b-205 {margin-bottom: 205px;}
.m-b-206 {margin-bottom: 206px;}
.m-b-207 {margin-bottom: 207px;}
.m-b-208 {margin-bottom: 208px;}
.m-b-209 {margin-bottom: 209px;}
.m-b-210 {margin-bottom: 210px;}
.m-b-211 {margin-bottom: 211px;}
.m-b-212 {margin-bottom: 212px;}
.m-b-213 {margin-bottom: 213px;}
.m-b-214 {margin-bottom: 214px;}
.m-b-215 {margin-bottom: 215px;}
.m-b-216 {margin-bottom: 216px;}
.m-b-217 {margin-bottom: 217px;}
.m-b-218 {margin-bottom: 218px;}
.m-b-219 {margin-bottom: 219px;}
.m-b-220 {margin-bottom: 220px;}
.m-b-221 {margin-bottom: 221px;}
.m-b-222 {margin-bottom: 222px;}
.m-b-223 {margin-bottom: 223px;}
.m-b-224 {margin-bottom: 224px;}
.m-b-225 {margin-bottom: 225px;}
.m-b-226 {margin-bottom: 226px;}
.m-b-227 {margin-bottom: 227px;}
.m-b-228 {margin-bottom: 228px;}
.m-b-229 {margin-bottom: 229px;}
.m-b-230 {margin-bottom: 230px;}
.m-b-231 {margin-bottom: 231px;}
.m-b-232 {margin-bottom: 232px;}
.m-b-233 {margin-bottom: 233px;}
.m-b-234 {margin-bottom: 234px;}
.m-b-235 {margin-bottom: 235px;}
.m-b-236 {margin-bottom: 236px;}
.m-b-237 {margin-bottom: 237px;}
.m-b-238 {margin-bottom: 238px;}
.m-b-239 {margin-bottom: 239px;}
.m-b-240 {margin-bottom: 240px;}
.m-b-241 {margin-bottom: 241px;}
.m-b-242 {margin-bottom: 242px;}
.m-b-243 {margin-bottom: 243px;}
.m-b-244 {margin-bottom: 244px;}
.m-b-245 {margin-bottom: 245px;}
.m-b-246 {margin-bottom: 246px;}
.m-b-247 {margin-bottom: 247px;}
.m-b-248 {margin-bottom: 248px;}
.m-b-249 {margin-bottom: 249px;}
.m-b-250 {margin-bottom: 250px;}
.m-l-neg-4 {margin-left: -4px;}
.m-l-neg-2 {margin-left: -2px;}
.m-l-0 {margin-left: 0px;}
.m-l-1 {margin-left: 1px;}
.m-l-2 {margin-left: 2px;}
.m-l-3 {margin-left: 3px;}
.m-l-4 {margin-left: 4px;}
.m-l-5 {margin-left: 5px;}
.m-l-6 {margin-left: 6px;}
.m-l-7 {margin-left: 7px;}
.m-l-8 {margin-left: 8px;}
.m-l-9 {margin-left: 9px;}
.m-l-10 {margin-left: 10px !important;}
.m-l-11 {margin-left: 11px;}
.m-l-12 {margin-left: 12px;}
.m-l-13 {margin-left: 13px;}
.m-l-14 {margin-left: 14px;}
.m-l-15 {margin-left: 15px;}
.m-l-16 {margin-left: 16px !important;}
.m-l-17 {margin-left: 17px;}
.m-l-18 {margin-left: 18px;}
.m-l-19 {margin-left: 19px;}
.m-l-20 {margin-left: 20px;}
.m-l--20 {margin-left: -20px;}
.m-l-21 {margin-left: 21px;}
.m-l-22 {margin-left: 22px;}
.m-l-23 {margin-left: 23px;}
.m-l-24 {margin-left: 24px;}
.m-l-25 {margin-left: 25px;}
.m-l-26 {margin-left: 26px;}
.m-l-27 {margin-left: 27px;}
.m-l-28 {margin-left: 28px;}
.m-l-29 {margin-left: 29px;}
.m-l-30 {margin-left: 30px;}
.m-l-31 {margin-left: 31px;}
.m-l-32 {margin-left: 32px;}
.m-l-33 {margin-left: 33px;}
.m-l-34 {margin-left: 34px;}
.m-l-35 {margin-left: 35px;}
.m-l-36 {margin-left: 36px;}
.m-l-37 {margin-left: 37px;}
.m-l-38 {margin-left: 38px;}
.m-l-39 {margin-left: 39px;}
.m-l-40 {margin-left: 40px;}
.m-l-41 {margin-left: 41px;}
.m-l-42 {margin-left: 42px;}
.m-l-43 {margin-left: 43px;}
.m-l-44 {margin-left: 44px;}
.m-l-45 {margin-left: 45px;}
.m-l-46 {margin-left: 46px;}
.m-l-47 {margin-left: 47px;}
.m-l-48 {margin-left: 48px;}
.m-l-49 {margin-left: 49px;}
.m-l-50 {margin-left: 50px;}
.m-l-51 {margin-left: 51px;}
.m-l-52 {margin-left: 52px;}
.m-l-53 {margin-left: 53px;}
.m-l-54 {margin-left: 54px;}
.m-l-55 {margin-left: 55px;}
.m-l-56 {margin-left: 56px;}
.m-l-57 {margin-left: 57px;}
.m-l-58 {margin-left: 58px;}
.m-l-59 {margin-left: 59px;}
.m-l-60 {margin-left: 60px;}
.m-l-61 {margin-left: 61px;}
.m-l-62 {margin-left: 62px;}
.m-l-63 {margin-left: 63px;}
.m-l-64 {margin-left: 64px;}
.m-l-65 {margin-left: 65px;}
.m-l-66 {margin-left: 66px;}
.m-l-67 {margin-left: 67px;}
.m-l-68 {margin-left: 68px;}
.m-l-69 {margin-left: 69px;}
.m-l-70 {margin-left: 70px;}
.m-l-71 {margin-left: 71px;}
.m-l-72 {margin-left: 72px;}
.m-l-73 {margin-left: 73px;}
.m-l-74 {margin-left: 74px;}
.m-l-75 {margin-left: 75px;}
.m-l-76 {margin-left: 76px;}
.m-l-77 {margin-left: 77px;}
.m-l-78 {margin-left: 78px;}
.m-l-79 {margin-left: 79px;}
.m-l-80 {margin-left: 80px;}
.m-l-81 {margin-left: 81px;}
.m-l-82 {margin-left: 82px;}
.m-l-83 {margin-left: 83px;}
.m-l-84 {margin-left: 84px;}
.m-l-85 {margin-left: 85px;}
.m-l-86 {margin-left: 86px;}
.m-l-87 {margin-left: 87px;}
.m-l-88 {margin-left: 88px;}
.m-l-89 {margin-left: 89px;}
.m-l-90 {margin-left: 90px;}
.m-l-91 {margin-left: 91px;}
.m-l-92 {margin-left: 92px;}
.m-l-93 {margin-left: 93px;}
.m-l-94 {margin-left: 94px;}
.m-l-95 {margin-left: 95px !important}
.m-l-96 {margin-left: 96px;}
.m-l-97 {margin-left: 97px;}
.m-l-98 {margin-left: 98px;}
.m-l-99 {margin-left: 99px;}
.m-l-100 {margin-left: 100px;}
.m-l-101 {margin-left: 101px;}
.m-l-102 {margin-left: 102px;}
.m-l-103 {margin-left: 103px;}
.m-l-104 {margin-left: 104px;}
.m-l-105 {margin-left: 105px;}
.m-l-106 {margin-left: 106px;}
.m-l-107 {margin-left: 107px;}
.m-l-108 {margin-left: 108px;}
.m-l-109 {margin-left: 109px;}
.m-l-110 {margin-left: 110px;}
.m-l-111 {margin-left: 111px;}
.m-l-112 {margin-left: 112px;}
.m-l-113 {margin-left: 113px;}
.m-l-114 {margin-left: 114px;}
.m-l-115 {margin-left: 115px;}
.m-l-116 {margin-left: 116px;}
.m-l-117 {margin-left: 117px;}
.m-l-118 {margin-left: 118px;}
.m-l-119 {margin-left: 119px;}
.m-l-120 {margin-left: 120px;}
.m-l-121 {margin-left: 121px;}
.m-l-122 {margin-left: 122px;}
.m-l-123 {margin-left: 123px;}
.m-l-124 {margin-left: 124px;}
.m-l-125 {margin-left: 125px;}
.m-l-126 {margin-left: 126px;}
.m-l-127 {margin-left: 127px;}
.m-l-128 {margin-left: 128px;}
.m-l-129 {margin-left: 129px;}
.m-l-130 {margin-left: 130px;}
.m-l-131 {margin-left: 131px;}
.m-l-132 {margin-left: 132px;}
.m-l-133 {margin-left: 133px;}
.m-l-134 {margin-left: 134px;}
.m-l-135 {margin-left: 135px;}
.m-l-136 {margin-left: 136px !important;}
.m-l-137 {margin-left: 137px;}
.m-l-138 {margin-left: 138px;}
.m-l-139 {margin-left: 139px;}
.m-l-140 {margin-left: 140px;}
.m-l-141 {margin-left: 141px;}
.m-l-142 {margin-left: 142px;}
.m-l-143 {margin-left: 143px;}
.m-l-144 {margin-left: 144px;}
.m-l-145 {margin-left: 145px;}
.m-l-146 {margin-left: 146px;}
.m-l-147 {margin-left: 147px;}
.m-l-148 {margin-left: 148px;}
.m-l-149 {margin-left: 149px;}
.m-l-150 {margin-left: 150px;}
.m-l-151 {margin-left: 151px;}
.m-l-152 {margin-left: 152px;}
.m-l-153 {margin-left: 153px;}
.m-l-154 {margin-left: 154px;}
.m-l-155 {margin-left: 155px;}
.m-l-156 {margin-left: 156px;}
.m-l-157 {margin-left: 157px;}
.m-l-158 {margin-left: 158px;}
.m-l-159 {margin-left: 159px;}
.m-l-160 {margin-left: 160px;}
.m-l-161 {margin-left: 161px;}
.m-l-162 {margin-left: 162px;}
.m-l-163 {margin-left: 163px;}
.m-l-164 {margin-left: 164px;}
.m-l-165 {margin-left: 165px;}
.m-l-166 {margin-left: 166px;}
.m-l-167 {margin-left: 167px;}
.m-l-168 {margin-left: 168px;}
.m-l-169 {margin-left: 169px;}
.m-l-170 {margin-left: 170px;}
.m-l-171 {margin-left: 171px;}
.m-l-172 {margin-left: 172px;}
.m-l-173 {margin-left: 173px;}
.m-l-174 {margin-left: 174px;}
.m-l-175 {margin-left: 175px;}
.m-l-176 {margin-left: 176px;}
.m-l-177 {margin-left: 177px;}
.m-l-178 {margin-left: 178px;}
.m-l-179 {margin-left: 179px;}
.m-l-180 {margin-left: 180px;}
.m-l-181 {margin-left: 181px;}
.m-l-182 {margin-left: 182px;}
.m-l-183 {margin-left: 183px;}
.m-l-184 {margin-left: 184px;}
.m-l-185 {margin-left: 185px;}
.m-l-186 {margin-left: 186px;}
.m-l-187 {margin-left: 187px;}
.m-l-188 {margin-left: 188px;}
.m-l-189 {margin-left: 189px;}
.m-l-190 {margin-left: 190px;}
.m-l-191 {margin-left: 191px;}
.m-l-192 {margin-left: 192px;}
.m-l-193 {margin-left: 193px;}
.m-l-194 {margin-left: 194px;}
.m-l-195 {margin-left: 195px;}
.m-l-196 {margin-left: 196px;}
.m-l-197 {margin-left: 197px;}
.m-l-198 {margin-left: 198px;}
.m-l-199 {margin-left: 199px;}
.m-l-200 {margin-left: 200px;}
.m-l-201 {margin-left: 201px;}
.m-l-202 {margin-left: 202px;}
.m-l-203 {margin-left: 203px;}
.m-l-204 {margin-left: 204px;}
.m-l-205 {margin-left: 205px;}
.m-l-206 {margin-left: 206px;}
.m-l-207 {margin-left: 207px;}
.m-l-208 {margin-left: 208px;}
.m-l-209 {margin-left: 209px;}
.m-l-210 {margin-left: 210px;}
.m-l-211 {margin-left: 211px;}
.m-l-212 {margin-left: 212px;}
.m-l-213 {margin-left: 213px;}
.m-l-214 {margin-left: 214px;}
.m-l-215 {margin-left: 215px;}
.m-l-216 {margin-left: 216px;}
.m-l-217 {margin-left: 217px;}
.m-l-218 {margin-left: 218px;}
.m-l-219 {margin-left: 219px;}
.m-l-220 {margin-left: 220px;}
.m-l-221 {margin-left: 221px;}
.m-l-222 {margin-left: 222px;}
.m-l-223 {margin-left: 223px;}
.m-l-224 {margin-left: 224px;}
.m-l-225 {margin-left: 225px;}
.m-l-226 {margin-left: 226px;}
.m-l-227 {margin-left: 227px;}
.m-l-228 {margin-left: 228px;}
.m-l-229 {margin-left: 229px;}
.m-l-230 {margin-left: 230px;}
.m-l-231 {margin-left: 231px;}
.m-l-232 {margin-left: 232px;}
.m-l-233 {margin-left: 233px;}
.m-l-234 {margin-left: 234px;}
.m-l-235 {margin-left: 235px;}
.m-l-236 {margin-left: 236px;}
.m-l-237 {margin-left: 237px;}
.m-l-238 {margin-left: 238px;}
.m-l-239 {margin-left: 239px;}
.m-l-240 {margin-left: 240px;}
.m-l-241 {margin-left: 241px;}
.m-l-242 {margin-left: 242px;}
.m-l-243 {margin-left: 243px;}
.m-l-244 {margin-left: 244px;}
.m-l-245 {margin-left: 245px;}
.m-l-246 {margin-left: 246px;}
.m-l-247 {margin-left: 247px;}
.m-l-248 {margin-left: 248px;}
.m-l-249 {margin-left: 249px;}
.m-l-250 {margin-left: 250px;}
.m-l-neg-1 {margin-left: -1px;}
.m-l-neg-16 {margin-left: -16px;}
.m-r-0 {margin-right: 0px !important;}
.m-r-1 {margin-right: 1px;}
.m-r-2 {margin-right: 2px;}
.m-r-3 {margin-right: 3px;}
.m-r-4 {margin-right: 4px;}
.m-r-5 {margin-right: 5px;}
.m-r-6 {margin-right: 6px;}
.m-r-7 {margin-right: 7px;}
.m-r-8 {margin-right: 8px;}
.m-r-9 {margin-right: 9px !important;}
.m-r-10 {margin-right: 10px; }
.m-r-11 {margin-right: 11px;}
.m-r-12 {margin-right: 12px;}
.m-r-13 {margin-right: 13px;}
.m-r-14 {margin-right: 14px;}
.m-r-15 {margin-right: 15px;}
.m-r-16 {margin-right: 16px !important;}
.m-r-17 {margin-right: 17px;}
.m-r-18 {margin-right: 18px;}
.m-r-19 {margin-right: 19px;}
.m-r-20 {margin-right: 20px;}
.m-r-21 {margin-right: 21px;}
.m-r-22 {margin-right: 22px;}
.m-r-23 {margin-right: 23px;}
.m-r-24 {margin-right: 24px;}
.m-r-25 {margin-right: 25px;}
.m-r-26 {margin-right: 26px;}
.m-r-27 {margin-right: 27px;}
.m-r-28 {margin-right: 28px;}
.m-r-29 {margin-right: 29px;}
.m-r-30 {margin-right: 30px !important;}
.m-r-31 {margin-right: 31px;}
.m-r-32 {margin-right: 32px;}
.m-r-33 {margin-right: 33px;}
.m-r-34 {margin-right: 34px;}
.m-r-35 {margin-right: 35px;}
.m-r-36 {margin-right: 36px;}
.m-r-37 {margin-right: 37px;}
.m-r-38 {margin-right: 38px;}
.m-r-39 {margin-right: 39px;}
.m-r-40 {margin-right: 40px !important;;}
.m-r-41 {margin-right: 41px;}
.m-r-42 {margin-right: 42px;}
.m-r-43 {margin-right: 43px;}
.m-r-44 {margin-right: 44px;}
.m-r-45 {margin-right: 45px;}
.m-r-46 {margin-right: 46px;}
.m-r-47 {margin-right: 47px;}
.m-r-48 {margin-right: 48px;}
.m-r-49 {margin-right: 49px;}
.m-r-50 {margin-right: 50px !important;}
.m-r-51 {margin-right: 51px;}
.m-r-52 {margin-right: 52px;}
.m-r-53 {margin-right: 53px;}
.m-r-54 {margin-right: 54px;}
.m-r-55 {margin-right: 55px;}
.m-r-56 {margin-right: 56px;}
.m-r-57 {margin-right: 57px;}
.m-r-58 {margin-right: 58px;}
.m-r-59 {margin-right: 59px;}
.m-r-60 {margin-right: 60px;}
.m-r-61 {margin-right: 61px;}
.m-r-62 {margin-right: 62px;}
.m-r-63 {margin-right: 63px;}
.m-r-64 {margin-right: 64px;}
.m-r-65 {margin-right: 65px;}
.m-r-66 {margin-right: 66px;}
.m-r-67 {margin-right: 67px;}
.m-r-68 {margin-right: 68px;}
.m-r-69 {margin-right: 69px;}
.m-r-70 {margin-right: 70px;}
.m-r-71 {margin-right: 71px;}
.m-r-72 {margin-right: 72px;}
.m-r-73 {margin-right: 73px;}
.m-r-74 {margin-right: 74px;}
.m-r-75 {margin-right: 75px;}
.m-r-76 {margin-right: 76px;}
.m-r-77 {margin-right: 77px;}
.m-r-78 {margin-right: 78px;}
.m-r-79 {margin-right: 79px;}
.m-r-80 {margin-right: 80px;}
.m-r-81 {margin-right: 81px;}
.m-r-82 {margin-right: 82px;}
.m-r-83 {margin-right: 83px;}
.m-r-84 {margin-right: 84px;}
.m-r-85 {margin-right: 85px;}
.m-r-86 {margin-right: 86px;}
.m-r-87 {margin-right: 87px;}
.m-r-88 {margin-right: 88px;}
.m-r-89 {margin-right: 89px;}
.m-r-90 {margin-right: 90px;}
.m-r-91 {margin-right: 91px;}
.m-r-92 {margin-right: 92px;}
.m-r-93 {margin-right: 93px;}
.m-r-94 {margin-right: 94px;}
.m-r-95 {margin-right: 95px;}
.m-r-96 {margin-right: 96px;}
.m-r-97 {margin-right: 97px;}
.m-r-98 {margin-right: 98px;}
.m-r-99 {margin-right: 99px;}
.m-r-100 {margin-right: 100px;}
.m-r-101 {margin-right: 101px;}
.m-r-102 {margin-right: 102px;}
.m-r-103 {margin-right: 103px;}
.m-r-104 {margin-right: 104px;}
.m-r-105 {margin-right: 105px;}
.m-r-106 {margin-right: 106px;}
.m-r-107 {margin-right: 107px;}
.m-r-108 {margin-right: 108px;}
.m-r-109 {margin-right: 109px;}
.m-r-110 {margin-right: 110px;}
.m-r-111 {margin-right: 111px;}
.m-r-112 {margin-right: 112px;}
.m-r-113 {margin-right: 113px;}
.m-r-114 {margin-right: 114px;}
.m-r-115 {margin-right: 115px;}
.m-r-116 {margin-right: 116px;}
.m-r-117 {margin-right: 117px;}
.m-r-118 {margin-right: 118px;}
.m-r-119 {margin-right: 119px;}
.m-r-120 {margin-right: 120px;}
.m-r-121 {margin-right: 121px;}
.m-r-122 {margin-right: 122px;}
.m-r-123 {margin-right: 123px;}
.m-r-124 {margin-right: 124px;}
.m-r-125 {margin-right: 125px;}
.m-r-126 {margin-right: 126px;}
.m-r-127 {margin-right: 127px;}
.m-r-128 {margin-right: 128px;}
.m-r-129 {margin-right: 129px;}
.m-r-130 {margin-right: 130px;}
.m-r-131 {margin-right: 131px;}
.m-r-132 {margin-right: 132px;}
.m-r-133 {margin-right: 133px;}
.m-r-134 {margin-right: 134px;}
.m-r-135 {margin-right: 135px;}
.m-r-136 {margin-right: 136px;}
.m-r-137 {margin-right: 137px;}
.m-r-138 {margin-right: 138px;}
.m-r-139 {margin-right: 139px;}
.m-r-140 {margin-right: 140px;}
.m-r-141 {margin-right: 141px;}
.m-r-142 {margin-right: 142px;}
.m-r-143 {margin-right: 143px;}
.m-r-144 {margin-right: 144px;}
.m-r-145 {margin-right: 145px;}
.m-r-146 {margin-right: 146px;}
.m-r-147 {margin-right: 147px;}
.m-r-148 {margin-right: 148px;}
.m-r-149 {margin-right: 149px;}
.m-r-150 {margin-right: 150px;}
.m-r-151 {margin-right: 151px;}
.m-r-152 {margin-right: 152px;}
.m-r-153 {margin-right: 153px;}
.m-r-154 {margin-right: 154px;}
.m-r-155 {margin-right: 155px;}
.m-r-156 {margin-right: 156px;}
.m-r-157 {margin-right: 157px;}
.m-r-158 {margin-right: 158px;}
.m-r-159 {margin-right: 159px;}
.m-r-160 {margin-right: 160px;}
.m-r-161 {margin-right: 161px;}
.m-r-162 {margin-right: 162px;}
.m-r-163 {margin-right: 163px;}
.m-r-164 {margin-right: 164px;}
.m-r-165 {margin-right: 165px;}
.m-r-166 {margin-right: 166px;}
.m-r-167 {margin-right: 167px;}
.m-r-168 {margin-right: 168px;}
.m-r-169 {margin-right: 169px;}
.m-r-170 {margin-right: 170px;}
.m-r-171 {margin-right: 171px;}
.m-r-172 {margin-right: 172px;}
.m-r-173 {margin-right: 173px;}
.m-r-174 {margin-right: 174px;}
.m-r-175 {margin-right: 175px;}
.m-r-176 {margin-right: 176px;}
.m-r-177 {margin-right: 177px;}
.m-r-178 {margin-right: 178px;}
.m-r-179 {margin-right: 179px;}
.m-r-180 {margin-right: 180px;}
.m-r-181 {margin-right: 181px;}
.m-r-182 {margin-right: 182px;}
.m-r-183 {margin-right: 183px;}
.m-r-184 {margin-right: 184px;}
.m-r-185 {margin-right: 185px;}
.m-r-186 {margin-right: 186px;}
.m-r-187 {margin-right: 187px;}
.m-r-188 {margin-right: 188px;}
.m-r-189 {margin-right: 189px;}
.m-r-190 {margin-right: 190px;}
.m-r-191 {margin-right: 191px;}
.m-r-192 {margin-right: 192px;}
.m-r-193 {margin-right: 193px;}
.m-r-194 {margin-right: 194px;}
.m-r-195 {margin-right: 195px;}
.m-r-196 {margin-right: 196px;}
.m-r-197 {margin-right: 197px;}
.m-r-198 {margin-right: 198px;}
.m-r-199 {margin-right: 199px;}
.m-r-200 {margin-right: 200px;}
.m-r-201 {margin-right: 201px;}
.m-r-202 {margin-right: 202px;}
.m-r-203 {margin-right: 203px;}
.m-r-204 {margin-right: 204px;}
.m-r-205 {margin-right: 205px;}
.m-r-206 {margin-right: 206px;}
.m-r-207 {margin-right: 207px;}
.m-r-208 {margin-right: 208px;}
.m-r-209 {margin-right: 209px;}
.m-r-210 {margin-right: 210px;}
.m-r-211 {margin-right: 211px;}
.m-r-212 {margin-right: 212px;}
.m-r-213 {margin-right: 213px;}
.m-r-214 {margin-right: 214px;}
.m-r-215 {margin-right: 215px;}
.m-r-216 {margin-right: 216px;}
.m-r-217 {margin-right: 217px;}
.m-r-218 {margin-right: 218px;}
.m-r-219 {margin-right: 219px;}
.m-r-220 {margin-right: 220px;}
.m-r-221 {margin-right: 221px;}
.m-r-222 {margin-right: 222px;}
.m-r-223 {margin-right: 223px;}
.m-r-224 {margin-right: 224px;}
.m-r-225 {margin-right: 225px;}
.m-r-226 {margin-right: 226px;}
.m-r-227 {margin-right: 227px;}
.m-r-228 {margin-right: 228px;}
.m-r-229 {margin-right: 229px;}
.m-r-230 {margin-right: 230px;}
.m-r-231 {margin-right: 231px;}
.m-r-232 {margin-right: 232px;}
.m-r-233 {margin-right: 233px;}
.m-r-234 {margin-right: 234px;}
.m-r-235 {margin-right: 235px;}
.m-r-236 {margin-right: 236px;}
.m-r-237 {margin-right: 237px;}
.m-r-238 {margin-right: 238px;}
.m-r-239 {margin-right: 239px;}
.m-r-240 {margin-right: 240px;}
.m-r-241 {margin-right: 241px;}
.m-r-242 {margin-right: 242px;}
.m-r-243 {margin-right: 243px;}
.m-r-244 {margin-right: 244px;}
.m-r-245 {margin-right: 245px;}
.m-r-246 {margin-right: 246px;}
.m-r-247 {margin-right: 247px;}
.m-r-248 {margin-right: 248px;}
.m-r-249 {margin-right: 249px;}
.m-r-250 {margin-right: 250px;}
.m-l-r-auto {margin-left: auto;	margin-right: auto;}
.m-l-auto {margin-left: auto;}
.m-r-auto {margin-right: auto;}
.m-r-1rem {margin-right:1rem;}
.m-t--20 {margin-top: -20px;}

/*[ TEXT ]
///////////////////////////////////////////////////////////
*/
/* ------------------------------------ */
.text-primary-tenant {color: var(--tenant-color);}
.text-white {color: white !important;}
.text-success {color: #5d9e58 !important;}
.text-error {color: #eb353c !important;}
.text-black {color: black !important;}
.text-black-25 {color: rgba(0, 0, 0, 0.75);}
.text-orange {color: #FF7A00 !important;}
.text-lightOrange {color:#F0C24B !important;}
.text-adb3bd {color: #adb3bd;}
.text-gray {color: gray !important;}
.text-002C5F {color: #002C5F !important}
.text-gray2 {color:#545454 !important;}
.text-primary {color: var(--text-primary);}
.text-secondary {color: var(--text-secondary);}
.text-slategrey {color: var(--page-sub-header-text-color);}
.text-page-header {color: var(--page-header-text-color);}
.text-color-444 {color:#444 !important;}
.text-color-818CF8 {color:#818CF8 !important;}
.text-color-red {color:red !important;}
.text-color-d2dcd3 {color: #d2dcd3 !important;}
.text-color-91bfbc {color: #91bfbc !important;}
.text-color-0D0D0D {color: #0D0D0D !important;}
/*
		modified by Arun Singh
		modified at 09/10/2023
		modification : Add test color theme !important class
*/

/*
  Modification: added new classes for color, border, top , bottom and left
  By: Masum Raja
  Date: 30/01/2024
*/
.text-color-002c5f-imp {color: #002c5f !important;}
.text-color-0a0a0a {color: #0a0a0a !important;}
.text-color-green {color:green !important;}
.text-color-00a884 {color:#00a884 !important;}
.text-hov-white:hover {color: white;}
.text-hov-white-imp:hover {color: white !important;}
.text-408b8b {color:#408b8b !important;}
.text-4b4d51 {color:#4b4d51 !important;}
.text-b9b9b9 {color:#b9b9b9 !important;}
.text-ed553b8f {color:#ed553b8f !important;}
.text-6393a1 {color:#6393a1 !important;}
.text-d0e7f7 {color:#d0e7f7 !important;}
.text-theme-color {color:#002C5F !important;}
.text-002C5F {color:#002C5F !important;}
.text-red {color:#FF0000 !important;}
.text-red-2 {color:#F35252 !important;}
.text-green {color:#37D200 !important;}
.text-green-2 {color:#9EDE59 !important;}
.text-green-3 {color:#05E700 !important;}
.text-green-4 {color:#02CB77 !important;}
.text-47AF2C {color:#47AF2C !important;}
.text-lightBlue-2 {color:#6297F8 !important;}
.text-lightBlue {color:#00A3FF !important;}
.text-lightPurple {color:#E64C7D !important;}
.text-lightPink {color:#EB7097 !important;}
.text-yellow {color:#FFEE59 !important;}
.text-pink {color:#F87A53 !important;}
.text-6F727C {color:#6F727C !important;}
.text-404040 {color:#404040 !important;}
.text-3C3C3C {color:#3C3C3C !important;}
.text-428AB4 {color:#428AB4 !important;}
.text-EF7F68 {color:#EF7F68 !important;}
.text-F84D44 {color:#F84D44 !important;}
.text-DE5C6E {color:#DE5C6E !important;}
.text-002c5f {color:#002c5f !important;}
.text-0076ff {color:#0076ff !important;}
.text-009de2 {color:#009de2 !important;}
.text-212529 {color:#212529 !important;}
.text-e3736d {color:#e3736d !important;}
.text-2E8994 {color: var(--tenant-color);}

/*
  Modification: Added 3 colors for processMapping code
  By: Devang
  Date: 04/08/2022
*/
.text-b44b99 {color: #b44b99 !important}
.text-da2566 {color: #da2566 !important}
.text-3b6a4a {color: #3b6a4a !important}
.text-479393 { color:#479393 !important;}
.text-header-blue {
	color: #408b8b;
}
.text-sub-header-grey {
	color: #6c6d8acc;
}
.text-darkgrey {
	color: #444 !important;
}
.text-theme-blue {
	color: #003468;
}
.text-theme {
	color: rgba(108, 109, 138, 0.8) !important;
	&::placeholder {
		color: rgba(108, 109, 138, 0.8) !important;
	}
}
.text-2067b0 {
  color: #2067b0;
}

.text-F8A67B {
  color:#F8A67B  !important;
}
.text-skyblue {
  color:#98C1D9  !important;
}
/*
  BY: Prasannadatta Kawadkar
  ON: 12 Mar 2024
  modification: added new text color classes for sales home v2
*/
.text-212A3E {
  color: #212A3E !important;
}
.text-2f4c62 {
  color: #2f4c62 !important;
}
.text-364152 {
  color: #364152 !important;
}
.text-393E46 {
  color: #393E46 !important;
}
.text-364152 {
  color: #364152 !important;
}
/* ------------------------------------ */
.text-up {text-transform: uppercase;}

/* Modified By: Vihang
Modified On: 24 May 2022
Modification: text align end class added
*/

/* ------------------------------------ */
.text-center {text-align: center;}
.text-align-content {align-content: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-end {text-align: end;}
.text-middle {vertical-align: middle;}
.text-top {vertical-align: top;}
.text-444 {color:#444 !important;}

/* ------------------------------------ */
.lh-initial {line-height: initial;}
.lh-0.85 {line-height: 0.85 !important;}
.lh-1-0 {line-height: 1.0;}
.lh-1-1 {line-height: 1.1;}
.lh-1-2 {line-height: 1.2;}
.lh-1-3 {line-height: 1.3;}
.lh-1-4 {line-height: 1.4;}
.lh-1-5 {line-height: 1.5;}
.lh-1-6 {line-height: 1.6;}
.lh-1-7 {line-height: 1.7;}
.lh-1-8 {line-height: 1.8;}
.lh-1-9 {line-height: 1.9;}
.lh-2-0 {line-height: 2.0;}
.lh-2-1 {line-height: 2.1;}
.lh-2-2 {line-height: 2.2;}
.lh-2-3 {line-height: 2.3;}
.lh-2-4 {line-height: 2.4;}
.lh-2-5 {line-height: 2.5;}
.lh-2-6 {line-height: 2.6;}
.lh-2-7 {line-height: 2.7;}
.lh-2-8 {line-height: 2.8;}
.lh-2-9 {line-height: 2.9;}
.lh-9-px {line-height: 9px;}
.lh-20-px {line-height: 20px;}
.lh-18-px {line-height: 18px;}
.lh-16-px {line-height: 16px;}
.lh-14-px {line-height: 14px;}
.lh-12-px {line-height: 12px;}
.lh-15-px {line-height: 15px;}
.lh-18-px {line-height: 18px;}
.lh-35-px {line-height: 35px !important;}
.lh-38-px {line-height: 38px !important;}
.lh-50-px {line-height: 50px !important;}
/* ------------------------------------ */
.ls-0 {letter-spacing: 0px !important;}
.ls-01 {letter-spacing: 0.1px;}
.ls-02 {letter-spacing: 0.2px;}
.ls-03 {letter-spacing: 0.3px;}
.ls-04 {letter-spacing: 0.4px;}
.ls-05 {letter-spacing: 0.5px;}
.ls-06 {letter-spacing: 0.6px;}
.ls-07 {letter-spacing: 0.7px;}
.ls-08 {letter-spacing: 0.8px;}
.ls-09 {letter-spacing: 0.9px;}
.ls-1 {letter-spacing: 1px;}
.ls-1-5 {letter-spacing:1.5px;}
.ls-2 {letter-spacing: 2px;}
.ls-3 {letter-spacing: 3px;}
.ls-4 {letter-spacing: 4px;}
.ls-5 {letter-spacing: 5px;}
.ls-6 {letter-spacing: 6px;}
.ls-7 {letter-spacing: 7px;}
.ls-8 {letter-spacing: 8px;}
.ls-9 {letter-spacing: 9px;}

/*[ Display ]
-----------------------------------------------------------
*/
.dis-none {display: none;}
.dis-none-imp {display: none !important;}
.dis-block {display: block;}
.dis-inline {display: inline;}
.dis-inline-block {display: inline-block;}
.dis-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

/*[ Position ]
-----------------------------------------------------------
*/
.pos-relative {position: relative !important;}
.pos-absolute {position: absolute !important;}
.pos-fixed {position: fixed;}
.pos-sticky {position:sticky;}
.pos-static {position: static !important;}
.pos-inherit {position: inherit !important;}

/*modified by Vihang
   modifield on 16/05/2022
   modification: position sticky added
*/
/*[ float ]
-----------------------------------------------------------
*/
.float-l {float: left;}
.float-r {float: right;}


/*[ Width & Height ]
-----------------------------------------------------------
*/
.sizefull {
	width: 100%;
	height: 100%;
}
.w-full {width: 100% !important;}
.w-90-p {width: 90% !important;}
.w-95-p {width: 95% !important;}
.w-40-p {width: 40% !important;}
.w-80-p {width: 80% !important;}
.h-full {height: 100% !important;}
.h-94-p {height: 94% !important;}
.h-96-p {height: 96% !important;}
.w-fit-content {width: fit-content !important;}
.h-fit-content {height: fit-content !important;}
.w-full-vh {width: 100vh !important;}
.h-full-vh {height: 100vh !important;}
.w-half {width: 50% !important;}
.h-half {height: 50% !important;}
.h-0 {height: 0 !important;}
.h-40-per {height: 40% !important;}
.h-180px {height: 180px !important;}
.h-auto {height: auto !important;}
/*
  By: Prasannadatta Kawadkar
  On: 16 Jan 2024
  Modification: added new Classes
*/
.w-176 {width: 176px !important;}
.h-54 {height: 54px !important;}
.h-369 {height: 369px;}
.h-400 {height: 400px;}
.h-300px {height: 300px !important;}
.max-w-none {max-width: none;}
.max-w-full {max-width: 100%;}
.max-w-31 {max-width: 31%;}
.max-w-69 {max-width: 69%;}
.max-width-70 {max-width: 70px;}
.max-w-100 {max-width: 100px;}
.max-w-125 {max-width: 125px;}
.max-w-150 {max-width: 150px;}
.max-w-200 {max-width: 200px;}

/*
  BY: Prasannadatta Kawadkar
  ON: 15 March 2024
  Modification: added new max-width class
*/
.max-w-12vw {max-width: 12vw;}
.max-h-full {max-height: 100%;}
.max-h-200 {max-height: 200px;}
.min-w-full {min-width: 100%;}
.min-w-20percent {min-width: 20% !important;}
.min-w-19-5rem {min-width: 19.5rem !important;}
.min-w-32rem {min-width: 32rem !important;}
.min-w-31-4rem {min-width: 31.4rem !important;}
.min-w-16px {min-width: 16px !important;}
.min-w-32px {min-width: 32px !important;}
.min-w-12px {min-width: 12px !important;}
.min-w-20px {min-width: 20px;}
.min-w-15px {min-width: 15px;}
.min-w-18px {min-width: 18px;}
.min-w-24px-imp {min-width: 24px !important;}
.min-w-28px-imp {min-width: 28px !important;}
.min-w-25px {min-width: 25px;}
.min-w-125px {min-width: 125px;}
.min-w-85px {min-width: 85px;}
/*
  BY: Prasannadatta Kawadkar
  ON: 15 March 2024
  Modification: added new min-width class
*/
.min-w-75px {min-width: 75px;}
.min-w-90px {min-width: 90px;}
.min-w-19-5rem{min-width: 19.5rem;}
.min-w-32rem {min-width: 31.4rem;}
.max-h-37px {max-height: 37px !important;}
.max-h-300px {max-height: 300px !important;}
.min-w-42px {min-width: 42px;}
.min-w-44px {min-width: 44px;}
.min-h-46 {min-height: 46px !important;}
.min-h-48 {min-height: 48px;}
.min-h-auto-imp {min-height: auto !important;}
.min-h-74 {min-height: 74px !important;}
.min-h-83 {min-height: 83px !important;}
.min-h-100 {min-height: 100px;}
.min-h-272 {min-height: 272px;}
.min-h-300 {min-height: 300px;}
.min-h-38px {min-height: 38px !important;}
.min-h-42px {min-height: 42px;}
.min-h-44px {min-height: 44px;}
.min-h-350 {min-height: 350px;}
.min-h-400 {min-height: 400px;}
.min-h-522 {min-height: 522px;}
.min-h-560 {min-height: 560px;}
.min-h-632 {min-height: 632px;}
.mh-50vh {max-height: 50vh !important;}
.h-344px {height: 344px;}
.h-376px {height: 376px;}
.h-382px {height: 382px;}
.h-400 {height: 400px;}
.min-h-full {min-height: 100%;}
.min-h-full-imp {min-height: 100% !important;}
.h-1.4 {height: 1.4rem;}
.min-w-inherit {min-width: inherit;}
.min-h-inherit {min-height: inherit;}
.w-95px {width: 95px !important;}
.w-85px {width: 85px !important;}
/*
  BY: Prasannadatta Kawadkar
  ON: 15 March 2024
  Modification: added new width class
*/
.w-90px {width: 90px !important;}
// modified by Ashutosh
// modified at 18/08/2023
// modification :class for min width and max-width for formViewer step

.max-width-180 { max-width: 180px !important; }
.min-width-180 { min-width: 180px !important; }
// modified by Rajkumar Pradhan
// modified at 21/08/2023
.max-width-70 { max-width: 70px; }
.max-width-50 { max-width: 50px; }
// modified by Vihang
// modified at 17/05/2022
// modification :class for min width fit content added

.min-w-fit-content {min-width: fit-content;}
.h-fit-content {height:fit-content;}
//.h-fill-available {height:-webkit-fill-available !important;}
// modified by Vihang
// modified at 29/04/2022
// modification : classe for height inherit

// modified by Vihang
// modified at 25/05/2022
// modification : class added for height 40px

.h-inherit {height:inherit}
.min-h-60vh { min-height:60vh;}
.min-h-70vh { min-height:70vh;}
.min-h-90vh { min-height:90vh;}
/*
  By: Prasannadatta Kawadkar
  On: 17 Jan 2024
  Modification: added new class for height
*/
.min-h-48vh { min-height: 48vh; }

// modified by Komal
// modified at 16/09/2022
// modification : class added for height 72px

// modified by Arun Singh
// modified at 23/08/2023
// modification : class added for height 33px and height 145px

.min-h-72vh { min-height:72vh;}
.min-h-61vh { min-height:61vh;}
.min-h-100 {min-height:	100px !important;}
.min-h-45 {min-height:	45px !important;}
.min-h-30 {min-height:	30px !important;}
.min-h-85 {min-width: 85px;}
.w-0 {width: 0px !important; }
.w-7px {width:7px !important;}
.w-8px {width:8px !important;}
.w-9px {width:9px !important;}
.w-10px {width:10px !important;}
.w-11px {width:11px !important;}
.w-12px {width:12px !important;}
.w-14px {width:14px !important;}
.w-15px {width:15px !important;}
.w-16px {width:16px !important;}
.w-17px {width:17px !important;}
.w-18px {width:18px !important;}
.w-60px {width:60px !important;}
.w-83px {width:83px !important;}
.w-88px {width:88px !important;}
.w-125px {width:125px !important;}
/*
  BY: Prasannadatta Kawadkar
  ON: 08 March 2024
  Modification: added new classes for width
*/
.w-135px {width:135px !important;}
.w-171px {width:171px !important;}
.w-187px {width:187px !important;}
.w-194px {width:194px !important;}
.h-7px {height:7px !important;}
.h-8px {height:8px !important;}
.h-9px {height:9px !important;}
.h-12px {height:12px !important;}
.h-10px {height:10px !important;}
.h-14px {height:14px !important;}
.h-15px {height:15px !important;}
.h-16px {height:16px !important;}
.h-17px {height:17px !important;}
.h-18px {height:18px !important;}
.w-18px {width:18px !important;}
.w-24px {width:24px !important;}
.w-26px {width:26px !important;}
.w-28px {width:28px !important;}
.w-32px {width:32px !important;}
.w-36px {width:36px !important;}
.w-20px {width:20px !important;}
.w-200px {width:200px !important;}
.w-209px {width:209px !important;}
.w-220px {width:220px !important;}
.w-270px {width:270px !important;}
.w-586px {width:586px !important;}
.h-15px {height: 15px;}
.h-20px {height:20px !important;}
.h-23px {height:23px !important;}
.h-24px {height:24px !important;}
.h-22px {height:22px !important;}
.w-25px {width:25px !important;}
.h-26px {height:26px !important;}
.h-20px {height:20px !important;}
.h-24px {height:24px !important;}
.h-28px {height:28px !important;}
.h-33px {height:33px !important;}
.h-36px {height:36px !important;}
.h-42px {height: 42px !important;}
.h-64 {height:64px !important;}
.h-68 {height:68px !important;}
.h-76px {height:76px !important;}
.h-79 {height:79px !important;}
.h-80 {height:80px !important;}
.h-82 {height:82px !important;}
.h-87 {height:87px !important;}
.h-95 {height:95px !important;}
.h-100 {height:100px !important;}
.h-108 {height:108px ;}
.h-160 {height:160px !important;}
.h-207px-imp {height:207px !important;}
.h-424px {height:424px !important;}
.w-80 {width:80px !important;}
.w-30 {width:30% !important;}
.w-22px {width:22px !important;}
.w-27px {width:27px !important;}
.w-30px {width:30px !important;}
.w-35px {width:35px !important;}
.w-40px {width:40px !important;}
.w-45px {width:45px !important;}
.w-65px {width:65px !important;}
.w-75px {width:75px !important;}
.max-w-75-p {max-width:75% !important;}
.w-50px {width:50px !important;}
.w-96px {width:96px !important;}
.w-100px {width:100px !important;}
.w-150px {width:150px !important;}
.w-166px {width:166px !important;}
/*
  Modification: same class added 2 time remove one of them
  By: Masum Raja
  Date: 28/03/2024
*/
.w-304px {width:304px !important;}
.w-128px {width:128px !important;}
.w-158px {width:158px !important;}
.w-300px {width:300px !important;}
.w-400px {width:400px !important;}
.w-450px {width:450px !important;}
.h-30 {height:30% !important;}
.h-22px {height:22px !important;}
.h-25px {height:25px !important;}
.h-30px {height:30px !important;}
.h-35px {height:35px !important;}
.h-38px {height:38px !important;}
.h-40px {height:40px !important;}
.h-50px {height:50px !important;}
.h-54px {height:54px !important;}
.h-64px {height:64px !important;}
.h-111px {height:111px !important;}
.w-70 {width:70% !important;}
.h-70 {height:70% !important;}
.w-40 {width:40% !important;}
.w-64 {width:64% !important;}
.w-45 {width:45% !important;}
.w-388px {width:388px !important;}
.w-462px {width:462px !important;}
.w-500px {width:500px !important;}
.w-812px {width:812px !important;}
.h-40 {height:40% !important;}
.h-24px {height:24px !important;}
.h-47px {height:47px;}
.h-50px {height:50px;}
.h-51px {height:51px;}
/*
  BY: Prasannadatta Kawadkar
  On: 30 Jan 2024
  Modification: class was repeated so changed one to important
*/
.h-50px-imp {height:50px !important;}
.h-60px {height:60px !important;}
.h-75px {height:75px;}
.h-78px {height:78px;}
.h-96px {height:96px !important;}
.h-296px {height:296px !important;}
.h-88px {height:88px !important;}
.h-81px {height:88px !important;}
.h-100px {height:100px;}
.h-114px {height: 114px !important;}
.h-120px {height: 120px !important;}
.h-102px {height: 102px !important;}
.h-144 {height:144px !important;}
.h-145 {height:145px !important;}
.h-154 {height:154px !important;}
.h-156 {height:165px !important;}
.h-158 {height:158px !important;}
.h-228 {height: 228px !important;}
.h-240 {height: 240px !important;}
.h-263 {height: 263px !important;}
.h-284 {height: 284px !important;}
.h-292 {height: 292px !important;}
.h-487 {height: 487px !important;}
.h-548 {height: 548px !important;}
.h-582 {height: 582px !important;}
.h-656 {height: 656px !important;}
.h-680 {height: 680px !important;}
.h-526 {height: 526px !important;}
.h-393 {height: 393px !important;}
.h-150px {height:150px;}
.h-158px {height:158px;}
.h-200px {height:200px;}
/*
  By: Prasannadatta Kawadkar
  On: 23 Jan 2024
  Modification: added new class for height
*/
.h-205px {height: 205px;}
.h-210px {height:210px;}
.h-225px {height:225px;}
.h-250px {height:250px;}
.h-270px {height:270px;}
.h-275px {height:275px;}
.h-420px {height:420px;}
.h-500px {height:500px;}
.h-min-70 {min-height: 70px ;}
.h-min-680 {min-height: 680px ;}
.h-min-706 {min-height: 706px ;}
.h-min-828 {min-height: 828px;}
/*
  BY: Prasannadatta Kawadkar
  ON: 24 April 2024
  Modification: added new height class
*/
.h-min-100vh-200 {min-height: calc(100vh - 200px) !important};

.w-1vw {width: 1vw !important;}
.w-2vw {width: 2vw !important;}
.w-3vw {width: 3vw !important;}
.w-4vw {width: 4vw !important;}
.w-5vw {width: 5vw !important;}
.w-6vw {width: 6vw !important;}
.w-7vw {width: 7vw !important;}
.w-8vw {width: 8vw !important;}
.w-9vw {width: 9vw !important;}
.w-10vw {width: 10vw !important;}
.w-11vw {width: 11vw !important;}
.w-12vw {width: 12vw !important;}
.w-13vw {width: 13vw !important;}
.w-14vw {width: 14vw !important;}
.w-15vw {width: 15vw !important;}
.w-16vw {width: 16vw !important;}
.w-17vw {width: 17vw !important;}
.w-18vw {width: 18vw !important;}
.w-19vw {width: 19vw !important;}
.w-20vw {width: 20vw !important;}
.w-21vw {width: 21vw !important;}
.w-22vw {width: 22vw !important;}
.w-23vw {width: 23vw !important;}
.w-24vw {width: 24vw !important;}
.w-25vw {width: 25vw !important;}
.w-26vw {width: 26vw !important;}
.w-27vw {width: 27vw !important;}
.w-28vw {width: 28vw !important;}
.w-29vw {width: 29vw !important;}
.w-30vw {width: 30vw !important;}
.w-31vw {width: 31vw !important;}
.w-32vw {width: 32vw !important;}
.w-33vw {width: 33vw !important;}
.w-34vw {width: 34vw !important;}
.w-35vw {width: 35vw !important;}
.w-36vw {width: 36vw !important;}
.w-37vw {width: 37vw !important;}
.w-38vw {width: 38vw !important;}
.w-39vw {width: 39vw !important;}
.w-40vw {width: 40vw !important;}
.w-41vw {width: 41vw !important;}
.w-42vw {width: 42vw !important;}
.w-43vw {width: 43vw !important;}
.w-44vw {width: 44vw !important;}
.w-45vw {width: 45vw !important;}
.w-46vw {width: 46vw !important;}
.w-47vw {width: 47vw !important;}
.w-48vw {width: 48vw !important;}
.w-49vw {width: 49vw !important;}
.w-50vw {width: 50vw !important;}
.w-51vw {width: 51vw !important;}
.w-52vw {width: 52vw !important;}
.w-53vw {width: 53vw !important;}
.w-54vw {width: 54vw !important;}
.w-55vw {width: 55vw !important;}
.w-56vw {width: 56vw !important;}
.w-57vw {width: 57vw !important;}
.w-58vw {width: 58vw !important;}
.w-59vw {width: 59vw !important;}
.w-60vw {width: 60vw !important;}
.w-61vw {width: 61vw !important;}
.w-62vw {width: 62vw !important;}
.w-63vw {width: 63vw !important;}
.w-64vw {width: 64vw !important;}
.w-65vw {width: 65vw !important;}
.w-66vw {width: 66vw !important;}
.w-67vw {width: 67vw !important;}
.w-68vw {width: 68vw !important;}
.w-69vw {width: 69vw !important;}
.w-70vw {width: 70vw !important;}
.w-71vw {width: 71vw !important;}
.w-72vw {width: 72vw !important;}
.w-73vw {width: 73vw !important;}
.w-74vw {width: 74vw !important;}
.w-75vw {width: 75vw !important;}
.w-76vw {width: 76vw !important;}
.w-77vw {width: 77vw !important;}
.w-78vw {width: 78vw !important;}
.w-79vw {width: 79vw !important;}
.w-80vw {width: 80vw !important;}
.w-81vw {width: 81vw !important;}
.w-82vw {width: 82vw !important;}
.w-83vw {width: 83vw !important;}
.w-84vw {width: 84vw !important;}
.w-85vw {width: 85vw !important;}
.w-86vw {width: 86vw !important;}
.w-87vw {width: 87vw !important;}
.w-88vw {width: 88vw !important;}
.w-89vw {width: 89vw !important;}
.w-90vw {width: 90vw !important;}
.w-91vw {width: 91vw !important;}
.w-92vw {width: 92vw !important;}
.w-93vw {width: 93vw !important;}
.w-94vw {width: 94vw !important;}
.w-95vw {width: 95vw !important;}
.w-96vw {width: 96vw !important;}
.w-97vw {width: 97vw !important;}
.w-98vw {width: 98vw !important;}
.w-99vw {width: 99vw !important;}
.w-100vw {width: 100vw !important;}

.w-1p {width: 1% !important;}
.w-2p {width: 2% !important;}
.w-3p {width: 3% !important;}
.w-4p {width: 4% !important;}
.w-5p {width: 5% !important;}
.w-6p {width: 6% !important;}
.w-7p {width: 7% !important;}
.w-8p {width: 8% !important;}
.w-9p {width: 9% !important;}
.w-10p {width: 10% !important;}
.w-11p {width: 11% !important;}
.w-12p {width: 12% !important;}
.w-13p {width: 13% !important;}
.w-14p {width: 14% !important;}
.w-15p {width: 15% !important;}
.w-16p {width: 16% !important;}
.w-17p {width: 17% !important;}
.w-18p {width: 18% !important;}
.w-19p {width: 19% !important;}
.w-20p {width: 20% !important;}
.w-21p {width: 21% !important;}
.w-22p {width: 22% !important;}
.w-23p {width: 23% !important;}
.w-24p {width: 24% !important;}
.w-25p {width: 25% !important;}
.w-26p {width: 26% !important;}
.w-27p {width: 27% !important;}
.w-28p {width: 28% !important;}
.w-29p {width: 29% !important;}
.w-30p {width: 30% !important;}
.w-31p {width: 31% !important;}
.w-32p {width: 32% !important;}
.w-33p {width: 33% !important;}
.w-34p {width: 34% !important;}
.w-35p {width: 35% !important;}
.w-36p {width: 36% !important;}
.w-37p {width: 37% !important;}
.w-38p {width: 38% !important;}
.w-39p {width: 39% !important;}
.w-40p {width: 40% !important;}
.w-41p {width: 41% !important;}
.w-42p {width: 42% !important;}
.w-43p {width: 43% !important;}
.w-44p {width: 44% !important;}
.w-45p {width: 45% !important;}
.w-46p {width: 46% !important;}
.w-47p {width: 47% !important;}
.w-48p {width: 48% !important;}
.w-49p {width: 49% !important;}
.w-50p {width: 50% !important;}
.w-51p {width: 51% !important;}
.w-52p {width: 52% !important;}
.w-53p {width: 53% !important;}
.w-54p {width: 54% !important;}
.w-55p {width: 55% !important;}
.w-56p {width: 56% !important;}
.w-57p {width: 57% !important;}
.w-58p {width: 58% !important;}
.w-59p {width: 59% !important;}
.w-60p {width: 60% !important;}
.w-61p {width: 61% !important;}
.w-62p {width: 62% !important;}
.w-63p {width: 63% !important;}
.w-64p {width: 64% !important;}
.w-65p {width: 65% !important;}
.w-66p {width: 66% !important;}
.w-67p {width: 67% !important;}
.w-68p {width: 68% !important;}
.w-69p {width: 69% !important;}
.w-70p {width: 70% !important;}
.w-71p {width: 71% !important;}
.w-72p {width: 72% !important;}
.w-73p {width: 73% !important;}
.w-74p {width: 74% !important;}
.w-75p {width: 75% !important;}
.w-76p {width: 76% !important;}
.w-77p {width: 77% !important;}
.w-78p {width: 78% !important;}
.w-79p {width: 79% !important;}
.w-80p {width: 80% !important;}
.w-81p {width: 81% !important;}
.w-82p {width: 82% !important;}
.w-83p {width: 83% !important;}
.w-84p {width: 84% !important;}
.w-85p {width: 85% !important;}
.w-86p {width: 86% !important;}
.w-87p {width: 87% !important;}
.w-88p {width: 88% !important;}
.w-89p {width: 89% !important;}
.w-90p {width: 90% !important;}
.w-91p {width: 91% !important;}
.w-92p {width: 92% !important;}
.w-93p {width: 93% !important;}
.w-94p {width: 94% !important;}
.w-95p {width: 95% !important;}
.w-96p {width: 96% !important;}
.w-97p {width: 97% !important;}
.w-98p {width: 98% !important;}
.w-99p {width: 99% !important;}
.w-100p {width: 100% !important;}

// modified by Masum Raja
// modified at 08/01/2024
// modification : class added for height, width text color and background

/*
  Modification: added height class and other background
  By: Masum Raja
  Date: 15/01/2024
*/

.w-350px {width: 350px !important;}

.h-1vh {height: 1vh !important;}
.h-2vh {height: 2vh !important;}
.h-3vh {height: 3vh !important;}
.h-4vh {height: 4vh !important;}
.h-5vh {height: 5vh !important;}
.h-6vh {height: 6vh !important;}
.h-7vh {height: 7vh !important;}
.h-8vh {height: 8vh !important;}
.h-9vh {height: 9vh !important;}
.h-10vh {height: 10vh !important;}
.h-11vh {height: 11vh !important;}
.h-12vh {height: 12vh !important;}
.h-13vh {height: 13vh !important;}
.h-14vh {height: 14vh !important;}
.h-15vh {height: 15vh !important;}
.h-16vh {height: 16vh !important;}
.h-17vh {height: 17vh !important;}
.h-18vh {height: 18vh !important;}
.h-19vh {height: 19vh !important;}
.h-20vh {height: 20vh !important;}
.h-21vh {height: 21vh !important;}
.h-22vh {height: 22vh !important;}
.h-23vh {height: 23vh !important;}
.h-24vh {height: 24vh !important;}
.h-25vh {height: 25vh !important;}
.h-26vh {height: 26vh !important;}
.h-27vh {height: 27vh !important;}
.h-28vh {height: 28vh !important;}
.h-29vh {height: 29vh !important;}
.h-30vh {height: 30vh !important;}
.h-31vh {height: 31vh !important;}
.h-32vh {height: 32vh !important;}
.h-33vh {height: 33vh !important;}
.h-34vh {height: 34vh !important;}
.h-35vh {height: 35vh !important;}
.h-36vh {height: 36vh !important;}
.h-37vh {height: 37vh !important;}
.h-38vh {height: 38vh !important;}
.h-39vh {height: 39vh !important;}
.h-40vh {height: 40vh !important;}
.h-41vh {height: 41vh !important;}
.h-42vh {height: 42vh !important;}
.h-43vh {height: 43vh !important;}
.h-44vh {height: 44vh !important;}
.h-45vh {height: 45vh !important;}
.h-46vh {height: 46vh !important;}
.h-47vh {height: 47vh !important;}
.h-48vh {height: 48vh !important;}
.h-49vh {height: 49vh !important;}
.h-50vh {height: 50vh !important;}
.h-51vh {height: 51vh !important;}
.h-52vh {height: 52vh !important;}
.h-53vh {height: 53vh !important;}
.h-54vh {height: 54vh !important;}
.h-55vh {height: 55vh !important;}
.h-56vh {height: 56vh !important;}
.h-57vh {height: 57vh !important;}
.h-58vh {height: 58vh !important;}
.h-59vh {height: 59vh !important;}
.h-60vh {height: 60vh !important;}
.h-61vh {height: 61vh !important;}
.h-62vh {height: 62vh !important;}
.h-63vh {height: 63vh !important;}
.h-64vh {height: 64vh !important;}
.h-65vh {height: 65vh !important;}
.h-66vh {height: 66vh !important;}
.h-67vh {height: 67vh !important;}
.h-68vh {height: 68vh !important;}
.h-69vh {height: 69vh !important;}
.h-70vh {height: 70vh !important;}
.h-71vh {height: 71vh !important;}
.h-72vh {height: 72vh !important;}
.h-73vh {height: 73vh !important;}
.h-74vh {height: 74vh !important;}
.h-min-74vh {min-height: 74vh !important;}
.h-75vh {height: 75vh !important;}
.h-76vh {height: 76vh !important;}
.h-77vh {height: 77vh !important;}
.h-78vh {height: 78vh !important;}
.h-79vh {height: 79vh !important;}
.h-80vh {height: 80vh !important;}
.h-81vh {height: 81vh !important;}
.h-82vh {height: 82vh !important;}
.h-83vh {height: 83vh !important;}
.h-84vh {height: 84vh !important;}
.h-85vh {height: 85vh !important;}
.h-86vh {height: 86vh !important;}
.h-87vh {height: 87vh !important;}
.h-88vh {height: 88vh !important;}
.h-89vh {height: 89vh !important;}
.h-90vh {height: 90vh !important;}
.h-91vh {height: 91vh !important;}
.h-92vh {height: 92vh !important;}
.h-93vh {height: 93vh !important;}
.h-94vh {height: 94vh !important;}
.h-95vh {height: 95vh !important;}
.h-96vh {height: 96vh !important;}
.h-97vh {height: 97vh !important;}
.h-98vh {height: 98vh !important;}
.h-99vh {height: 99vh !important;}
.h-100vh {height: 100vh !important;}
.h-110vh {height:110vh !important;}
.h-100vh-100 {height: calc(100vh - 100px)};
.h-100vh-70 {height: calc(100vh - 70px)};
.h-100vh-64 {height: calc(100vh - 64px)};
.h-100vh-50 {height: calc(100vh - 50px)};
.h-100vh-36 {height: calc(100vh - 36px)};
.h-100vh-59 {height: calc(100vh - 59px)};
.h-100vh-90 {height: calc(100vh - 90px)};
.h-100vh-55 {height: calc(100vh - 55px) !important};
.h-100vh-105-imp {height: calc(100vh - 105px) !important;}
.h-100vh-145-imp {height: calc(100vh - 145px) !important;}
.h-100vh-105 {height: calc(100vh - 105px)}
.h-100vh-129 {height: calc(100vh - 129px)}
.h-100vh-130 {height: calc(100vh - 130px)}
.h-100vh-135 {height: calc(100vh - 135px) !important};
.h-100vh-132 {height: calc(100vh - 132px) !important};
.h-100vh-140 {height: calc(100vh - 140px) !important};
.h-100vh-192 {height: calc(100vh - 192px) !important};
.h-100vh-165 {height: calc(100vh - 165px) !important};
.h-100vh-175 {height: calc(100vh - 175px) !important};
.h-100vh-200 {height: calc(100vh - 200px) !important};
.h-100vh-46 {height: calc(100vh- 46vh) !important}
.h-100vh-260 {height: calc(100vh - 260px) !important};
.h-1p {height: 1% !important;}
.h-2p {height: 2% !important;}
.h-3p {height: 3% !important;}
.h-4p {height: 4% !important;}
.h-5p {height: 5% !important;}
.h-6p {height: 6% !important;}
.h-7p {height: 7% !important;}
.h-8p {height: 8% !important;}
.h-9p {height: 9% !important;}
.h-10p {height: 10% !important;}
.h-11p {height: 11% !important;}
.h-12p {height: 12% !important;}
.h-13p {height: 13% !important;}
.h-14p {height: 14% !important;}
.h-15p {height: 15% !important;}
.h-16p {height: 16% !important;}
.h-17p {height: 17% !important;}
.h-18p {height: 18% !important;}
.h-19p {height: 19% !important;}
.h-20p {height: 20% !important;}
.h-21p {height: 21% !important;}
.h-22p {height: 22% !important;}
.h-23p {height: 23% !important;}
.h-24p {height: 24% !important;}
.h-25p {height: 25% !important;}
.h-26p {height: 26% !important;}
.h-27p {height: 27% !important;}
.h-28p {height: 28% !important;}
.h-29p {height: 29% !important;}
.h-30p {height: 30% !important;}
.h-31p {height: 31% !important;}
.h-32p {height: 32% !important;}
.h-33p {height: 33% !important;}
.h-34p {height: 34% !important;}
.h-35p {height: 35% !important;}
.h-36p {height: 36% !important;}
.h-37p {height: 37% !important;}
.h-38p {height: 38% !important;}
.h-39p {height: 39% !important;}
.h-40p {height: 40% !important;}
.h-41p {height: 41% !important;}
.h-42p {height: 42% !important;}
.h-43p {height: 43% !important;}
.h-44p {height: 44% !important;}
.h-45p {height: 45% !important;}
.h-46p {height: 46% !important;}
.h-47p {height: 47% !important;}
.h-48p {height: 48% !important;}
.h-49p {height: 49% !important;}
.h-50p {height: 50% !important;}
.h-51p {height: 51% !important;}
.h-52p {height: 52% !important;}
.h-53p {height: 53% !important;}
.h-54p {height: 54% !important;}
.h-55p {height: 55% !important;}
.h-56p {height: 56% !important;}
.h-57p {height: 57% !important;}
.h-58p {height: 58% !important;}
.h-59p {height: 59% !important;}
.h-60p {height: 60% !important;}
.h-61p {height: 61% !important;}
.h-62p {height: 62% !important;}
.h-63p {height: 63% !important;}
.h-64p {height: 64% !important;}
.h-65p {height: 65% !important;}
.h-66p {height: 66% !important;}
.h-67p {height: 67% !important;}
.h-68p {height: 68% !important;}
.h-69p {height: 69% !important;}
.h-70p {height: 70% !important;}
.h-71p {height: 71% !important;}
.h-72p {height: 72% !important;}
.h-73p {height: 73% !important;}
.h-74p {height: 74% !important;}
.h-75p {height: 75% !important;}
.h-76p {height: 76% !important;}
.h-77p {height: 77% !important;}
.h-78p {height: 78% !important;}
.h-79p {height: 79% !important;}
.h-80p {height: 80% !important;}
.h-81p {height: 81% !important;}
.h-82p {height: 82% !important;}
.h-83p {height: 83% !important;}
.h-84p {height: 84% !important;}
.h-85p {height: 85% !important;}
.h-86p {height: 86% !important;}
.h-87p {height: 87% !important;}
.h-88p {height: 88% !important;}
.h-89p {height: 89% !important;}
.h-90p {height: 90% !important;}
.h-91p {height: 91% !important;}
.h-92p {height: 92% !important;}
.h-93p {height: 93% !important;}
.h-94p {height: 94% !important;}
.h-95p {height: 95% !important;}
.h-96p {height: 96% !important;}
.h-97p {height: 97% !important;}
.h-98p {height: 98% !important;}
.h-99p {height: 99% !important;}
.h-100p {height: 100% !important;}
.h-unset { height: unset !important;}


.w-20 {width:20% !important;}
.w-25 {width:25% !important;}
.w-55 {width:55% !important;}
.w-41 {width:41% !important;}
.w-60 {width:60% !important;}
.w-64 {width:64%;}
.h-60 {height:60% !important;}
.h-23px {height : 23px !important;}
.h-32px {height: 32px !important;}
.min-h-32px {min-height: 32px !important;}
.min-h-16px {min-height: 16px;}
.h-34px {height: 34px !important;}
.h-38px {height:38px !important;}
.h-40px {height: 40px !important;}
.h-44px {height: 44px !important;}
.h-62px {height: 62px !important;}
.h-2rem {height:2rem !important;}
.h-max-content {height: max-content;}
.w-auto {width: auto;}
.w-max-content {width: max-content;}
.w-15 {width: 15%;}
/*[ Top Bottom Left Right ]
-----------------------------------------------------------
*/
/*modified by Vihang
   modifield on 16/05/2022
   modification: top 5 class added
*/

/*
  modified by Masum Raja
  modifield on 18/08/2023
  modification: top neg 1 class added
  modified by Masum
  modifield on 19/07/2023
  modification: top 1 & right 1 class added
*/

/*
  Modification: top, left, class added
  By: Masum Raja
  Date: 10/01/2024
*/
.top-0 {top: 0 !important;}
.top-5 {top:5px}
.top-8 {top:8px !important}
.top-11 {top:11px}
/*
  BY: Prasanadatta Kawadkar
  ON: 13 March 2024
  Modification: added new class for top
*/
/*
  Modification: added 2 new class top and bg
  By: Masum Raja
  Date: 27/03/2024
*/
.top-12 {top:12px !important}
.top-15 {top:15px}
.top-20 {top:20px !important}
.top-21 {top:21px !important}
.top-40 {top:40px !important}
.top-45 {top:45px !important}
.top-55 {top:55px !important}
.top-34 {top:34px !important}
.top-76 {top:76px}
.top-79 {top:79px}
.top-16 {top:16px !important}
.top-24 {top:24px !important}
.top-29 {top:29px !important}
.top-90 {top:90px}
.top-88 {top:88px}
.top-98 {top:98px}
.top-72 {top:72px}
.top-80 {top:80px !important;}
.top-96 {top:96px}
.top-100 {top:100px}
.top-110 {top:110px !important}
.top-140 {top:140px !important;}
.top-155 {top:155px !important;}
.top-104 {top:104px}
.top-neg-2 {top: -2px !important;}
.top-neg-10 {top: -10px !important;}
.top-neg-15 {top: -15px !important;}
.top-neg-7 {top: -7px !important;}
.top-20-p {top: 20% !important;}
.top-50-p {top: 50% !important;}
.top-100-p {top: 100% !important;}
.bottom-neg-1 {bottom: -1px;}
.bottom-neg-3 {bottom: -3px;}
.bottom-neg-32px {bottom: -32px !important}
.bottom-0 {bottom: 0;}
.bottom-0-imp {bottom: 0 !important;}
.bottom-1 {bottom: 1px;}
.bottom-5px {bottom: 5px;}
.bottom-8px {bottom: 8px;}
.bottom-10px {bottom: 10px;}
.bottom-16px {bottom: 16px;}
.bottom-24px {bottom: 24px;}
.bottom-26px {bottom: 26px;}
.bottom-40px {bottom: 40px;}
.bottom-30px {bottom: 30px;}
.bottom-32px {bottom: 32px;}
.bottom-33px {bottom: 33px;}
.bottom-34px {bottom: 34px;}
.bottom-35px {bottom: 35px;}
.bottom-34px {bottom: 34px;}
.bottom-38px {bottom: 38px;}
.bottom-60px {bottom: 60px;}
.bottom-60px-imp {bottom: 60px !important;}
.bottom-70px-imp {bottom: 70px !important;}
.bottom-neg-4 {bottom: -4px !important;}
.bottom-neg-3 {bottom: -3px !important;}
.bottom-100-p {bottom: 100% !important;}
.bottom-15-p {bottom: 15%;}
.bottom-19-p {bottom: 19%;}
.bottom-70-p {bottom: 70%;}
.bottom-90-p {bottom: 90%;}
.left-0 {left: 0 !important;}
.left-8 {left: 8px !important;}
.left-10px {left: 10px !important}
.left-12px {left: 12px !important}
.left-17 {left: 17px !important;}
.left-24px {left: 24px;}
.left-18px {left: 18px;}
.left-36px {left: 36px;}
.left-106px {left: 106px;}
.left-120px {left: 120px;}
.left-124px {left: 124px;}
.left-150px {left: 150px;}
.left-224px {left: 224px;}
.left-238px {left: 238px;}
.left-252px {left: 252px;}
.left-256px {left: 256px;}
.left-294px {left: 294px;}
.left-25-per {left: 25% !important;}
.left-87-per {left: 87% !important;}
.left-88-per {left: 88% !important;}
.left-89-per {left: 89% !important;}
.left-90-per {left: 90% !important;}
.left-91-per {left: 91% !important;}
.left-neg-100-per {left: -100% !important;}
.left-40px {left:40px;}
.left-120px {left: 120px;}
.left-195px {left: 195px !important;}
.left-207px {left: 207px;}
.left-222px {left: 222px;}
.left-234px {left: 234px;}
.left-244px {left:244px;}
.left-168px {left:168px;}
.left-190px {left:190px;}
.left-206px {left:206px;}
.left-289px {left:289px;}
.left-296px {left: 296px;}
.left-436px {left: 436px;}
.left-358px {left:358px;}
.left-376px {left:376px;}
.left-372px {left:372px;}
.left-386px {left:386px;}
.left-394px {left:394px;}
.left-410px {left:410px;}
.left-493px {left:493px;}
.left-520px {left:520px;}
.left-562px {left:562px;}
.left-578px {left:578px;}
.left-586px {left:586px;}
.left-50-p {left: 50% !important;}
.left-98-p {left: 98%;}
.left-70-p {left: 70%;}
.left-100-p {left: 100%;}
.left-40-p {left: 40% !important;}
.left-72-per {left:72% !important;}
.right-0 {right: 0 !important;}
.right-5px {right: 5px;}
.right-7px {right: 7px;}
.right-8 {right: 8px;}
.right-10px {right: 10px;}
.right-10px-imp {right: 10px !important;}
.right-16-px {right: 16px;}
.right-32px {right: 32px;}
.right-16 {right: 16px;}
.right-24px {right:  24px;}
.right-34px {right:  34px;}
.right-26px {right: 26px;}
.right-40px {right: 40px;}
.right-45px {right: 45px;}
.right-152px {right: 152px;}
.right-172px {right: 172px;}
.right-42px {right: 42px;}
.right-335px-imp {right: 335px !important;}

.right-49px {right: 49px;}
.right-60px {right: 60px;}
.right-65px {right: 65px;}
.right-70px {right: 70px;}
.right-75px {right: 75px;}
.right-neg-10 {right: -10px !important;}
.right-neg-8 {right: -8px !important;}
.bottom-neg-28 {bottom: -28px !important;}
.top-auto {top: auto;}
.top-4px {top: 4px;}
.top-4px-imp {top: 4px !important;}
.top-7px {top: 7px;}
.bottom-auto {bottom: auto;}
.left-auto {left: auto;}
.right-auto {right: auto;}
.left-110px {left:110px; }
.left-156px {left:156px; }
.left-122px {left:122px; }
.left-134px {left:134px; }
.left-158px {left:158px !important;}
.bottom-65px {bottom: 65px;}

/*[ Opacity ]
-----------------------------------------------------------
*/
.op-0-0 {opacity: 0;}
.op-0-1 {opacity: 0.1;}
.op-0-2 {opacity: 0.2;}
.op-0-3 {opacity: 0.3;}
.op-0-4 {opacity: 0.4;}
.op-0-5 {opacity: 0.5;}
.op-0-6 {opacity: 0.6;}
.op-0-7 {opacity: 0.7;}
.op-0-8 {opacity: 0.8;}
.op-0-9 {opacity: 0.9;}
.op-1-0 {opacity: 1 !important;}
.op-unset {opacity: unset !important;}
/*[ Background ]
-----------------------------------------------------------
*/

/*
  modified by Masum Raja
  modifield on 18/08/2023
  modification: one background hover class added
*/

/*
  Modification: added new class for background
  By: Masum Raja
  Date: 10/01/2024
*/
.bg-none {background: none !important;}
.bgwhite {background-color: white !important;}
.bgblack {background-color: black;}
.bggray {background-color: #e2e2e2;}
.bg-sunset-orange {background-color:#ed553b !important;}
.bg-theme {background-color: rgba(240, 248, 255,1) !important;}
.bg-theme-secondary {background-color: #bcedfc;}
.bg-table-header {background-color: #f0f8ff !important;}
.bg-transparent {background-color: transparent !important;}
.bg-bfe1ff {background-color: #bfe1ff !important;}
.bg-white {background-color: #fff !important;}
.bg-212a3e-hov:hover {background-color: #212a3e !important;}
.bg-gray {background-color: grey !important;}
.bg-grey {background-color: #eeeeee !important;}
.bg-lightgrey {background-color: #cacad4 !important;}
.bg-lightgreyish {background-color: #CFD2CF !important;}
.bg-titanGrey {background-color: #52576c !important;}
.bg-polarWhite {background-color: #eef4f4 !important;}
.bg-taigaBrown {background-color: #8c804c !important;}
.bg-denimBlue {background-color: #2243b6 !important;}
.bg-lightblue-header {background-color: #7bdaf7;}
.bg-custom-header {background-color: #bcedfc;}
.bg-lightblue-count {background-color: #64b2d1;}
.bg-custom-count {background-color: #69ACAC;}
.bg-blue-header {background-color: #7cb5ec;}
.bg-blue-count {background-color: #4e779e;}
.bg-grey-header {background-color: #7f7f84;}
.bg-hov-grey:hover {background-color: #dbe9e9;}
.bg-hov-f8fafc:hover {background-color: #f8fafc;}
.bg-grey-count {background-color: #434348;}
.bg-green-header {background-color: #66a25b;}
.bg-green-count {background-color: #8de77d;}
.bg-orange-header {background-color: rgb(212 160 107);}
.bg-orange-count {background-color: rgb(218 107 54);}
.bg-red-header {background-color: #d45d58 !important;}
.bg-red-ff0000e0 {background-color: #ff0000e0 !important;}
.bg-red-count {background-color: #c11d1d;}
.bg-case-theme {background: var(--case-theme);}
.bg-408b8f61 {background:#408b8f61;}
.bg-e5e5e5 {background:#e5e5e5 !important;}
.bg-f7f7f7 { background:#f7f7f7;}
.bg-bebebe {background: #bebebe;}
.bg-d2dcd3 {background: #d2dcd3;}
.bg-ddf0f3 {background: #ddf0f3;}
.bg-4b4d51 {background: #4b4d51;}
.bg-efeef0 {background: #efeef0;}
.bg-light-grey {background: #e9ebf0;}
.selectedbackground {background: var(--selected-bacground);}
.bg-002c5f {background: #002C5F !important;}
.bg-e5dcd3 {background: #e5dcd3 !important;}
.bg-d3e8f6 {background: #d3e8f6 !important;}
.bg-d9d9d9 {background: #d9d9d9 !important;}
.bg-black-75 {background: rgba(0, 0, 0, 0.75);}
.bg-pink {background: #F87A53 !important;}
.bg-98C1D9 {background: #98C1D9 ;}
.bg-EF7F68 {background: #EF7F68; }
.bg-EF7F68-25 {background: rgba(239, 127, 104, 0.25) !important;}
.bg-95B6A8 {background: #95B6A8 !important; }
.bg-95B6A8-25 {background-color: rgba(149, 182, 168, 0.25) !important;}
.bg-ccc {background: #ccc !important;}
.bg-98C1D9-25 {background: rgba(152, 193, 217, 0.25) !important;}
.bg-98C1D9-75 {background: rgba(152, 193, 217, 0.75) !important; }
.bg-DC8F99 {background: #DC8F99}
.bg-526F95 {background: #526F95;}
.bg-73C136 {background: #73C136;}
.bg-DC8F99 {background: #DC8F99;}
.bg-F2F7FA {background: #F2F7FA;}
.bg-CFE3C0 {background: #CFE3C0 !important;}
.bg-3C3C3C {background: #3C3C3C;}
.bg-FF496C {background: #FF496C;}
.bg-3C5C83 {background: #3C5C83 !important;}
.bg-E64C7D {background: #E64C7D;}
.bg-526F95 {background: #526F95;}
.bg-E79999 {background: #E79999;}
.bg-88B7D2 {background: #88B7D2 !important;}
.bg-D4D2D2 {background: #d4d2d2;}
.bg-808080 {background: #808080;}
.bg-6297F8 {background: #6297F8;}
.bg-79C6C1 {background: #79C6C1;}
.bg-d1f4cc {background: #d1f4cc !important;}
.bg-526f95 {background: #526f95 !important;}
.bg-blueGreenGradient-100 {
  background: linear-gradient(0deg, #79C6C1 0%, #2E8994 0.01%, #002C5F 87.5%);
  }
.bg-skyDarkBlue {
  //background: linear-gradient(0deg, #002C5F 0%, #2E8994 53.12%, #79C6C1 100%);
  background: linear-gradient(180deg, #002C5F 0%, #002C5F 24.81%, #1275B0 100%);
}
.bg-D2E5E3 {
  background: #D2E5E3;
}
.bg-bfcad7 {background: #bfcad7 !important;}
.bg-0D4858 {background: #0D4858 ;}
.bg-96C4C9 {background: #96C4C9 ;}
.bg-2E8994 {background: #2E8994 ;}
.bg-BFCAD7 {background: #BFCAD7 ;}
.bg-8095AF {background: #8095AF ;}
.bg-FFCEB5 {background: #FFCEB5 ;}
.bg-264C77 {background: #264C77 ;}
.bg-264C77-hov:hover {background: #264C77;}
.bg-FFB6B6 {background: #FFB6B6 ;}
.bg-E9EDF1 {background: #E9EDF1 ;}
.bg-CAE1E4 {background: #CAE1E4 !important;}
.bg-BFCAD7-75 {background: rgb(191, 202, 215, 0.75) !important;}
.bg-0D4858-75 {background: rgb(13, 72, 88, 0.75) !important;}
.bg-96C4C9-75 {background: rgb(150, 196, 201, 0.75) !important;}
.bg-2E8994-75 {background: rgb(46, 137, 148, 0.75) !important;}
.bg-8095AF-75 {background: rgb(128, 149, 175, 0.75) !important;}
.bg-FFCEB5-75 {background: rgb(255, 206, 181, 0.75) !important;}
.bg-264C77-75 {background: rgb(38, 76, 119, 0.75) !important;}
.bg-FFB6B6-75 {background: rgb(255, 182, 182, 0.75) !important;}
.bg-CAE1E4-75 {background: rgb(202, 225, 228, 0.75) !important;}
.bg-264C77 {background: #264C77 !important;}
.bg-1275B0 {background: #1275B0 !important;}
.bg-1b5aa2 {background: #1b5aa2 !important;}
.bg-4D97C4 {background: #4D97C4 !important;}
.bg-A6C9DE {background: #A6C9DE !important;}
.bg-f1f1f1 {background: #f1f1f1 !important;}
.bg-04364a {background: #04364a !important;}
/*
  BY: Prasannadatta Kawadkar
  ON: 12 Mar 2024
  Modification: added new bg class
*/
.bg-bluishGrey {background: #F1F6F9 !important;}
.bg-909AAC {background: #909aac !important;}
.bg-f5f5f5 {background: #f5f5f5 !important;}
.bg-pink-gradient {
  background: linear-gradient(90deg, rgba(237, 178, 178, 0.5) 18.75%, rgba(245, 234, 234, 0.5) 100%) !important;
}
.bg-96B6C5 {background: #96B6C5 !important;}
.bg-e3f2fd {background: #e3f2fd !important;}

/*
        modified by Vihang
        modified at 17/05/2022
        modification : background color classes for tags
  */
.bg-sunset-orange {
  background: #ed553b13 !important;
  color: #ed553b8f !important;
}
.bg-red-white {
  background: #f44336 !important;
  color: #fff !important;
}
.bg-blue-white {
  background: #326fff !important;
  color: #fff !important;
}
.bg-green {
  background: #00800013 !important;
  color: #008000 !important;
}
.bg-dbeae1 {
  background: #dbeae1 !important;
  color: #008000 !important;
}
.bg-blue {
  background: #91c7e712 !important;
  color: #91c7e7 !important;
}
.bg-e8e8e8 {
  background: #e8e8e8 !important;
  color: #818CF8 !important;
}
.bg-violet {
  background: #22618c1a !important;
  color: #2872a4 !important;
}
.bg-yellow {
  background: #fbc12d13 !important;
  color: #fbc12d !important;
}
.bg-tag-bfbfbf {
  color: #fff !important;
  background: #bfbfbf !important;
}
.bg-greybg {background: #ECECEC !important;}

.bg-yellow-2 {
  background-color:#FFEE59 !important;
}
.bg-green-3 {
  background-color:#05E700  !important;
}
.bg-002c5f-text-white {
  background-color: #002c5f !important;
  colors: #fff !important;
}
.bg-darkblue {
  background-color:#526F95  !important;
}
.bg-orange {
  background-color:#F8A67B  !important;
}
.bg-orange-75 {
  background-color:rgba(248, 166, 123, 0.75) !important;
}
.bg-skyblue {
  background-color:#98C1D9  !important;
}
.bg-lightGreen {
  background-color:#79C6C1  !important;
}
.bg-lightOrange {
  background-color:#E38858  !important;
}
.bg-F8FAFC {
  background-color: #f8fafc !important;
}
.bg-e9ecef {
  background-color: #e9ecef !important;
}
.bg-47AF2C-imp {background: #47AF2C !important;}
.bg-F7BFBF-imp {background: #F7BFBF !important;}
.bg-D1EBCA-imp {background: #D1EBCA !important;}

.bg-89D6D1-4FA4A8 {
  background: linear-gradient(180deg, #89D6D1 0%, #4FA4A8 100%);
}
.bg-62C6C6-2C8592 {
  background: linear-gradient(180deg, #62C6C6 0%, #2C8592 100%);
}
.bg-2F95A3-1A6085 {
  background: linear-gradient(180deg, #2F95A3 0%, #1A6085 100%);
}
.bg-1A6585-0A3B74 {
  background: linear-gradient(180deg, #1A6585 0%, #0A3B74 100%);
}

.bg-hov-unset {background: unset;}
.bg-hov-unset-imp {background: unset !important;}
.bg-e4dcd4 {background-color: #e4dcd4 !important;}
.bg-DCF8C6 {background-color: #DCF8C6 !important;}
.bg-00a884 {background-color: #00a884 !important;}
.bg-b4dc8c {background-color: #b4dc8c !important;}
.bg-fcebeb {background-color: #fcebeb !important;}
.bg-dcdcdccc {background-color: #dcdcdccc !important;}

#b4dc8c

.taxi-style {
  background: #fbc12d !important;
  color: #000 !important;
}
/*[ Wrap Picture ]
-----------------------------------------------------------
*/
.wrap-pic-w img {width: 100%;}
.wrap-pic-max-w img {max-width: 100%;}

/* ------------------------------------ */
.wrap-pic-h img {height: 100%;}
.wrap-pic-max-h img {max-height: 100%;}

/* ------------------------------------ */
.wrap-pic-cir {
	border-radius: 50%;
	overflow: hidden;
}
.wrap-pic-cir img {
	width: 100%;
}

/*[ Hover ]
-----------------------------------------------------------
*/
.hov-pointer:hover {cursor: pointer;}
.cursor-pointer {cursor: pointer;}
.cursor-not-allowed {cursor: not-allowed !important;}
.cursor-progress {cursor: progress !important;}
.cursor-default {cursor: default;}
.cursor-auto {cursor: auto;}
.cursor-auto-imp {cursor: auto !important;}
.cursor-default-imp {cursor: default !important;}
/*
  BY: Prasannadatta Kawadkar
  ON: 26 March 2024
  Modification: added new class for cursor pointer
*/
.cursor-pointer-imp {cursor: pointer !important;}
/* ------------------------------------ */
.hov-img-zoom {
	display: block;
	overflow: hidden;
}
.hov-img-zoom img{
	width: 100%;
	-webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}
.hov-img-zoom:hover img {
	-webkit-transform: scale(1.1);
  	-moz-transform: scale(1.1);
  	-ms-transform: scale(1.1);
  	-o-transform: scale(1.1);
	transform: scale(1.1);
}
.scale-0-9 {
	-webkit-transform: scale(0.9);
  	-moz-transform: scale(0.9);
  	-ms-transform: scale(0.9);
  	-o-transform: scale(0.9);
	transform: scale(0.9);
}



/*[  ]
-----------------------------------------------------------
*/
.bo-cir {border-radius: 50%;}

.of-hidden {overflow: hidden;}

.visible-false {visibility: hidden;}
.visible-true {visibility: visible;}

.overflow-x-y {overflow: auto !important;}
.overflow-x {overflow-x: auto}
.overflow-y {overflow-y: auto}
.overflow-unset {overflow: unset !important}



.overflow-x-y-scroll {overflow: scroll}
.overflow-x-scroll {overflow-x: scroll}
.overflow-y-scroll {overflow-y: scroll}
.overflow-y-auto {overflow-y: auto}

//By:Komal
//Date:30 Sept 2022
//Modification:Class for overflow-x hidden added

.overflow-x-hidden {overflow-x: hidden}
.right-33 {right:33px !important}

/*[ Transition ]
-----------------------------------------------------------
*/
.trans-0-1 {
	-webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
}
.trans-0-2 {
	-webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}
.trans-0-3 {
	-webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
.trans-0-4 {
	-webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.trans-0-5 {
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.trans-0-6 {
	-webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}
.trans-0-9 {
	-webkit-transition: all 0.9s;
    -o-transition: all 0.9s;
    -moz-transition: all 0.9s;
    transition: all 0.9s;
}
.trans-1-0 {
	-webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
}



/*[ Layout ]
///////////////////////////////////////////////////////////
*/

/*[ Flex ]
-----------------------------------------------------------
*/
/* ------------------------------------ */
.flex-w {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-basis-auto {
	flex-basis: auto !important;
}
.flex-basis-70 {
	flex-basis: 70% !important;
}
/* ------------------------------------ */
.flex-l {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
}


.flex-basis-auto {
	flex-basis:auto;
}

.flex-r {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
}

.flex-c {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
}

.flex-sa {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-around;
}

.flex-sb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
}

.flex-se {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-evenly;
}

/* ------------------------------------ */
.flex-t {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.flex-b {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.flex-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

/*
  Modification: added flex important class
  By: Masum Raja
  Date: 24/02/2024
*/

.flex-m-imp {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex !important;
	-ms-align-items: center !important;
	align-items: center !important;
}

.flex-str {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: stretch;
	align-items: stretch;
}

/* ------------------------------------ */
.flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
}

.flex-row-rev {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.flex-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

.flex-col-rev {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	-o-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

/* ------------------------------------ */
.flex-c-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
}

.flex-c-t {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.flex-c-b {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.flex-c-str {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: stretch;
	align-items: stretch;
}

.flex-l-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	-ms-align-items: center;
	align-items: center;
}

.flex-r-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	-ms-align-items: center;
	align-items: center;
}

.flex-sa-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-around;
	-ms-align-items: center;
	align-items: center;
}

.flex-sb-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
}

/* ------------------------------------ */
.flex-col-l {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.flex-col-r {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.flex-col-c {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
}

.flex-col-l-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: flex-start;
	align-items: flex-start;
	justify-content: center;
}

.flex-col-r-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: flex-end;
	align-items: flex-end;
	justify-content: center;
}

.flex-col-c-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.flex-col-str {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: stretch;
	align-items: stretch;
}

.flex-col-sb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: space-between;
}

/* ------------------------------------ */
.flex-col-rev-l {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	-o-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.flex-col-rev-r {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	-o-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.flex-col-rev-c {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	-o-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-ms-align-items: center;
	align-items: center;
}

.flex-col-rev-str {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	-o-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-ms-align-items: stretch;
	align-items: stretch;
}


/*[ Absolute ]
-----------------------------------------------------------
*/
.ab-c-m {
	position: absolute !important;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
  	-moz-transform: translate(-50%, -50%);
  	-ms-transform: translate(-50%, -50%);
  	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.ab-c-t {
	position: absolute;
	top: 0px;
	left: 50%;
	-webkit-transform: translateX(-50%);
  	-moz-transform: translateX(-50%);
  	-ms-transform: translateX(-50%);
  	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

.ab-c-b {
	position: absolute;
	bottom: 0px;
	left: 50%;
	-webkit-transform: translateX(-50%);
  	-moz-transform: translateX(-50%);
  	-ms-transform: translateX(-50%);
  	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

.ab-l-m {
	position: absolute;
	left: 0px;
	top: 50%;
	-webkit-transform: translateY(-50%);
  	-moz-transform: translateY(-50%);
  	-ms-transform: translateY(-50%);
  	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ab-r-m {
	position: absolute;
	right: 0px;
	top: 50%;
	-webkit-transform: translateY(-50%);
  	-moz-transform: translateY(-50%);
  	-ms-transform: translateY(-50%);
  	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ab-t-l {
	position: absolute;
	left: 0px;
	top: 0px;
}

.ab-t-r {
	position: absolute;
	right: 0px;
	top: 0px;
}

.ab-b-l {
	position: absolute;
	left: 0px;
	bottom: 0px;
}

.ab-b-r {
	position: absolute;
	right: 0px;
	bottom: 0px;
}

/*[ border ]
-----------------------------------------------------------
*/

.bor-none {
  border: none !important;
}

.bor-color-transparent {
  border-color: transparent !important;
}

.bor-all-black {
  border: 1px solid #000;
}

.bor-all-818CF8 {
  border: 1px solid #818CF8;
}

.bor-all-bfbfbf {
  border: 1px solid #bfbfbf;
}

.bor-b-bfbfbf {
  border-bottom: 1px solid #bfbfbf;
}

.bor-b-DFDFDF {
  border-bottom: 1px solid #dfdfdf;
}

.bor-t-DFDFDF {
  border-top: 1px solid #dfdfdf;
}

.bor-all-transparent  {
  border: 1px solid transparent;
}

.bor-all {
  border: 1px solid color-quinary;
}
.bor-all-black-25 {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.bor-all-v3 {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.bor-all-orange {
  border: 1px solid #FF7A00;
}
.bor-all-orange-75 {
  border: 1px solid rgba(248, 166, 123, 0.75);
}
.bor-all-95B6A8 {
  border: 1px solid #95B6A8 !important;
}
.bor-all-e0e0e098 {
  border: 1px solid #e0e0e098 !important;
}
.bor-all-EF7F68 {
  border: 1px solid #EF7F68 !important;
}
.bor-all-DFDFDF {
  border: 1px solid #dfdfdf;
}
.bor-all-366591 {
  border: 1px solid #366591;
}
.bor-all-skyBlue {
  border: 1px solid rgba(0, 0, 0, 0.25)
}
.bor-l {
  border-left: 1px solid color-quinary;
}
.bor-l-00a884 {
  border-left: 3px solid #00a884;
}
.bor-l-w {
  border-left: 1px solid #fff;
}
.bor-r-w {
  border-right: 1px solid #fff;
}
.bor-r-dadada {
  border-right: 1px solid #dadada;
}

.bor-r {
  border-right: 1px solid color-quinary;
}
.bor-t {
  border-top: 1px solid color-quinary;
}
.bor-t-v2 {
  border-top: 1px solid #bfbfbf;
}
.bor-l-v2 {
  border-left: 1px solid #bfbfbf;
}
.bor-r-v2 {
  border-right: 1px solid #bfbfbf;
}
.bor-b {
  border-bottom: 1px solid color-quinary;
}
.bor-b-v2 {
  border-bottom: 1px solid #bfbfbf;
}
.bor-b-2 {
  border-bottom: 2px solid color-quinary !important;
}
.bor-3 {
  border: 3px solid white !important;
}
.bor-b-728BCE {
  border-bottom:1px solid #728BCE;
}
.bor-b-C9F57E {
  border-bottom:1px solid #C9F57E;
}
.bor-b-pink {
  border-bottom:1px solid #F87A53;
}
.bor-b-red {
  border-bottom:1px solid #FF0000;
}
.bor-b-green {
  border-bottom:1px solid #05E700 ;
}
.bor-b-yellow {
  border-bottom:1px solid #FFEE59;
}


/*
	modified by Masum Raja
	modified at 04/01/2024
	modification : Added new class for border-bottom
*/

.bor-b-e3e5e2 {
  border-bottom: 1px solid #e3e5e2;
}

.bor-t-e3e5e2 {
  border-top: 1px solid #e3e5e2;
}

.bor-all-blue {
  border: 1px solid rgba(0, 44, 95, 0.5);
}
.bor-all-lightBlue {
  border: 1px solid #6297F8;
}
.bor-all-red {
  border: 1px solid #F35252;
}
.bor-all-lightPurple {
  border: 1px solid #E64C7D;
}
.bor-all-lightPink {
  border: 1px solid #EB7097;
}
.bor-all-lightRed {
  border: 1px solid rgba(255, 0, 0, 0.25) !important;
}
.bor-all-green {
  border: 1px solid #9EDE59;
}
.bor-all-green-4 {
  border: 1px solid #02CB77;
}
.bor-all-lightOrange {
  border: 1px solid #F0C24B;
}

.bor-l-black {
  border-left: 1px solid #000 !important;
}
.bor-r-black {
  border-right: 1px solid #000 !important;
}
.bor-r-818cf8 {
  border-right: 1px solid #818cf8 !important;
}
.bor-b-818cf8 {
  border-bottom: 1px solid #818cf8 !important;
}
.bor-t-black {
  border-top: 1px solid #000 !important;
}
/*
          modified by Vihang
          modified at 13/05/2022
          modification : 479393 color border-top class added
*/
.bor-t-479393 {
  border-top: 2px solid #479393 !important;
}
.bor-b-black {
  border-bottom: 1px solid #000 !important;
}
.bor-t-none {
  border-top: none !important;
}
.bor-rad-all-0 {
  border-radius: 0px !important;
}
.bor-rad-all-5-5-0-0 {
  border-radius: 5px 5px 0 0 !important;
}
.bor-rad-left-5 {
  border-radius: 5px 0 0 5px !important;
}
.bor-rad-right-5 {
  border-radius: 0 5px 5px 0 !important;
}
.bor-rad-all-1 {
  border-radius: 1px;
}
.bor-rad-all-2 {
  border-radius: 2px;
}
.bor-rad-all-3 {
  border-radius: 3px;
}
.bor-rad-all-4 {
  border-radius: 4px;
}
.bor-rad-all-5 {
  border-radius: 5px !important;
}
.bor-rad-all-6 {
  border-radius: 6px !important;
}
.bor-rad-all-8 {
  border-radius: 8px !important;
}
.bor-rad-all-10 {
  border-radius: 10px;
}
/*
  BY: Prasannadatta Kawadkar
  ON: 26 March 2024
  Modification: added new class for border
*/
.bor-rad-all-12 {
  border-radius: 12px;
}
.bor-rad-all-12-12-0-0 {
  border-radius: 12px 12px 0 0 !important;
}
.bor-rad-all-15 {
  border-radius: 15px;
}
.bor-rad-all-16 {
  border-radius: 16px;
}
.bor-rad-all-50 {
  border-radius: 50px;
}
.bor-rad-circular {
  border-radius: 50%;
}

.bor-all-526f95 {
  border: 1px solid rgba(82, 111, 149, 0.25);
}
.bor-t-526f95 {
  border-top: 1px solid rgba(82, 111, 149, 0.25);
}
.bor-b-526f95 {
  border-bottom: 1px solid rgba(82, 111, 149, 0.25);
}
.bor-r-526f95 {
  border-right: 1px solid rgba(82, 111, 149, 0.25);
}

.bor-l-526f95 {
  border-left: 1px solid rgba(82, 111, 149, 0.25);
}

.bor-all-41635e{
  border: 1px solid #41635e;
}
.bor-all-gray{
  border:1px solid rgba(0, 0, 0, 0.25) !important;
}
.bor-rad-t-l-5 {
  border-top-left-radius:5px !important;
}
.bor-rad-b-l-5 {
  border-bottom-left-radius:5px !important;
}
.bor-rad-t-r-5 {
  border-top-right-radius:5px !important;
}
.bor-rad-b-r-5 {
  border-bottom-right-radius:5px !important;
}
/*
  BY: Prasannadatta Kawadkar
  ON: 12 Mar 2024
  Modification: added new classes for border top
*/
.bor-rad-t-l-10 {
  border-top-left-radius:10px !important;
}
.bor-rad-t-r-10 {
  border-top-right-radius:10px !important;
}
.border-sunset-orange {
  border: 2px solid #ed553b8f !important;
  background: #ed553b13 !important;
  color: #ed553b8f !important;
}

.border-green {
  border: 2px solid #008000 !important;
  background: #00800013 !important;
  color: #008000 !important;
}
.border-blue {
  border:2px solid #91c7e78f !important;
  background: #91c7e712 !important;
  color: #91c7e7 !important;
}
.border-blue-1px {
  border:1px solid #002c5f !important;
  background: #91c7e712 !important;
  color: #002c5f !important;
}
.border-darkblue {
  border: 2px solid rgb(102 133 164) !important;
  background: #d4dde685 !important;
  color: #003468 !important;
}
.border-yellow {
  border: 2px solid #fbc12d !important;
  background: #fbc12d13 !important;
  color: #fbc12d !important;
}
.border-tenant {
  border:2px solid var(--tenant-color-opacity1) !important;
  background: var(--tenant-color-opacity2) !important;
  color: var(--tenant-color) !important;
}
.border-white {
  border:2px solid #fff !important;
  //background: #fff !important;
  color: #fff !important;
}
.border-orange {
  border: 1px solid #F8A67B  !important;
}
.border-skyblue {
  border: 1px solid #98C1D9  !important;
}
.border-left-2 {
  border-left: 2px solid var(--page-sub-header-text-color);
  height: 4.2em;
  justify-content: center;

}
.border-left {
  border-left: 0.5px solid var(--page-sub-header-text-color);
  height: 4.2em;justify-content: center;
}
.filterteamborder {background: #95d3d3; border: 1px solid #dfebf0; border-radius: 12px;}
.borderteambottom {border-bottom: 1px solid #dfebf0;}
.borderteambottomdark {border-bottom: 1px solid #292d34 !important;}
.borderteambottomtenant {border-bottom: 1px solid var(--tenant-color) !important;}

.border-grey {
  border: 1px solid #626262;
}
.border-bottom-lightgrey-thin {
  border-bottom: 0.5px solid lightgrey;
}

.border-bottom-lightgrey {
  border-bottom:1px solid lightgrey
}
.border-0-5-002C5F {
  border: 0.5px solid #002C5F;
}
.border-002C5F {
  border: 1px solid #002C5F;
}
.border-526F95{
  border: 1px solid #526F95;
}
.border-88B7D2 {
  border: 1px solid #88B7D2;
}
/*
		modified by Masum Raja
		modified at 23/10/2023
		modification : Add border class font class height
*/
.border-none-imp {
  border: none !important;
}

.bor-t-EDEDED {
  border-top: 1px solid #EDEDED !important;
}

.bor-all-EDEDED {
  border: 1px solid #EDEDED !important;
}
.outline-all-0000FF {
  outline: 0.5px solid #0000FF !important;
}

/*
-------------------------------------------------
*/
.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.cursor-text {
	cursor: text !important;
}
.cursor-grab {
	cursor: grab;
}
/*
  BY: Prasannadatta Kawadkar
  ON: 24 April 2024
  Modification: added new class
*/
.cursor-default-imp {
	cursor: default !important;
}
.flex-nowrap {flex-wrap: nowrap !important;}
.flex-shrink-0 {flex-shrink: 0;}

/*
Center a div using flex
-------------------------------------------------
*/
.center-div {
  display: flex;
  align-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.align-items-end {
  align-items: end;
}
/*
Flex grow
--------------------------------------------------
*/
.flex-grow-1 {
	flex-grow: 1;
}
.flex-grow-2 {
	flex-grow: 2;
}
/*
Flex direction
--------------------------------------------------
*/
.flex-direction-row{
	flex-direction: row !important;
}
.flex-column {
	flex-direction: column;
}
.flex-direction-column{
	flex-direction: column;
}
.flex-wrap-wrap{
	flex-wrap: wrap;
}

.flex-flow-column {
  flex-flow: column;
}

/*
Display classes
-----------------------------------------
*/
.display-flex {
	display: flex !important;
}
.display-grid {
	display: grid !important;
}
.display-block {
	display: block !important;
}
.display-none {
	display: none !important;
}
.display-inline-block {
	display: inline-block !important;
}
.inline-block {
	display: inline-block;
}

/*
Align classes
-----------------------------------------
*/
.align-flex-start {
	align-items: flex-start !important;
}
.align-self-center {
	align-self: center;
}
.align-self-end {
	align-self: flex-end;
}
.align-center {
	align-items: center;
}
.align-baseline {
	align-items: baseline !important;
}
.align-content-between {
	align-content:	space-between;
}
.align-content-flex-start {
	align-content:	flex-start;
}

/*
Justify classes
-----------------------------------------
*/
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content:	space-between;
}
.justify-between-imp {
	justify-content:	space-between !important;
}
.justify-evenly {
	justify-content:space-evenly;
}
.justify-around {
	justify-content:	space-around;
}
.justify-right {
	justify-content:	right;
}
.justify-flex-start {
	justify-content: flex-start;
}
.justify-left {
	justify-content: left;
}
.justify-right {
	justify-content: right;
}
.justify-flex-end {
	justify-content: flex-end;
}

/*
Text-align classes
-----------------------------------------
*/
.text-align-right {
	text-align: right;
}
.text-align-left {
	text-align: left;
}
.text-align-center {
	text-align: center;
}
.text-align-last {
  text-align-last: right;
}
.align-items-center {
  align-items: center;
}
/*
  By: Prasannadatta Kawadkar
  On: 17 Jan 2024
  Modification: added new class for text-align
*/
.text-align-webkit-center {
  text-align: -webkit-center;
}

/*
Float Classes
-----------------------------------------
*/
.float-right {
	float: right;
}
.float-left {
	float: left;
}
.float-none {
	margin: 0 auto;
}

/*
Overflow
------------------------------------------------------
*/
.overflow-auto {
	overflow: auto;
}
.overflow-scroll {
	overflow: scroll;
}
.overflow-hidden {
	overflow: hidden !important;
}
.overflow-visible {
	overflow: visible;
}
.overflow-visible-imp {
	overflow: visible !important;
}

/*
Vertical align
------------------------------------------------------
*/
.vertical-align-top {
	vertical-align: top;
}
.vertical-align-bottom {
	vertical-align: bottom;
}
.vertical-align-middle {
	vertical-align: middle;
}

/*
Font styles
------------------------------------------------------
*/
.font-openSans {
	font-family: 'OpenSans', sans-serif !important;
}
.font-roboto {
	font-family: 'Roboto', sans-serif;
}
.first-letter-capital {
  text-transform: capitalize;
}

.border-red-radio {
  input[type='radio'] {
    border:1px solid red !important;
  }
}
.object-fit-contain {
  object-fit: contain;
}

.contain-none {
  contain: none;
}
.contain-none-imp {
  contain: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.italic {
 font-style: italic;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.flex-1 {
  flex: 1;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2 !important;
}
.z-index-3{
  z-index:3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5 !important;
}
.z-index-13 {
  z-index: 13 !important;
}
.z-index-21 {
  z-index: 21 !important;
}
.z-index-9997 {
  z-index: 9997;
}
.z-index-9998 {
  z-index: 9998;
}
.z-index-101 {
  z-index: 101 !important;
}
.z-index-99 {
  z-index: 99 !important;
}
.z-index-1999 {
  z-index: 1999 !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.truncate {
  //width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ws-nowrap {
  white-space: nowrap;
}

.highlight-yellow {
  background-color: #fbc12d !important;
  color: black;
  width: fit-content;
}

.online-dot {
  position: absolute;
  cursor: pointer;
  height: 10px;
  width: 10px;
  right: -4px;
  top: 0;
  background-color: #1ad67b;
  border: 1px solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  border-radius: 99px;
}

.online-dot-v2 {
  position: absolute;
  height: 6px;
  width: 6px;
  right: -1px;
  top: -1px;
  background: #5ED700;
  border: 1px solid #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  border-radius: 99px;
}

.dot {
  position: relative;
  top: 6px;
  right: -6px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  border-radius: 99px;
}

/*
Gap Classes
-----------------------------------------
*/
.gap-1px {gap: 1px;}
.gap-2px {gap: 2px;}
.gap-3px {gap: 3px;}
.gap-4px {gap: 4px;}
.gap-5px {gap: 5px;}
.gap-6px {gap: 6px;}
.gap-7px {gap: 7px;}
.gap-8px {gap: 8px;}
.gap-9px {gap: 9px;}
.gap-10px {gap: 10px;}
.gap-11px {gap: 11px;}
.gap-12px {gap: 12px;}
.gap-13px {gap: 13px;}
.gap-14px {gap: 14px;}
.gap-15px {gap: 15px;}
.gap-16px {gap: 16px;}
.gap-17px {gap: 17px;}
.gap-18px {gap: 18px;}
.gap-19px {gap: 19px;}
.gap-20px {gap: 20px;}
.gap-21px {gap: 21px;}
.gap-22px {gap: 22px;}
.gap-23px {gap: 23px;}
.gap-24px {gap: 24px;}
.gap-25px {gap: 25px;}
.gap-26px {gap: 26px;}
.gap-27px {gap: 27px;}
.gap-28px {gap: 28px;}
.gap-29px {gap: 29px;}
.gap-30px {gap: 30px;}
.gap-31px {gap: 31px;}
.gap-32px {gap: 32px;}
.gap-33px {gap: 33px;}
.gap-34px {gap: 34px;}
.gap-35px {gap: 35px;}
.gap-36px {gap: 36px;}
.gap-37px {gap: 37px;}
.gap-38px {gap: 38px;}
.gap-39px {gap: 39px;}
.gap-40px {gap: 40px;}
.gap-41px {gap: 41px;}
.gap-42px {gap: 42px;}
.gap-43px {gap: 43px;}
.gap-44px {gap: 44px;}
.gap-45px {gap: 45px;}
.gap-46px {gap: 46px;}
.gap-47px {gap: 47px;}
.gap-48px {gap: 48px;}
.gap-49px {gap: 49px;}
.gap-50px {gap: 50px;}
.gap-51px {gap: 51px;}
.gap-52px {gap: 52px;}
.gap-53px {gap: 53px;}
.gap-54px {gap: 54px;}
.gap-55px {gap: 55px;}
.gap-56px {gap: 56px;}
.gap-57px {gap: 57px;}
.gap-58px {gap: 58px;}
.gap-59px {gap: 59px;}
.gap-60px {gap: 60px;}
.gap-61px {gap: 61px;}
.gap-62px {gap: 62px;}
.gap-63px {gap: 63px;}
.gap-64px {gap: 64px;}
.gap-65px {gap: 65px;}
.gap-66px {gap: 66px;}
.gap-67px {gap: 67px;}
.gap-68px {gap: 68px;}
.gap-69px {gap: 69px;}
.gap-70px {gap: 70px;}
.gap-71px {gap: 71px;}
.gap-72px {gap: 72px;}
.gap-73px {gap: 73px;}
.gap-74px {gap: 74px;}
.gap-75px {gap: 75px;}
.gap-76px {gap: 76px;}
.gap-77px {gap: 77px;}
.gap-78px {gap: 78px;}
.gap-79px {gap: 79px;}
.gap-80px {gap: 80px;}
.gap-81px {gap: 81px;}
.gap-82px {gap: 82px;}
.gap-83px {gap: 83px;}
.gap-84px {gap: 84px;}
.gap-85px {gap: 85px;}
.gap-86px {gap: 86px;}
.gap-87px {gap: 87px;}
.gap-88px {gap: 88px;}
.gap-89px {gap: 89px;}
.gap-90px {gap: 90px;}
.gap-91px {gap: 91px;}
.gap-92px {gap: 92px;}
.gap-93px {gap: 93px;}
.gap-94px {gap: 94px;}
.gap-95px {gap: 95px;}
.gap-96px {gap: 96px;}
.gap-97px {gap: 97px;}
.gap-98px {gap: 98px;}
.gap-99px {gap: 99px;}
.gap-100px {gap: 100px;}
.gap-101px {gap: 101px;}
.gap-102px {gap: 102px;}
.gap-103px {gap: 103px;}
.gap-104px {gap: 104px;}
.gap-105px {gap: 105px;}
.gap-106px {gap: 106px;}
.gap-107px {gap: 107px;}
.gap-108px {gap: 108px;}
.gap-109px {gap: 109px;}
.gap-110px {gap: 110px;}
.gap-111px {gap: 111px;}
.gap-112px {gap: 112px;}
.gap-113px {gap: 113px;}
.gap-114px {gap: 114px;}
.gap-115px {gap: 115px;}
.gap-116px {gap: 116px;}
.gap-117px {gap: 117px;}
.gap-118px {gap: 118px;}
.gap-119px {gap: 119px;}
.gap-120px {gap: 120px;}
.gap-121px {gap: 121px;}
.gap-122px {gap: 122px;}
.gap-123px {gap: 123px;}
.gap-124px {gap: 124px;}
.gap-125px {gap: 125px;}
.gap-126px {gap: 126px;}
.gap-127px {gap: 127px;}
.gap-128px {gap: 128px;}
.gap-129px {gap: 129px;}
.gap-130px {gap: 130px;}
.gap-131px {gap: 131px;}
.gap-132px {gap: 132px;}
.gap-133px {gap: 133px;}
.gap-134px {gap: 134px;}
.gap-135px {gap: 135px;}
.gap-136px {gap: 136px;}
.gap-137px {gap: 137px;}
.gap-138px {gap: 138px;}
.gap-139px {gap: 139px;}
.gap-140px {gap: 140px;}
.gap-141px {gap: 141px;}
.gap-142px {gap: 142px;}
.gap-143px {gap: 143px;}
.gap-144px {gap: 144px;}
.gap-145px {gap: 145px;}
.gap-146px {gap: 146px;}
.gap-147px {gap: 147px;}
.gap-148px {gap: 148px;}
.gap-149px {gap: 149px;}
.gap-150px {gap: 150px;}
.gap-151px {gap: 151px;}
.gap-152px {gap: 152px;}
.gap-153px {gap: 153px;}
.gap-154px {gap: 154px;}
.gap-155px {gap: 155px;}
.gap-156px {gap: 156px;}
.gap-157px {gap: 157px;}
.gap-158px {gap: 158px;}
.gap-159px {gap: 159px;}
.gap-160px {gap: 160px;}
.gap-161px {gap: 161px;}
.gap-162px {gap: 162px;}
.gap-163px {gap: 163px;}
.gap-164px {gap: 164px;}
.gap-165px {gap: 165px;}
.gap-166px {gap: 166px;}
.gap-167px {gap: 167px;}
.gap-168px {gap: 168px;}
.gap-169px {gap: 169px;}
.gap-170px {gap: 170px;}
.gap-171px {gap: 171px;}
.gap-172px {gap: 172px;}
.gap-173px {gap: 173px;}
.gap-174px {gap: 174px;}
.gap-175px {gap: 175px;}
.gap-176px {gap: 176px;}
.gap-177px {gap: 177px;}
.gap-178px {gap: 178px;}
.gap-179px {gap: 179px;}
.gap-180px {gap: 180px;}
.gap-181px {gap: 181px;}
.gap-182px {gap: 182px;}
.gap-183px {gap: 183px;}
.gap-184px {gap: 184px;}
.gap-185px {gap: 185px;}
.gap-186px {gap: 186px;}
.gap-187px {gap: 187px;}
.gap-188px {gap: 188px;}
.gap-189px {gap: 189px;}
.gap-190px {gap: 190px;}
.gap-191px {gap: 191px;}
.gap-192px {gap: 192px;}
.gap-193px {gap: 193px;}
.gap-194px {gap: 194px;}
.gap-195px {gap: 195px;}
.gap-196px {gap: 196px;}
.gap-197px {gap: 197px;}
.gap-198px {gap: 198px;}
.gap-199px {gap: 199px;}
.gap-200px {gap: 200px;}


/*
Rotate Classes
-----------------------------------------
*/
.rotate-45 {transform: rotate(45deg);}
.rotate-90 {transform: rotate(90deg);}
.rotate-135 {transform: rotate(135deg);}
.rotate-180 {transform: rotate(180deg);}
.rotate-225 {transform: rotate(225deg);}
.rotate-270 {transform: rotate(270deg);}
.rotate-315 {transform: rotate(315deg);}
.rotate-360 {transform: rotate(360deg);}
.td-underline { text-decoration: underline;}
.hov-td-underline:hover { text-decoration: underline;}

.word-wrap-break {
  word-wrap: break-word;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}
.shadow1 {box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.shadow2 {box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);}
.shadow4 {box-shadow: 6px 0px 2px rgba(0, 0, 0, 0.25),  2px 0px 2px rgba(0, 0, 0, 0.25);}
/*
		modified by Arun Singh
		modified at 28/12/2023
		modification : Add new box-shadow with this border;
*/
.shadow5 {box-shadow: 0px 0px 0px 1px;}
.no-shadow {box-shadow: none !important;}
/*
  BY: Prasannadatta Kawadkar
  ON: 12 Mar 2024
  Modification: added new class for box shadow
*/
.minimal-shadow {
  box-shadow: 0px 0.5px 2px 0px rgba(0,0,0,0.1), 0px 1px 2px -1px rgba(0,0,0,0.1) !important;
}
.arrow-dot {
  height: 30px;
  width: 30px;
  align-items: center;
  background-color: #eee !important;
  border-radius: 50%;
  display: inline-block;
  margin-top: 22px;
  margin-left: 20px;
}

.highlight-88B7D2 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=88B7D2);
  padding: 8px;
}
.highlight-fbc12d {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=fbc12d);
  padding: 2px 10px 2px 10px;
}
.highlight-ed736d {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=ed736d);
  padding: 2px 10px 2px 10px;
}
.highlight-79ae6b9e {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=79ae6b9e);
  padding: 2px;
  color: #002c5f;
}

/*
  BY: Prasannadatta Kawadkar
  ON: 13 May 2024
  Modification: added new highlight classes
*/
.highlight-DD5746 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=DD5746);
  padding: 8px;
}

.highlight-FF9800 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=FF9800);
  padding: 8px;
}

.highlight-FDDE55 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=FDDE55);
  padding: 8px;
}

.highlight-607274 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=607274);
  padding: 8px;
}

.disabled-div {
	opacity: 0.5;
	cursor: default;
}

.rainbow {
  animation-name: backgroundColorPalette;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes backgroundColorPalette {
  0% {
    background: #eeeeee;
  }
  25% {
    background: #eeeeee;
  }
  50% {
    background: #eeeeee;
  }
  75% {
    background: #eeeeee;
  }
  100% {
    background: white;
  }
}

.bell-notification {
  display:block;
  width: 32px;
  height: 40px;
  font-size: 25px !important;
  //margin:50px auto 0;
  color: white;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

/*
  Modification: added new class for disable text
  By: Masum Raja
  Date: 19/07/2023
*/

.text-disabled {
  pointer-events: none;
  opacity: 0.6;
}

// modified by Masum Raja
// modified at 16/oct/2023
// modification :Create a new shadow class specifically designed for shadows in the X-direction add one margin left class.
.shadow3 {box-shadow: 6px 0px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);}

.bg-2F95A3-1A6085 {
  background: linear-gradient(180deg, #2F95A3 0%, #1A6085 100%);
}

.bg-0093E9-80D0C7 {
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}


// modified by Arun Singh
// modified at 1/Mar/2024
// modification : Add background !important classes.

.bg-ed736d {
  background: #ed736d !important;
}
.bg-79ae6b9e {
  background: #79ae6b9e !important;
}
.bg-fbc12d {
  background: #fbc12d !important;
}

.flash {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-color: white; /* Color for the flash effect */
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Allow interaction with elements beneath */
  transition: opacity 0.04s; /* Add a transition for the opacity property */
  transition-timing-function: ease;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate infinite 2s linear; /* Adjust the animation duration as needed */
}
/*
  By: Prasannadatta Kawadkar
  On: 25 Jan 2024
  Modification: added classes for text color
*/
.text-242424 {color:#242424;}
.text-808080 {color:#808080 !important;}
.text-ed736d {
  color: #ed736d !important;
}
.text-88B7D2 {
  color: #88B7D2 !important;
}
.text-fbc12d {
  color: #fbc12d !important;
}

.text-909AAC {
  color: #909AAC !important;
}

.text-04364a {
  color: #04364a !important;
}

/*
  modified : Ashutosh G
  modified : 05/02/2024
  modification : teamViewDropDown, teamViewDropDown-mobile-view, workspace-rotate-on, workspace-rotate-off added for Insurance Head Dashboard
*/
.custom-dropDown-item {
  display: flex;
  //min-width: 200px;
  //max-width: 200px;
  padding: 8px 16px;
}
.top-neg-5 {
  top: -5px;
}

.gridRadioGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

{/*
  Bug:- KH_BG_655 (When hovered on Download button , it is not getting highlighted)
  Modification:- Added css to download button
  On:- 22-01-2024
  By:- Rajkumar Pradhan
*/}

button-10 {
		border-radius: 3px;
		padding: 2px 8px;
		height: auto;
		cursor: pointer;
		border: 0.5px solid #c6c6c6;
		color: #808080;
		min-width: auto;
		background-color: var(--text-white);;
		font-size: 12px;
		transition: all 0.2s ease-in-out;
	}
.button-10:hover {
		color: var(--text-white);;
		background-color: #808080;
	}

.my-input::placeholder {
  color: #2f4c62;
}

/*
  BY: Prasanadatta Kawadkar
  ON: 02 MAy 2024
  Modification: added new styling class for textarea
*/
.descriptionBox {
  width: 100%;
  height: 120px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #5f6368;
  border-radius: 5px;
  resize: none;
  background: #fff !important;
}

/*
  BY: Prasanadatta Kawadkar
  ON: 02 MAy 2024
  Modification: added new styling class for overflow-wrap
*/
.overflow-wrap {
  overflow-wrap: break-word;
}
.blur-5 {
  filter: blur(5px);
}
.formwizard-error-table {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
}
