/*
  BY: Prasannadatta Kawadkar
  ON: 13 March 2024
  Modification: color changed for the leftInfo Panel and Right Panel and width changed for the leftInfo Panel
*/
/*
  BY: Prasannadatta Kawadkar
  ON: 13 March 2024
  Modification: padding changed for icons
*/

.leftInfoPanel-tab {
  width: 64px;
  text-align: center;
  position: relative;
  //padding-top: 10px;
  height: 100vh;
  //box-shadow: rgb(189 191 193 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px;
  //box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
  //box-shadow: #d4d8db 6px 2px 16px 0px, #c7c7c2 -6px -2px 16px 0px;
  //background:linear-gradient(180deg, #002C5F 0%, #002C5F 24.81%, #1275B0 100%)
  background: #04364A;

  .leftInfoPanel-tab-container {
    width: 55px;
    padding: 10px 7px 7px 7px;
    border-bottom: 1px solid rgba(0,0,0,0.051);
  }
  .leftInfoPanel-logout {
    position: absolute;
    bottom: 17px;
    left: 14px;
  }

  /*
    By: Prasannadatta Kawadkar
    On: 30 Jan 2024
    Modification: changed class for the navbarV2
  */
  .leftInfoPanel-menu-icon {
    //top: 45px !important;
    position: relative;
    padding: 12px 8px 8px 8px
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //width: 42px;
    //margin-left: 12px;
    height: 65px;
  }
  .leftInfoPanel-menu-icon-active {
    //top: 50px !important;
    position: relative;
    padding: 12px 8px 8px 8px
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //border-left: 4px solid #fff;
    //border-radius: 22px;
    //height: 30px;
    //margin-bottom: 2px;
    //margin-left: 12px;
    //position: relative;
    //width: 42px;
    height: 65px;
    background-color: #ffffffb8 !important;
    .menu-name{
      color:black !important;
    }
  }
  .menu-name {
    color: white;
    font-size: 0.65rem;
    //margin-top: -5px;
    margin-bottom: 3px;
  }

}
.leftInfoPanel-cards, .leftInfoPanel-cards-hover {
  display: flex;
  position: relative;
  //box-shadow: 0px 1px 3px 1px #e5e5e5;
  border-radius: 5px;
  margin-top: 10px;
  flex-direction: column;
  //background: #fff;
  padding: 5px;
  span {
    font-weight:400 !important;
    span {
    user-select: none;
       -moz-user-select: none;
       -khtml-user-select: none;
       -webkit-user-select: none;
       -o-user-select: none;

    }

  }
}

.leftInfoPanel-cards-hover {
  cursor: pointer;
  &:hover {
    //box-shadow: -2px 7px 3px 1px #e5e5e5;
  }
}

.counter-span {
  position: absolute;
  right: 0;
  top: 0;
}
.menu-count {
  min-width: 8px;
  text-align: center;
  background-color: #b3261e;
  background-clip: padding-box;
  border: 1px solid #b3261e;
  color: #fff;
  font-size: .6875rem;
  margin: 0;
  text-shadow: none;
  top: 2px;
  right: 2px;
  font-size: .75rem;
  letter-spacing: normal;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 8px;
  padding: 1px 4px;
  position: relative;
  z-index: 1;
}

.notify-span {
  //display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  position: absolute;
  background-color: #c11e1efa;
  top: 6px;
  right: 5px;
}

.notify-span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  //z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  animation-delay: 0.2s;
  top: 0px;
  right: 0px;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}


.mobile-leftInfoPanel {
  background: #d0e7f7;
  height: 100vh;
  position: fixed;
  //top: 50px;
  z-index: 1001;
  width: 70vw;
  padding-top: 4px;
    transform: translate3d(0, 0, 0);
  -webkit-transition: transform 0.2s ease-in;
    -o-transition transform 0.2s ease-in;
    -moz-transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in;



  .leftInfoPanel-tab-container {
      width: 55px;
      padding: 10px 7px 7px 7px;
      border-bottom: 1px solid rgba(0,0,0,0.051);
    }

    .leftInfoPanel-logout {
      position: absolute;
      bottom: 72px;
      left: 14px;
    }
    .leftInfoPanel-menu-icon {
      //top: 45px !important;
      position: relative;
      padding: 12px 8px 8px 8px
      //border-bottom: 1px solid rgba(0,0,0,0.051);
      cursor: pointer;
      //width: 42px;
      //margin-left: 12px;
      height: 52px;


    }
    .leftInfoPanel-menu-icon-active {
      //top: 50px !important;
      position: relative;
      padding: 15px 9px 8px 8px
      //border-bottom: 1px solid rgba(0,0,0,0.051);
      cursor: pointer;
      //border-left: 4px solid #fff;
      //border-radius: 22px;
      //height: 30px;
      //margin-bottom: 2px;
      //margin-left: 12px;
      //position: relative;
      //width: 42px;
      height: 52px;
      background-color: #ffffffb8 !important;
    }

    .menu-name {
      color: black;
      //font-size: 0.65rem;
      //margin-top: -5px;
      margin-bottom: 3px;
      letter-spacing:2px;
    }

    .icon-sign-out::before {
      content: "c";
      margin-bottom : 5px;
    }

  }

.leftPanel-overlay{
  width:100vw;
  height:100vh;
  position:absolute;
  z-index:1001;
  opacity: 0.3;
  background: black;

}

.logo::after {
  content : "Kothari Cars";
  position: absolute;
  font-size: 1.2rem;
  left: 55px;
  bottom: 12px;
  color:black;
  font-weight: 200;
  letter-spacing:2px;
}

  .mobile-leftInfoPanel1 {
  background: #d0e7f7;
  height: 100vh;
  position: fixed;
  //top: 50px;
  z-index:1001;
  width: 70vw;
  padding-top: 4px;
transform: translate3d(-100%, 0, 0);
-webkit-transition: transform 0.2s ease-out;
-o-transition: transform 0.2s ease-out;
-moz-transition: transform 0.2s ease-out;
transition: transform 0.2s ease-out;
 //transition-delay:0.2s;

.leftInfoPanel-tab-container {
    width: 55px;
    padding: 10px 7px 7px 7px;
    border-bottom: 1px solid rgba(0,0,0,0.051);
  }

  .leftInfoPanel-logout {
    position: absolute;
    bottom: 72px;
    left: 14px;
  }
  .leftInfoPanel-menu-icon {
    //top: 45px !important;
    position: relative;
    padding: 12px 8px 8px 8px
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //width: 42px;
    //margin-left: 12px;
    height: 52px;


  }
  .leftInfoPanel-menu-icon-active {
    //top: 50px !important;
    position: relative;
    padding: 15px 9px 8px 8px
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //border-left: 4px solid #fff;
    //border-radius: 22px;
    //height: 30px;
    //margin-bottom: 2px;
    //margin-left: 12px;
    //position: relative;
    //width: 42px;
    height: 52px;
    background-color: #ffffffb8 !important;
  }

  .menu-name {
    color: white;
    //font-size: 0.65rem;
    //margin-top: -5px;
    margin-bottom: 3px;
    letter-spacing:2px;
  }

  .icon-sign-out::before {
    content: "c";
    margin-bottom : 5px;
  }


}

/* [Media Queries]
--------------------------------------------------------------------------------
*/

@media (max-width:428px)  {
	/* smartphones, iPhone, portrait 480x320 phones*/
    .leftInfoPanel-tab{
      display:none;
    }
}





/* modified by Vipin
   modified at 07/11/2022
   modification : Hide Left Navigation Panel on mobile devices(width below 428px)
*/
